import HttpRequest from './HttpRequest'

class SecurityService extends HttpRequest {
  constructor() {
    super(window.__env.api.url + 'Security')
  }

  // request body
  // {
  //     userName: 'string',
  //     password: 'string',
  //     email: 'string', //optional
  // }
  async signIn(body) {
    const res = await this.post('SignIn', body)
    const { data } = res
    return data
  }

  async signInWithGoogle(payload) {
    const res = await this.get('SignInAsGoogle?code=' + payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     id: id //userId
  //   }
  async getUserById(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('GetUserById?id=' + payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     userName: 'string',
  //     email: 'string',
  //     firstName: 'string',
  //     lastName: 'string',
  //     isActive: 'bool',
  //     orderBy: 'string',
  //     pageNo: 'int',
  //     pageSize: 'int',
  //     isPagination: 'bool',
  //   }
  async getUsers(payload) {
    this.setHeader({
      Authorization: `Bearer ${payload.token}`
    })
    const res = await this.get('GetUsers', payload.params)
    const { data } = res.data
    return data
  }

  // payload.params
  //   {
  //     id: 'int', //optional if 0 or null = create > 0  != null = update
  //     userName: 'string',
  //     password: 'string',
  //     email: 'string',
  //     firstName: 'string',
  //     lastName: 'string',
  //     country: 'string',
  //     isActive: 'bool',
  //     signupAs: 'int',
  //     userType: 'int',
  //   }
  async addOrUpdateUser(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('AddOrUpdateUser', payload.body)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     id: 'int',
  //     password: 'string',
  //   }
  async changePassword(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('ChangePassword', payload.body)
    const { data } = res
    return data
  }
}

export default SecurityService
