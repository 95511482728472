import ScholarService from './ScholarService'
import SecurityService from './SecurityService'
import LogService from './LogService'
import BankService from './BankService'
import AuthService from './AuthService'
import CloudService from './CloudService'
import JobScheduleService from './JobScheduleService'
import DashboardService from './DashboardService'
const securityService = new SecurityService()
const scholarService = new ScholarService()
const logService = new LogService()
const bankService = new BankService()
const authService = new AuthService()
const cloudService = new CloudService()
const jobScheduleService = new JobScheduleService()
const dashboardService = new DashboardService()
export {
  scholarService,
  securityService,
  logService,
  bankService,
  authService,
  cloudService,
  jobScheduleService,
  dashboardService
}
