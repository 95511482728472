<template>
  <div>
    <DarkModeSwitcher isHide="true" />
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <div class="flex-col hidden min-h-screen xl:flex">
          <div class="my-auto">
            <div class="flex flex-row items-center">
              <img alt="lw" class="-intro-x" style="margin-top: -50; margin-left: -50px"
                :src="isDarkMode ? imgLogo : imgLogoDark" />
            </div>
          </div>
        </div>
        <div v-if="!isNormalLogin" class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-32 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <div class="mt-10 text-white lg:mt-0" style="text-align: -webkit-center;">
              <div class="w-10 h-10 -intro-x">
                <LoadingIcon icon="circles" />
              </div>
              <div class="mt-5 text-center intro-x">
                <span style="font-size: 16px;">
                  Verifying information...
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0">
          <div
            class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-32 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
            <h2 class="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
              Sign In
            </h2>
            <div class="mt-2 text-center text-gray-500 intro-x xl:hidden">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <div class="mt-6">
              <div class="border-gray-300 input-group login__input intro-x focus:intro-x">
                <div id="input-group-username" class="input-group-text" :class="{ disabled: isSigning }">
                  <UserIcon class="block w-3 mx-auto" />
                </div>
                <input :disabled="isSigning" type="text" v-model="formData.username" class="border-gray-300 form-control"
                 @keypress="onKeyPressed($event)"
                  placeholder="Username / Email" />
              </div>
              <template v-if="v$.formData.username.$error">
                <div v-for="(error, index) in v$.formData.username.$errors" :key="index" class="mt-2 text-theme-6">
                  {{ error.$message }}
                </div>
              </template>
              <div class="mt-4 border-gray-300 input-group login__input focus:intro-x intro-x">
                <div id="input-group-confirm-password " class="input-group-text " :class="{ disabled: isSigning }">
                  <LockIcon class="block w-3 mx-auto" />
                </div>
                <input :disabled="isSigning" :type="isShowPassword ? 'text' : 'password'" placeholder="Password"
                  @keypress="onKeyPressed($event)"
                  v-model="formData.password" class="border-gray-300 form-control" aria-label="password"
                  autocomplete="off" aria-describedby="input-group-password" />
                <div id="input-group-password " :aria-disabled="isSigning" class="cursor-pointer input-group-text"
                  :class="{ disabled: isSigning }" @click="isShowPassword = !isShowPassword">
                  <EyeOffIcon v-if="isShowPassword" class="block w-3 mx-auto " />
                  <EyeIcon v-else class="block w-3 mx-auto " />
                </div>
              </div>
              <template v-if="v$.formData.password.$error">
                <div v-for="(error, index) in v$.formData.password.$errors" :key="index" class="mt-2 text-theme-6">
                  {{ error.$message }}
                </div>
              </template>
              <div class="flex text-xs text-gray-700 intro-x dark:text-gray-600 sm:text-sm mt-7">
                <div class="flex items-center mr-auto">
                  <input :disabled="isSigning" id="remember-me" v-model="isRemember" type="checkbox"
                    class="mr-2 border form-check-input" />
                  <label :disabled="isSigning" class="cursor-pointer select-none" for="remember-me">Remember me</label>
                </div>
                <router-link to="/forgot-password">Forgot Password?</router-link>
              </div>
              <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
                <button :disabled="isSigning" class="w-full px-4 py-3 align-top btn btn-primary xl:w-32 xl:mr-3"
                  @click="onSignInClicked">
                  <div class="flex flex-col items-center justify-end sm:flex-row">
                    <div class="text-center">
                      {{ isSigning ? 'Logging' : 'Login' }}
                    </div>
                    <LoadingIcon v-if="isSigning" icon="oval" class="w-5 h-5 ml-3 text-center" />
                  </div>
                </button>
                <router-link to="/register" :disabled="isSigning" :class="{ disabled : isSigning}" class="w-full px-4 py-3 mt-3 align-top btn btn-outline-secondary xl:w-32 xl:mt-0">
                  Sign up
                </router-link>
              </div>
            </div>
            <div class="mt-2 text-center intro-x xl:mt-8 xl:text-left" v-if="!$helpers.isNull(errorMessage)">
              <div class="flex text-theme-6">
                <img :src="imgError" class="w-6 h-6 mr-2 " />
                {{ errorMessage }}
              </div>
            </div>
            <div class="mt-6 text-center text-gray-700 intro-x xl:mt-6 dark:text-gray-600 xl:text-left">
              By signing up, you agree to our
              <br/>
              <a class="text-theme-1 dark:text-theme-10" target="_blank" href="https://www.lightworkai.com/termofservice">
                Terms and Conditions
              </a>
              &
              <a class="text-theme-1 dark:text-theme-10" target="_blank" href="https://www.lightworkai.com/privacypolicy">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { authService } from '@/services'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import imgLogo from '@/assets/images/lw-logo-name.png'
import imgError from '@/assets/images/Error32.png'
import imgLogoDark from '@/assets/images/lw-logo-name-dark.png'
import * as mutationTypes from '@/store/mutation-types'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  computed: {
    ...mapGetters({
      isDarkMode: `main/${getterTypes.GET_DARK_MODE}`,
      getRememberMe: `local/${getterTypes.GET_REMEMBER_ME}`,
      hasRememberMe: `local/${getterTypes.GET_HAS_REMEMBER_ME}`
    })
  },
  data: () => ({
    imgError,
    imgLogo,
    imgLogoDark,
    formData: {
      username: null,
      password: null
    },
    token: null,
    errorMessage: null,
    isRemember: false,
    isNormalLogin: false,
    isShowPassword: false,
    isSigning: false
  }),
  setup: () => ({
    route: useRoute(),
    router: useRouter(),
    store: useStore(),
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true
    })
  }),
  validations() {
    return {
      formData: {
        username: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        },
        password: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        }
      }
    }
  },
  methods: {
    onKeyPressed(event) {
      // keyCode 13 = Enter
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault()
        this.onSignInClicked()
      }
    },
    async onSignInClicked() {
      this.errorMessage = null
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$helpers.showToastify('Validate-Failed', 'Please check your input', true)
      } else {
        try {
          this.isSigning = true
          const payload = {
            username: this.formData.username,
            password: this.formData.password
          }
          const res = await authService.Login(payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            if (this.isRemember) {
              this.$store.commit(`local/${mutationTypes.SET_REMEMBER_ME}`, payload)
            } else {
              this.$store.commit(`local/${mutationTypes.CLEAR_REMEMBER_ME}`)
            }
            await this.signInCompleted(res.access_token)
          } else {
            this.isSigning = false
            this.errorMessage = res.message
          }
        } catch (error) {
          this.isSigning = false
          this.errorMessage = error.message
        }
      }
    },
    async signInByToken() {
      try {
        const payload = {
          username: '_',
          password: '_',
          token: this.token
        }
        const res = await authService.LoginByToken(payload)
        if (this.$helpers.isNull(res?.statusCode)) {
          await this.signInCompleted(res.access_token)
        } else {
          this.isNormalLogin = true
          this.errorMessage = res.message
        }
      } catch (err) {
        this.isNormalLogin = true
        this.errorMessage = err.message
      }
    },
    async signInCompleted(token) {
      this.$store.commit(`main/${mutationTypes.SET_TOKEN}`, token)
      await this.delay(500)
      this.router.push('/')
    },
    async delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds)
      })
    },
    intiRememberMe() {
      if (this.hasRememberMe) {
        const temp = this.getRememberMe
        this.isRemember = true
        this.formData.username = temp.username
        this.formData.password = temp.password
      }
    }
  },
  created() {
    this.token = this.$route.query.token
  },
  async mounted() {
    cash('body')
      .removeClass('main')
      .removeClass('error-page')
      .addClass('login')
    this.intiRememberMe()
    if (this.token) {
      await this.signInByToken()
    } else {
      this.isNormalLogin = true
    }
  }

})
</script>
