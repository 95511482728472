<template>
  <div class="mt-5 intro-y box">
    <div class="items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <div class="mr-auto text-base font-medium">
        <div class="flex">
          <span>Email Setting</span>
          <AlertCircleIcon v-if="profile?.isEmailVerified == false" class="w-4 h-4 mt-1 ml-2 text-yellow-400 " />
        </div>
      </div>
      <label v-if="profile?.isEmailVerified == false" class="mt-2 text-yellow-400 form-label">
        ** This email has not been verified.
      </label>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <label for="profile-form-1" class="form-label">Old Email</label>
            <input id="profile-form-1" type="email" class="form-control" v-model="refEmail" disabled />
          </div>
          <div class="mt-3">
            <label id="profile-form-2" class="form-label">New Email</label>
            <label class="ml-2 text-theme-6">*</label>
            <input id="profile-form-2" type="email" class="form-control" placeholder="email..." :disabled="isUpdating" v-model="v$.email.$model" />
            <template v-if="v$?.email?.$errors">
              <div class="grid ">
                <label v-for="(error, index) in v$?.email?.$errors" :key="index" class="mt-2 form-label text-theme-6">
                  {{ error?.$message }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="justify-end p-5 mt-3 border-t border-gray-200 dark:border-dark-5">
      <div class="mt-3 mb-2 alert show alert-outline-warning" role="alert">
        <div class="flex items-center">
          <AlertCircleIcon class="mr-2 " style="align-self: start;height: 32px;width: 32px;" />
          <div class="inline-block">
            หากคุณมีการเปลี่ยนแปลง Email คุณจะต้องทำการยืนยันตัวตนผ่านทาง Email ใหม่อีกครั้ง
          </div>
        </div>
      </div>
      <div class="flex col-span-12 mt-5 -mb-3 text-theme-6" v-if="!$helpers.isNull(errorMessage)">
        <img :src="imgError" class="w-6 h-6 mr-2 " />
        {{ errorMessage }}
      </div>
      <div class="mt-7">
        <button type="button" class="mr-auto btn btn-primary"
                :disabled="isUpdating || $helpers.isNull(email) || v$?.email?.$errors?.length > 0" @click="onUpdateClicked">
          <LoadingIcon v-if="isUpdating"  icon="oval" class="w-6 h-6 mr-2 text-center"  />
           {{ isUpdating ? 'Updating' : 'Update' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { authService } from '@/services'
import imgError from '@/assets/images/Error32.png'
import { useVuelidate } from '@vuelidate/core'
import { required, email, not, sameAs, helpers } from '@vuelidate/validators'

export default defineComponent({
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  emits: ['update'],
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    isUpdating: false,
    refEmail: null,
    email: null,
    errorMessage: null,
    imgError
  }),
  validations () {
    return {
      email: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
        email: helpers.withMessage('รูปแบบของที่อยู่ของอีเมลที่ไม่ถูกต้อง', email),
        notSameAs: helpers.withMessage('อีเมลใหม่เหมือนกับอีเมลเก่า', not(sameAs(this.refEmail)))
      }
    }
  },
  methods: {
    async onUpdateClicked() {
      this.errorMessage = null
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$helpers.showToastify('Validate-Failed', 'Please check your email', true)
      } else {
        this.isUpdating = true
        try {
          const payload = { email: this.email }
          const res = await authService.updateEmail(this.profile.id, payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            this.$helpers.showToastify('Email updated successfully.')
            this.$emit('update')
          } else {
            this.errorMessage = res.message
          }
        } catch (error) {
          this.errorMessage = error.message
        } finally {
          this.isUpdating = false
        }
      }
    }
  },
  created() {
    this.refEmail = this.profile?.email
  },
  mounted() {
    this.v$.$touch()
  }
})
</script>
