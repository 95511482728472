<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div v-if="!isHide"
    class="z-50 flex items-center justify-center w-40 h-12 mb-10 mr-10 rounded-full shadow-md cursor-pointer dark-mode-switcher bg-color-violet dark:bg-dark-2"
    :class=" {'fixed bottom-0 right-0 box': !isAttach }"
    @click="switchMode"
  >
    <div class="mr-4 dark:text-gray-300">Dark Mode</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="border dark-mode-switcher__toggle"
    ></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from '@/store'
import * as mutationTypes from '@/store/mutation-types'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  props: ['isAttach', 'isHide'],
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.getters[`main/${getterTypes.GET_DARK_MODE}`])

    const setDarkModeClass = () => {
      darkMode.value
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }

    const switchMode = () => {
      store.commit(`main/${mutationTypes.SET_DARK_MODE}`, !darkMode.value)
      setDarkModeClass()
    }

    onMounted(() => {
      setDarkModeClass()
    })

    return {
      switchMode,
      darkMode
    }
  }
})
</script>
