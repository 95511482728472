<template>
  <div>
    <DarkModeSwitcher isHide="true" />
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Register Info -->
        <div class="flex-col hidden min-h-screen xl:flex">
          <div class="my-auto">
            <div class="flex flex-row items-center">
              <img alt="lw" class="-intro-x" style="margin-top: -50; margin-left: -50px" :src="
                darkMode
                  ? require('@/assets/images/lw-logo-name.png')
                  : require('@/assets/images/lw-logo-name-dark.png')
              " />
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Signup success Page -->
        <div class="flex flex-col items-center justify-center h-screen text-center lg:flex-row lg:text-left">
          <div v-if="isWaitForgot">
            <div class="mt-10 text-white lg:mt-0">
              <div class="mt-5 text-xl font-medium intro-x lg:text-3xl">
                อีเมลถูกส่งไปแล้ว
              </div>
              <div class="mt-3 text-lg intro-x">
                กรุณากลับไป Check Email: {{ email ? email : '-' }}
              </div>
              <button class="px-4 py-3 mt-10 text-white border-white intro-x btn dark:border-dark-5 dark:text-gray-300"
                @click="handleSignInBack($event)">
                กลับสู่หน้าหลัก
              </button>
            </div>
          </div>
          <!-- BEGIN: Register Form -->
          <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0" v-else>
            <div
              class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-32 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
              <form @submit.prevent="handleForgotpassword">
                <h2 class="text-2xl font-bold text-center intro-x xl:text-3xl xl:text-left">
                  ลืมรหัสผ่าน
                </h2>
                <div class="flex items-center mt-4 text-xs text-gray-700 intro-x dark:text-gray-600 sm:text-sm">
                  กรุณากรอกอีเมลที่ใช้ทำการสมัครสมาชิก StatementPro<br>ระบบจะทำการส่ง Link สำหรับ Reset รหัสผ่านไปให้ทางอีเมล
                </div>
                <div class="mt-2 text-center text-gray-500 intro-x dark:text-gray-500 xl:hidden">
                  A few more clicks to sign in to your account. Manage all your
                  e-commerce accounts in one place
                </div>
                <div class="mt-8 intro-x">
                  <div class="mt-4 border-gray-300 input-group login__input focus:intro-x">
                    <div id="input-group-email" class="input-group-text">
                      @
                    </div>
                    <input :disabled="isSubmiting" type="text" v-model.trim="validate.email.$model" placeholder="Email"
                      class="border-gray-300 form-control" aria-label="Email" aria-describedby="input-group-email" />
                  </div>
                  <template v-if="validate.email.$error">
                    <div v-for="(error, index) in validate.email.$errors" :key="index" class="mt-2 text-theme-6">
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <template v-if="state.invalidForgotPassword">
                  <div class="mt-2 text-theme-6">
                    {{ state.errorMessage }}
                  </div>
                </template>
                <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
                  <button class="w-full px-4 py-3 align-top btn btn-primary xl:w-32 xl:mr-3" :disabled="isSubmiting">
                    <div class="flex flex-col items-center justify-end sm:flex-row">
                      <div class="text-center">
                        {{ isSubmiting ? 'Submitting' : 'ส่ง' }}
                      </div>
                      <LoadingIcon v-if="isSubmiting" icon="oval" class="w-5 h-5 ml-3 text-center" />
                    </div>
                  </button>
                  <button :disabled="isSubmiting"
                    class="w-full px-4 py-3 mt-3 align-top btn btn-outline-secondary xl:w-32 xl:mt-0"
                    @click="handleSignIn">
                    กลับหน้า Login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- END: Register Form -->
        </div>
      </div>
      <!-- END: Signup Success Page -->
      <!-- BEGIN: Success Notification Content -->
      <div id="signup-success-notification-content" class="flex hidden toastify-content">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Sign Up success!</div>
          <div class="mt-1 text-gray-600">Let's go!</div>
        </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div id="validate-failed-notification-content" class="flex hidden toastify-content">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Sign Up failed!</div>
          <div class="mt-1 text-gray-600">Please check the fileld form.</div>
        </div>
      </div>
      <!-- END: Failed Notification Content -->
      <!-- BEGIN: SignUp Failed Notification Content -->
      <div id="signup-failed-notification-content" class="flex hidden toastify-content">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Sign Up failed!</div>
          <div class="mt-1 text-gray-600">
            {{ state.errorMessage }}
          </div>
        </div>
      </div>
      <!-- END: Signup Failed Notification Content -->
      <div id="signin-success-notification-content" class="flex hidden toastify-content">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Forgotpassword success!</div>
          <div class="mt-1 text-gray-600">Let's go!</div>
        </div>
      </div>
      <div id="signin-failed-notification-content" class="flex hidden toastify-content">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Forgotpassword failed!</div>
          <div class="mt-1 text-gray-600">
            {{ state.errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { required, email } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import Toastify from 'toastify-js'
import { authService } from '@/services'
import { useStore } from '@/store'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    const store = useStore()
    const state = reactive({ invalidForgotPassword: false, errorMessage: '' })
    const darkMode = computed(() => store.state.main.darkMode)
    const formData = reactive({
      email: ''
    })
    const rules = {
      email: {
        required,
        email
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const router = useRouter()
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const handleSignInBack = (event) => {
      event.preventDefault()
      router.push({
        name: 'login'
      })
    }

    const handleSignIn = () => {
      router.push('/login')
    }

    const displayToast = (displayType) => {
      Toastify({
        node: cash('#' + displayType + '-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const handleForgotpassword = async function handleForgotpassword() {
      validate.value.$touch()
      if (validate.value.$invalid) {
        displayToast('validate-failed')
      } else {
        const payload = {
          email: formData.email
        }
        this.isSubmiting = true
        authService
          .ForgotPassword(payload)
          .then((response) => {
            // console.log(response)
            if (response.status === 204 || response.message === 'Email นี้ไม่มีอยู่ในระบบ กรุณากรอกอีเมลใหม่ให้ถูกต้อง') {
              state.invalidForgotPassword = true
              this.isSubmiting = false
              state.errorMessage = response.message
              return
            }
            if (response.status && response.status === 500) {
              state.invalidForgotPassword = true
              state.errorMessage = response.message
              this.isSubmiting = false
              this.isWaitForgot = false
              displayToast('reset-password-failed')
            } else {
              this.isWaitForgot = true
              this.email = payload.email
              // console.log('email', this.email)
              // setTimeout(function () { router.push('/login') }, 1000)
              // handleLoginAfterRegister(response.refKey)
            }
          })
          .catch((error) => {
            state.invalidForgotPassword = true
            state.errorMessage = error.message
            displayToast('reset-password-failed')
            this.isSubmiting = false
            this.isWaitForgot = false
          })
      }
    }
    return {
      handleSignIn,
      handleSignInBack,
      handleForgotpassword,
      validate,
      darkMode,
      formData,
      state
    }
  },
  data() {
    return {
      isSubmiting: false,
      email: '',
      isWaitForgot: false,
      isShowPassword: false
    }
  }
})
</script>
