// Main
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_PAGE = 'SET_PAGE'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_IMAGE = 'SET_IMAGE'
export const SET_PACKAGE = 'SET_PACKAGE'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_TOKEN = 'SET_TOKEN'

export const CLEAR_PAGE = 'SET_PAGE'
export const CLEAR_PROFILE = 'SET_PROFILE'
export const CLEAR_IMAGE = 'SET_IMAGE'
export const CLEAR_PACKAGE = 'SET_PACKAGE'
export const CLEAR_PROVIDER = 'SET_PROVIDER'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'

// Local
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME'
export const CLEAR_REMEMBER_ME = 'CLEAR_REMEMBER_ME'
