<template>
  <div ref="main">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
        <div class="flex flex-col mt-3 sm:flex-row">
          <button v-if="isSyncProvider === true" type="button" :disabled="isLoading || isSyncing || !isContentLoaded"
            class="self-center mt-2 mr-2 shadow-md btn btn-primary preview " @click="onSyncAccountProviderClicked">
            Sync Bank Accounts
            <LoadingIcon v-if="isSyncing" icon="oval" class="w-5 h-5 ml-2" />
          </button>
          <button v-else type="button" class="self-center mt-2 mr-2 shadow-md btn btn-primary preview"
            @click="onOpenNewAccountClicked($event)">
            New Bank Accounts
          </button>
          <button @click="onDownloadAllClicked($event)" v-if="!isSyncProvider"
            class="self-center mt-2 ml-2 shadow-md btn btn-primary preview ">
            Download Statement</button>
        </div>
        <div class="mt-5 mb-5 border-white" style="border-top-width: 1px;" />
        <div class="flex flex-col justify-between sm:flex-row">
          <div role="tablist" class="nav nav-tabs">
            <div class="flex">
              <LWDropdownMultiSelect class="mr-2" label="Bank" :options="bankOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onBankSelected($event)" />
              <LWDropdownMultiSelect class="mr-2" label="Working Date" :options="workingDateOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onWorkingDateSelected($event)" />
              <LWDropdownMultiSelect class="mr-2" label="Statement Type" :options="statementTypeOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onStatementTypeSelected($event)" />
              <LWDropdownMultiSelect class="mr-2" label="Status" :options="statusOptions"
                :disabled="isLoading || !isContentLoaded" @selectionchange="onStatusSelected($event)" />
            </div>
          </div>
          <div class="flex w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
            <div class="relative w-56 text-gray-700 dark:text-gray-300">
              <input type="text" class="w-56 pr-10 form-control box placeholder-theme-13" placeholder="Search"
                :disabled="isLoading || !isContentLoaded" v-model="searchQuery" />
              <SearchIcon class="absolute inset-y-0 w-4 h-4 my-auto mt-3 mr-3 right-1" />
            </div>
          </div>
        </div>
        <table v-if="isLoading || !isContentLoaded" class="table mt-2 table-report">
          <tbody>
            <tr v-for="item in 3" :key="item" class="intro-x">
              <td class="">
                <Skeletor />
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
              <td class="">
                <Skeletor />
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <Skeletor />
                </div>
              </td>
              <td v-if="!isSyncProvider">
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  <Skeletor />
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else-if="isLoading === false && isContentLoaded ? filteredItems.length > 0 : false"
          class="table mt-2 table-report">
          <thead>
            <tr>
              <th class="text-center whitespace-nowrap">Code</th>
              <th class="whitespace-nowrap"></th>
              <th class="text-center whitespace-nowrap">Bank</th>
              <th class="text-center whitespace-nowrap">Service</th>
              <th class="text-center whitespace-nowrap">Account Number</th>
              <th class="text-center whitespace-nowrap">Working Date</th>
              <th class="text-center whitespace-nowrap">Status</th>
              <th class="text-center whitespace-nowrap" v-if="!isSyncProvider"></th>
              <th class="text-center whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredItems" :key="item.id" class="intro-x">
              <td>
                <div>
                  {{ item?.bankCode }}
                </div>
              </td>
              <td class="w-80 ">
                <div v-if="item.bankId !== -1" class="flex items-center justify-center">
                  <Multiselect v-model="item.scretVaultId" :close-on-select="true" :searchable="true"
                    placeholder="Please select credential" :create-option="true" :allow-empty="false"
                    :options="getListFilterSecretVaultByBank(item)" valueProp="id" trackBy="id" label="name"
                    :canDeselect="false" :canClear="false" @select="saveSingle(item)" />
                  <div class="ml-2 " v-if="getFilterSecretVaultBySecretVault(item) != null">
                    <Tippy v-if="getFilterSecretVaultBySecretVault(item).isExpiryDate" style="cursor:auto ;"
                      class="flex items-center justify-center w-8 h-8 rounded-full " content="Credential has expired.">
                      <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgExpired" />
                    </Tippy>
                    <Tippy v-if="getFilterSecretVaultBySecretVault(item).isAboutExpiry" style="cursor:auto;"
                      class="flex items-center justify-center w-8 h-8 ml-2 rounded-full text-gray-50 zoom-in"
                      content="The credential is about to expire.">
                      <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgDeadlineExpired" />
                    </Tippy>
                    <Tippy v-if="!getFilterSecretVaultBySecretVault(item).isVault" style="cursor:auto;"
                      class="flex items-center justify-center w-8 h-8 ml-2 rounded-full text-gray-50 zoom-in"
                      content="The credential has crashed. Please update new information.">
                      <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgSecurityWarning" />
                    </Tippy>
                  </div>
                </div>
                <div v-else class="text-yellow-500 ">
                  <a href="#" @click="onOpenEditAccountClicked(item)" data-toggle="modal"
                    data-target="#edit-profile-modal-slide-over">
                    <span> ** Please Update Bank Information</span>
                    <EditIcon class="w-4 h-4 ml-3 mr-2 -mt-1 " />
                  </a>
                </div>
              </td>
              <td>
                <div v-if="item.bankId !== -1" class="flex ">
                  <i :class="item.iconClass" class="mr-2" />
                  <span>
                    {{ item?.bankGroupShortNameEn }} - {{ item.bankShortName }}</span>
                </div>
                <div v-else class="flex ">
                  <i :class="item.iconClass" class="mr-2" />
                  <span>{{ item?.bankGroupShortNameEn }}</span>
                </div>
              </td>
              <td>
                <div class="text-center">
                  {{ item?.bankServiceTypeName }}
                </div>
              </td>
              <td>
                <div class="text-center">
                  {{ item?.bankAccountNo }}
                </div>
              </td>
              <td>
                <div v-if="item.bankId !== -1" class="text-center">
                  {{ onRowWorkingDateDisplayed(item) }}
                </div>
              </td>
              <td class="w-40">
                <div v-if="item.bankId !== -1">
                  <div v-if="Boolean(item.isSystemEnable) === true && Boolean(item.isUserEnable) === true
                    " class="flex items-center justify-center text-color-highlight">
                    Active
                  </div>
                  <div v-else-if="Boolean(item.isSystemEnable) === false"
                    class="flex items-center justify-center text-color-error">
                    Error
                  </div>
                  <div v-else-if="Boolean(item.isUserEnable) === false" class="flex items-center justify-center">
                    Inactive
                  </div>
                </div>
              </td>
              <td v-if="!isSyncProvider">
                <div class="flex flex-row justify-center" v-if="item.bankId !== -1">
                  <a href="#" @click="onDownloadClicked(item)" data-toggle="modal" data-target="#download-one-modal">
                    <img class="ml-2 cursor-pointer w-7 h-7" style="box-shadow: none" :src="imgDownload" />
                  </a>
                </div>
              </td>
              <td class="w-20">
                <div class="ml-auto dropdown" v-if="item.bankId !== -1">
                  <a class="block w-5 h-5 -mr-2 dropdown-toggle" href="javascript:;" aria-expanded="false">
                    <MoreVerticalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                  </a>
                  <div class="w-40 dropdown-menu">
                    <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                      <a href="#" @click="onRowActiveBankClicked(item)"
                        class="items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2">
                        <div v-if="Boolean(item.isUserEnable)">
                          <XIcon class="w-4 h-4 mr-2" />
                          Inactive
                        </div>
                        <div v-else>
                          <CheckIcon class="w-4 h-4 mr-2" />
                          Active
                        </div>
                      </a>
                    </div>
                    <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                      <a href="#" @click="onOpenEditAccountClicked(item)" data-toggle="modal"
                        data-target="#edit-profile-modal-slide-over"
                        class="items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2">
                        <div>
                          <EditIcon class="w-4 h-4 mr-2" />
                          Edit
                        </div>
                      </a>
                    </div>
                    <div v-if="!isSyncProvider" class="p-2 dropdown-menu__content box dark:bg-dark-1">
                      <a href="javascript:;" data-toggle="modal" @click="onRowDeleteClicked(item)"
                        class="items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2">
                        <div>
                          <Trash2Icon class="w-4 h-4 mr-2" />
                          Delete
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else-if="isLoading === false && isContentLoaded ? filteredItems.length < 1 : false"
          class="px-5 pt-5 mt-5 intro-y box">
          <div class="pb-5 -mx-5 border-b border-gray-200 dark:border-dark-5">
            <div style="justify-content: center; display: flex"
              class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
              <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
            </div>
            <div style="justify-content: center; display: flex"
              class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
              <div class="mt-5 font-medium text-center lg:text-left"> Bank information is not available.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="delete-modal-preview" class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <div class="mt-2 text-white">
                Please Selected some accounts first.<br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-dismiss="modal" class="w-24 btn btn-primary">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="p-0 modal-body">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
            <div class="mt-5 text-3xl">Are you sure?</div>
            <div class="mt-2 text-gray-600">
              Do you really want to delete these records? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="w-24 mr-1 btn btn-outline-secondary">
              Cancel
            </button>
            <button type="button" @click="deleteBank(deleteRow)" class="w-24 btn btn-danger">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Delete Confirmation Modal -->
  <!-- BEGIN: Inactive Latest Confirmation Modal -->
  <div id="inactive-confirmation-modal" class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="p-0 modal-body">
          <div class="p-5">
            <div class="mt-2 border-b border-gray-200 dark:border-dark-5">
              <div class="mb-2">Bank Code : {{ refData?.bankCode }}</div>
              <div class="flex mb-2">
                <div class="mr-2">Bank Name:</div>
                <i :class="refData?.iconClass" class="mr-2" style="width: 16px; height: 16px" />
                <label>
                  {{ refData?.bankGroupShortNameEn }} -
                  {{ refData?.bankShortName }}
                </label>
              </div>
              <div class="flex mb-2">
                <div class="mr-2">Status :</div>
                <label :class="refData.isActive ? 'text-red-600' : 'text-green-500'">
                  {{ refData?.isActive ? 'Inactive' : 'Active' }}
                </label>
              </div>
            </div>
            <div class="mt-3">
              หากคุณต้องการที่จะ
              <span :class="!refData.isActive ? 'text-theme-6' : ''">{{ !refData?.isActive ? 'Inactive' : '' }}</span>
              ของ Bank Account นี้จะทำให้ Bank Account ที่มีการผูกกับ Job Schedule
              นี้ปรับเป็น
              <span :class="!refData.isActive ? 'text-theme-6' : ''">
                {{ !refData?.isActive ? 'Inactive' : '' }}</span>
              ไปด้วย
            </div>
            <div class="p-5 mt-3 box max-h-96 scroll" style="text-align: -webkit-center;">
              <div v-if="!isLoaded" style="width: 32px;height: 32px;">
                <LoadingIcon icon="oval" />
              </div>
              <div v-else-if="listJobSchedule.length > 0" class="text-left">
                Job Name : {{ listJobSchedule[0].jobName ? listJobSchedule[0].jobName.JobName : '' }}
              </div>
              <div v-else class="text-center">
                No Job Schedule Name
              </div>
            </div>
            <div class="mt-3">
              คุณยืนยันที่จะ
              <span :class="!refData.isActive ? 'text-theme-6' : ''">
                {{ !refData?.isActive ? 'Inactive' : '' }}</span>
              ใช่หรือไม่?
            </div>
          </div>
          <div style="padding-left: 20px; padding-right: 20px">
            <div class="text-center border-t border-gray-200 dark:border-dark-5" style="padding: 16px">
              <div class="mb-2 -mt-2 -ml-4 text-left text-red-400" v-if="!$helpers.isNull(errorMessage)">
                <img :src="imgError" class="w-6 h-6 mr-2" />
                <span>{{ errorMessage }}</span>
              </div>
              <button ref="elClose" type="button" data-dismiss="modal" @click="onCloseClicked"
                class="h-10 mr-2 text-right btn btn-outline-secondary dark:border-dark-5 dark:text-gray-300">
                Cancel
              </button>
              <button type="button" class="h-10 mr-1 text-right btn btn-primary" @click="onLatestInActiveClicked">
                <div class="flex flex-col items-center justify-end sm:flex-row">
                  <div class="text-center">
                    {{ !refData?.isActive ? 'Inactive' : '' }}
                  </div>
                  <LoadingIcon v-if="refData?.isActive" icon="oval" class="w-6 h-6 ml-3 text-center" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Inactive Latest Confirmation Modal -->
  <BankStatementDownloadOneView ref="BankStatementDownloadOneView" id="download-one-modal" />

  <BankStatementEditView ref="BankStatementEditView" @update="onBankStatementEditViewUpdated($event)"
    id="edit-profile-modal-slide-over" />
</template>
<style></style>
<script>
import moment from 'moment'
import { bankService, jobScheduleService } from '@/services'
import syncImg from '@/assets/images/refresh.png'
import imgDownload from '@/assets/images/Download32.png'
import { mapGetters } from 'vuex'
import { useRouter } from 'vue-router'
import imgExpired from '@/assets/images/Expired64.png'
import imgEmptyFolder from '@/assets/images/EmptyFolder64.png'
import imgDeadlineExpired from '@/assets/images/DeadlineExpired64.png'
import imgSecurityWarning from '@/assets/images/SecurityWarning64.png'
import BankStatementEditView from '@/views/corgio/bankstatement-edit/Main.vue'
import BankStatementDownloadOneView from '@/views/corgio/bankstatement-download-one/Main.vue'
import { defineComponent } from 'vue'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  components: { BankStatementEditView, BankStatementDownloadOneView },
  computed: {
    ...mapGetters({
      currentQuotaData: 'main/currentQuotaData',
      isSyncProvider: `main/${getterTypes.GET_IS_SYNC_PROVIDER}`
    }),
    filteredItems() {
      let filtered = JSON.parse(JSON.stringify(this.listAccounts))
      if (this.bankSelected.length > 0 && Array.from(this.bankSelected[0]).length > 0) {
        filtered = filtered.filter(account => Array.from(this.bankSelected[0]).includes(account.releateBankSite))
      }

      if (this.workingDateSelected.length > 0 && Array.from(this.workingDateSelected[0]).length > 0) {
        filtered = filtered.filter(account => Array.from(this.workingDateSelected[0]).includes(account.workingDateDay))
      }

      if (this.statementTypeSelected.length > 0 && Array.from(this.statementTypeSelected[0]).length > 0) {
        filtered = filtered.filter(account => Array.from(this.statementTypeSelected[0]).includes(account.statementTypeId))
      }

      if (this.statusSelected.length > 0 && Array.from(this.statusSelected[0]).length > 0) {
        filtered = filtered.filter(item => {
          if (Boolean(item.isSystemEnable) === false) {
            return Array.from(this.statusSelected[0]).includes('Error')
          } else if (Boolean(item.isUserEnable) === false) {
            return Array.from(this.statusSelected[0]).includes('Inactive')
          } else {
            return Array.from(this.statusSelected[0]).includes('Active')
          }
        })
      }
      if (this.searchQuery) {
        return filtered.filter((item) => {
          return this.searchQuery.toLowerCase().split(' ').every(v => item.bankCode.toLowerCase().includes(v) || item.bankAccountNo.toString().includes(v)) || this.searchQuery.toLowerCase().split(' ').every(v => item.bankAccountNo.toString().includes(v)) || this.searchQuery.toLowerCase().split(' ').every(v => item.scretVault ? item.scretVault.name.toLowerCase().includes(v) : null)
        })
      } else {
        return filtered
      }
    }
  },
  data() {
    return {
      router: null,
      listAccounts: [],
      state: { invalidToast: false, notifyMessage: '' },
      currentRemoveId: 0,
      imgDeadlineExpired,
      imgExpired,
      imgSecurityWarning,
      imgEmptyFolder,
      imgDownload,
      isUpdatingOrCreating: false,
      syncImg,
      totalScholar: 0,
      lastSync: moment(),
      listScholarHistory: [],
      totalAvgSLP: 0.0,
      showTotalAvgSlp: false,
      currentPage: 1,
      pageSize: 3,
      lastPage: 0,
      isLoading: false,
      isContentLoaded: false,
      isSyncing: false,
      axieModalItem: false,
      currentIndex: 0,
      cloud: {
        username: '',
        password: ''
      },
      listBankStatementType: [],
      listWorkingDate: [],
      listSecretValut: [],
      secretvalutSelected: '',
      selected: 'Select Credential',
      selectDates: [],
      selectRow: null,
      deleteRow: null,
      notificationMessage: {
        title: '',
        body: '',
        iserror: false
      },
      dateFormatter: {
        date: 'YYYY-MM-DD',
        month: 'MMM'
      },
      errorMessage: null,
      searchQuery: null,
      listBankName: [],
      bankOptions: [],
      bankSelected: [],
      workingDateOptions: [],
      workingDateSelected: [],
      statusOptions: [{ label: 'Active', value: 'Active', iconClass: '' }, { label: 'Inactive', value: 'Inactive', iconClass: '' }, { label: 'Error', value: 'Error', iconClass: '' }],
      statusSelected: [],
      statementTypeOptions: [],
      statementTypeSelected: [],
      refData: {},
      isLoaded: false,
      listJobSchedule: []
    }
  },
  created() {
    this.router = useRouter()
  },
  methods: {
    async onBankStatementEditViewUpdated(event) {
      await this.GetBankStatementConfig()
    },
    onBankSelected(event) {
      this.bankSelected = []
      this.bankSelected.push(event.sources)
    },
    onWorkingDateSelected(event) {
      this.workingDateSelected = []
      this.workingDateSelected.push(event.sources)
    },
    onStatusSelected(event) {
      this.statusSelected = []
      this.statusSelected.push(event.sources)
    },
    onStatementTypeSelected(event) {
      this.statementTypeSelected = []
      this.statementTypeSelected.push(event.sources)
    },
    getListFilterSecretVaultByBank(item) {
      const filter = this.listSecretValut.filter(_ => _.bankId === item.bankId)
      return filter ?? []
    },
    getFilterSecretVaultBySecretVault(item) {
      const filter = this.listSecretValut.filter(_ => _.id === item.scretVaultId)
      return (filter?.length > 0 ? filter[0] : null) ?? null
    },
    onRowWorkingDateDisplayed(item) {
      const temp = this.listWorkingDate.find(
        (_) => _.days === item.workingDateDay
      )
      if (temp) {
        return temp.name
      }
      return '-'
    },
    updateIsDisableDownloadAll() {
      this.isDisableDownloadAll =
        this.listDownloadBackdate.filter((_) => _.IsCheck).length < 1 &&
        this.listDownloadOther.filter((_) => _.IsCheck).length < 1 &&
        this.listDownloadIntraday.filter((_) => _.IsCheck).length < 1
    },
    onDownloadAllClicked(event) {
      event.preventDefault()
      this.router.push({
        name: 'bankmanagement-download'
      })
    },
    onDownloadClicked(item) {
      this.$refs.BankStatementDownloadOneView.open(item)
      cash('#download-one-modal').modal('show')
    },
    async saveSingle(rowItem) {
      const res = await bankService.updateSecretVault(rowItem.id, {
        id: rowItem.scretVaultId
      })
      if (res?.statusCode === null || res?.statusCode === undefined) {
        this.$helpers.showToastify('Bank "' + rowItem.bankCode + '" changed credential!')
      } else {
        this.$helpers.showToastify('Bank "' + rowItem.bankCode + '" failed to change credential!', res.message, true)
      }
      try {
        await Promise.all([
          this.GetBankStatementConfig().catch(error => console.error('Error in GetBankStatementConfig', error)),
          this.getLoadJobScheduleConfig().catch(error => console.error('Error in getLoadJobScheduleConfig', error))
        ])
      } catch (error) {
        console.error('Error in Promise.all', error)
      }
    },
    async GetBankStatementConfig() {
      this.isLoading = true
      const res = await bankService.GetBankStatementConfig()
      this.listAccounts = res.map((x) => {
        x.IS_CHECKED = false
        if (this.listSecretValut === undefined) {
          return x
        }
        var tmpfind = this.listSecretValut.find((_) => _.id == x.scretVaultId)
        if (tmpfind !== null && tmpfind !== undefined && tmpfind !== '') {
          x.uuid = tmpfind.uuid
          x.scretVault = tmpfind
        }
        return x
      })
      this.isLoading = false
    },
    async deleteBank(item) {
      try {
        await bankService.DeleteBankStatementConfig(item.id)
        cash('#delete-confirmation-modal').modal('hide')
        await this.GetBankStatementConfig()
        this.$helpers.showToastify('Successfully!', 'deleted a bank account.', false)
        await this.getLoadJobScheduleConfig()
      } catch (e) {
        this.$helpers.showToastify('Failed to delete bank account!', e.message, true)
      }
    },
    async onRowActiveBankClicked(item) {
      this.$refs.main.click()
      if (item.scretVaultId === null || item.scretVaultId === '') {
        this.$helpers.showToastify(
          'Active failed!',
          'Please select credential first.',
          true
        )
        return
      }
      let temp = Boolean(item.isUserEnable)
      temp = !temp
      const sameGroupItems = this.filteredItems.filter(i =>
        i.scretVaultId === item.scretVaultId &&
        JSON.stringify(i.workingDateDay) === JSON.stringify(item.workingDateDay) &&
        i.isUserEnable
      )
      if (sameGroupItems.length === 1 && sameGroupItems[0].isUserEnable && temp === false) {
        if (item.isUserEnable) {
          this.refData = { ...item, isActive: temp }
          this.listJobSchedule = await jobScheduleService.getJobScheduleConfigByBankId(this.refData?.id) ?? []
          this.isLoaded = true
          cash('#inactive-confirmation-modal').modal('show')
          return
        }
      }
      const res = await bankService.activeBankStatementConfig(item.id, { active: temp })
      if (res?.statusCode === null || res?.statusCode === undefined) {
        await this.GetBankStatementConfig()
        this.$helpers.showToastify(
          temp === true
            ? 'Bank "' + item.bankCode + '" active successfully!'
            : 'Bank "' + item.bankCode + '" inactive successfully!'
        )
      } else {
        this.$helpers.showToastify(
          temp === true
            ? 'Bank "' + item.bankCode + '" active failed!'
            : 'Bank "' + item.bankCode + '" inactive failed!',
          res.message,
          true
        )
      }
    },
    async onLatestInActiveClicked() {
      // this.refData.isActive = true
      this.refData.errorMessage = null
      try {
        await bankService.activeBankStatementConfig(this.refData.id, { active: this.refData.isActive })
        cash('#inactive-confirmation-modal').modal('hide')
        this.$helpers.showToastify(
          'Bank "' + this.refData.bankCode + '" inactive successfully!'
        )
        await this.GetBankStatementConfig()
        // await this.getLoadJobScheduleConfig()
        // InActive ที่ JobSchdule ตัวสุดท้ายของ BankAccount
        if (this.listJobSchedule.length > 0) {
          const res = await jobScheduleService.InActiveJobScheduleConfig(this.listJobSchedule[0].jobName.GroupBankStatementConfigId, { active: false })
          console.log('res', res)
        }
      } catch (e) {
        this.errorMessage = e.message
        this.$helpers.showToastify(
          'Bank "' + this.refData.bankCode + '" inactive failed!',
          true
        )
      }
    },
    async onCloseClicked() {
      this.refData = {}
      this.listJobSchedule = []
      this.errorMessage = null
      this.isActive = false
      this.isLoaded = false
    },
    onOpenEditAccountClicked(item) {
      this.$refs.main.click()
      this.$refs.BankStatementEditView.open(item)
    },
    onRowDeleteClicked(e) {
      this.$refs.main.click()
      this.deleteRow = e
      cash('#delete-confirmation-modal').modal('show')
    },
    async onOpenNewAccountClicked(event) {
      event.preventDefault()
      this.router.push({
        name: 'bankmanagement-create'
      })
    },
    async onSyncAccountProviderClicked() {
      this.isSyncing = true
      try {
        const res = await bankService.putSyncBankProvider()
        if (this.$helpers.isNull(res?.statusCode)) {
          await this.GetBankStatementConfig()
        } else {
          this.$helpers.showToastifyResponseError(res)
        }
      } catch (err) {
        this.$helpers.showToastifyResponseError('Error', err.message, true)
      } finally {
        this.isSyncing = false
      }
    },
    async getSecretVault() {
      const res = await bankService.GetSecretVault()
      this.listSecretValut = res
    },
    async getBankName() {
      const res = await bankService.GetBankName()
      this.listBankName = res

      if (res !== null && res.length > 0) {
        for (let index = 0; index < res.length; index = index + 1) {
          this.bankOptions.push({ label: res[index].bankGroupShortNameEn + ' - ' + res[index].bankShortName, value: res[index].releateBankSite, iconClass: res[index].iconClass })
        }
      }
    },
    async getBankStatementType() {
      const res = await bankService.GetBankStatementType()
      this.listBankStatementType = res
      if (res !== null && res.length > 0) {
        for (let index = 0; index < res.length; index = index + 1) {
          this.statementTypeOptions.push({ label: res[index].name, value: res[index].id, iconClass: '' })
        }
      }
    },
    async getWorkingDate() {
      const res = await bankService.GetWorkingDate()
      this.listWorkingDate = res
      if (res !== null && res.length > 0) {
        for (let index = 0; index < res.length; index = index + 1) {
          this.workingDateOptions.push({ label: res[index].name, value: res[index].days, iconClass: '' })
        }
      }
    },
    async getLoadJobScheduleConfig() {
      const res = await jobScheduleService.GetLoadJobScheduleConfig()
      console.log('JobScheduleConfig', res)
    }
  },
  async mounted() {
    await this.getSecretVault()
    await this.getBankName()
    await this.getBankStatementType()
    await this.getWorkingDate()
    await this.GetBankStatementConfig()
    await this.getLoadJobScheduleConfig()
    this.isContentLoaded = true
  }
})
</script>
