<template>
  <FaqProviderView v-if="isSyncProvider"/>
  <FaqGeneralView v-else/>
</template>

<script>

import FaqGeneralView from '@/views/corgio/faq-general/Main.vue'
import FaqProviderView from '@/views/corgio/faq-provider/Main.vue'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  components: {
    FaqGeneralView, FaqProviderView
  },
  computed: {
    ...mapGetters({
      isSyncProvider: `main/${getterTypes.GET_IS_SYNC_PROVIDER}`
    })
  }
})
</script>
