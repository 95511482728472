<template>
  <router-view />
</template>
<style>

@font-face {
  font-family: "Athiti";
  src: local("Athiti"),
   url(./assets/fonts/Athiti/Athiti-Regular.ttf) format("truetype");
  unicode-range: U+0E00-U+0E7F;
}

@font-face {
  font-family: "Readex Pro";
  src: local("Readex Pro"),
   url(./assets/fonts/Readex_Pro/static/ReadexPro-Regular.ttf) format("truetype");
  unicode-range: U+41-5A, U+61-7A, U+C0-FF;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%); } }

</style>
