import * as getterTypes from '@/store/getter-types'
import * as actionTypes from '@/store/action-types'

export default async function Auth({ next, to, store }) {
  const isLoggedIn = store.getters[`main/${getterTypes.GET_IS_LOGGED_IN}`]
  if (isLoggedIn) {
    if (to.meta?.requirePage === true) {
      return next({
        path: '/'
      })
    }
    const hasProfile = store.getters[`main/${getterTypes.GET_HAS_PROFILE}`]
    if (!hasProfile) {
      await store.dispatch(`main/${actionTypes.UPDATE_USER_DATA}`)
    } else {
      // not use await because this func need run background
      store.dispatch(`main/${actionTypes.UPDATE_PACKAGE}`)
    }
    return next()
  }
  if (to.meta?.requirePage !== true) {
    return next({
      name: 'login'
    })
  }
  return next()
}
