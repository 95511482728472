<template>
  <div>
    <DarkModeSwitcher isHide="true" />
    <div v-if="!isLoaded" class="container">
      <div class="flex flex-col lg:flex-row items-center justify-center h-screen text-center ">
        <div class="flex flex-col justify-end items-center">
          <LoadingIcon icon="circles" class="-intro-x w-12 h-12" />
          <div class="mt-5 intro-x text-center">
            <span style="font-size: 16px;">
              Please wait, The system is verifying link...
            </span>
          </div>
        </div>
      </div>
    </div>
    <component v-if="!$helpers.isNull(selectVerifyComponent)" v-model="query" :message="message"
               @loaded="onComponentLoaded" @refresh="onComponentRefreshed" @error="onComponentError($event)"
               @already="onComponentAlReady($event)"
               :key="selectVerifyComponent.id" :is="selectVerifyComponent.component"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import VerifyEmailView from '@/views/corgio/verify-email/Main.vue'
import VerifyRegisterView from '@/views/corgio/verify-register/Main.vue'
import VerifyResetPasswordView from '@/views/corgio/verify-reset-password/Main.vue'
import VerifyErrorView from '@/views/corgio/verify-error/Main.vue'
import VerifyAlReadyView from '@/views/corgio/verify-already/Main.vue'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    VerifyEmailView,
    VerifyRegisterView,
    VerifyResetPasswordView,
    VerifyErrorView,
    VerifyAlReadyView
  },
  data: () => ({
    query: null,
    message: null,
    isLoaded: false,
    listVerifyComponent: [],
    selectVerifyComponent: null
  }),
  methods: {
    onComponentLoaded() {
      this.isLoaded = true
    },
    onComponentRefreshed() {
      this.isLoaded = false
      this.selectVerifyComponent = null
      this.$nextTick(() => {
        this.setDefaultSelectComponent()
      })
    },
    onComponentError(obj) {
      this.message = obj
      this.selectVerifyComponent = this.listVerifyComponent.find(_ => _.id == 1)
    },
    onComponentAlReady(obj) {
      this.message = obj
      this.selectVerifyComponent = this.listVerifyComponent.find(_ => _.id == 5)
    },
    setDefaultSelectComponent() {
      this.selectVerifyComponent = this.listVerifyComponent.find(_ => this.query?.action === _.name)
      if (this.selectVerifyComponent == null) {
        this.selectVerifyComponent = this.listVerifyComponent[0]
        this.message = 'ลิงค์นี้ทำงานไม่ถูกต้อง โปรดตรวจสอบข้อมูลลิงค์อีกครั้ง'
      }
    }
  },
  created() {
    this.listVerifyComponent.push({
      id: 1,
      name: 'error',
      component: 'VerifyErrorView'
    })
    this.listVerifyComponent.push({
      id: 2,
      name: 'register',
      component: 'VerifyRegisterView'
    })
    this.listVerifyComponent.push({
      id: 3,
      name: 'change-email',
      component: 'VerifyEmailView'
    })
    this.listVerifyComponent.push({
      id: 4,
      name: 'reset-password',
      component: 'VerifyResetPasswordView'
    })
    this.listVerifyComponent.push({
      id: 5,
      name: 'already',
      component: 'VerifyAlReadyView'
    })
  },
  mounted() {
    this.query = {
      key: this.$route.query?.key?.trim(),
      code: this.$route.query?.code?.trim(),
      action: this.$route.query?.action?.toLowerCase()?.trim()
    }
    this.setDefaultSelectComponent()
  }
})
</script>
