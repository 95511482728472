<template>
  <div id="delete-modal-preview" class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" v-if="isShow">
      <div class="modal-content">
        <div class="p-0 modal-body">
          <div class="p-5">
            <div class="mt-2 border-b border-gray-200  dark:border-dark-5">
              <div class="mb-2"> Credential Name : {{ refData?.name }} </div>
              <div class="flex mb-2">
                <div class="mr-2"> Bank : </div>
                <i :class="refData?.iconClass" class="mr-2" style="width:16px;height:16px" />
                <label> {{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }} </label>
              </div>
              <div class="flex mb-2">
                <div class="mr-2"> Status : </div>
                <label :class="refData.isActive ? 'text-green-600' : 'text-theme-6'"> {{ refData?.isActive ? 'Active' :
                  'Inactive' }} </label>
              </div>
            </div>
            <div class="mt-3">
              หากคุณต้องการที่จะ <span :class="!refData.isActive ? 'text-green-600' : 'text-theme-6'"> {{
                refData?.isActive ? 'Inactive' : 'Active' }}</span> ของ Credential นี้จะทำให้ Credential ที่มีการผูกกับ
              Bank Account นี้ปรับเป็น <span :class="!refData.isActive ? 'text-green-600' : 'text-theme-6'"> {{
                refData?.isActive ? 'Inactive' : 'Active' }}</span> ไปด้วย
            </div>
            <div class="p-5 mt-3 box max-h-96 scroll" style="text-align: -webkit-center;">
              <div v-if="!isLoaded" style="width: 32px;height: 32px;">
                <LoadingIcon icon="oval" />
              </div>
              <div v-else-if="listBank.length > 0" class="text-left">
                <div v-for="(item, index) in listBank" :key="index">
                  Code : {{ item.bankCode }}, Account No : {{ item.bankAccountNo }}
                </div>
              </div>
              <div v-else class="text-center">
                No Bank
              </div>
            </div>
            <div class="mt-3">
              และ Credential นี้ ที่มีการผูกกับ Job Schedule นี้จะหายไปด้วย
            </div>
            <div class="p-5 mt-3 box max-h-96 scroll" style="text-align: -webkit-center;">
              <div v-if="!isLoaded" style="width: 32px;height: 32px;">
                <LoadingIcon icon="oval" />
              </div>
              <div v-else-if="listJobSchedules.length > 0" class="text-left">
                <div v-for="(job, i) in listJobSchedules" :key="i">
                  Job Name : {{ job.jobName ? job.jobName.JobName : '' }}
                </div>
              </div>
              <div v-else class="text-center">
                No Job Schedule Name
              </div>
            </div>
            <div class="mt-3 ">
              คุณยืนยันที่จะ <span :class="!refData.isActive ? 'text-green-600' : 'text-theme-6'"> {{ refData?.isActive ?
                'Inactive' : 'Active' }}</span> ใช่หรือไม่?
            </div>
          </div>
          <div style="padding-left:20px;padding-right:20px">
            <div class="text-center border-t border-gray-200  dark:border-dark-5" style="padding: 16px;">
              <div class="mb-2 -mt-2 -ml-4 text-left text-red-400" v-if="!$helpers.isNull(errorMessage)">
                <img :src="imgError" class="w-6 h-6 mr-2 " />
                <span>{{ errorMessage }}</span>
              </div>
              <button ref="elClose" type="button" data-dismiss="modal" @click="onCloseClicked"
                class="h-10 mr-2 text-right btn btn-outline-secondary dark:border-dark-5 dark:text-gray-300">
                Cancel
              </button>
              <button type="button" class="h-10 mr-1 text-right btn btn-primary" @click="onActiveClicked"
                :disabled="!isLoaded || isActive">
                <div class="flex flex-col items-center justify-end sm:flex-row">
                  <div class="text-center">
                    {{ refData?.isActive ? 'Inactive' : 'Active' }}
                  </div>
                  <LoadingIcon v-if="isActive" icon="oval" class="w-6 h-6 ml-3 text-center" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { bankService, jobScheduleService } from '@/services'
import imgError from '@/assets/images/Error32.png'

export default defineComponent({
  data() {
    return {
      imgError,
      refData: null,
      isShow: false,
      isLoaded: false,
      isActive: false,
      errorMessage: null,
      listBank: [],
      listJobSchedules: []
    }
  },
  emits: ['update'],
  methods: {
    async open(data) {
      this.clearData()
      this.isShow = true
      this.refData = { ...data }
      this.listBank = await bankService.getBankStatementConfigBySecretVault(data.id) ?? []
      const bankIds = this.listBank?.map(bank => bank.id)
      this.listJobSchedules = await jobScheduleService.getJobScheduleConfigByBankId(bankIds) ?? []
      this.isLoaded = true
    },
    async onCloseClicked() {
      await this.delay(500)
      this.clearData()
    },
    async onActiveClicked() {
      this.isActive = true
      this.errorMessage = null
      try {
        await bankService.UpdateSecretVaultActive(this.refData.id, { isActive: !this.refData.isActive })
        this.refData.isActive = !this.refData.isActive
        this.$helpers.showToastify('Successfully', 'credential is ' + (this.refData.isActive ? 'activated!' : 'inactivated!'), false)
        this.$emit('update', this.refData)
        this.$refs.elClose.click()
      } catch (e) {
        this.errorMessage = e.message
        this.isActive = false
      }
    },
    clearData() {
      this.isShow = false
      this.refData = null
      this.errorMessage = null
      this.isActive = false
      this.isLoaded = false
      this.newData = {}
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
