import HttpRequest from './HttpRequest'

class DashboardService extends HttpRequest {
  constructor() {
    super(window.__env.api.apiBank + 'bankstatement-service/dashboard')
  }

  async GetBankStatus() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankStatus')
    const { data } = res
    return data
  }

  async GetTodayBankRunning() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('todayBankRunning')
    const { data } = res
    return data
  }

  async GetCredentialStatus() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('credentialStatus')
    const { data } = res
    return data
  }

  async GetTodayWorkResult() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('todayWorkResult')
    const { data } = res
    return data
  }

  async GetTodayGeneralResult() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('todayGeneralResult')
    const { data } = res
    return data
  }

  async GetTodayTransactionRun() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('todayTransactionRunning')
    const { data } = res
    return data
  }
}

export default DashboardService
