<template>
  <div class="toastify-content flex">
    <XCircleIcon v-if="iserror" class="text-theme-6" />
    <CheckCircleIcon v-else class="text-theme-9" />
    <div class="ml-4 mr-4">
      <div class="font-medium" v-if="title !== null || title !== ''">
        {{ title }}
      </div>
      <div class="text-gray-600 mt-1" v-if="body !== null || body !== ''" >
        {{ body }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { XCircleIcon, CheckCircleIcon } from '@zhuowenli/vue-feather-icons'

export default defineComponent({
  components: { XCircleIcon, CheckCircleIcon },
  props: {
    body: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    iserror: {
      type: Boolean,
      default: false
    }
  }
})
</script>
