<template>
  <div class="dropdown shadow-md preview self-center" :class="disabled ? 'disabled' : ''">
    <button :disabled="disabled" class="dropdown-toggle btn"  aria-expanded="false">
      {{ label }} <div v-if="currentSelected.length > 0" class="text-xs text-white px-2 rounded-md bg-theme-4 ml-2">{{
        currentSelected.length }}</div>
      <ChevronDownIcon class="w-4 h-4 ml-2" v-if="currentSelected.length === 0" />
    </button>
    <div class="dropdown-menu w-72">
      <div v-if="options?.length > 0" class="dropdown-menu__content box dark:bg-dark-1">
        <div class="p-4 border-b border-gray-200 dark:border-dark-5 font-medium">{{ label }}</div>
        <div class="p-2">
          <div v-for="(option, i) in options" :key="i" class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
            <LoadingIcon v-if="option?.isLoading" icon="oval" class="mr-2 w-5 h-5" />
            <input :disabled="disabled || option?.isLoading" :id="option.label + i"
                    @change="onCheckBoxChanged($event, option)" v-model="currentSelected"
                    class="form-check-input mr-2" type="checkbox" :value="option.value" />
            <i :for="option.label + i" v-if="!$helpers.isNull(option?.iconClass)" class="bank ml-2" :class="option.iconClass"/>
            <label :class="option?.isLoading ? 'disabled' : '' " style="width: 170px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis" class="ml-2"  :for="option.label + i" :title="option.label">
              {{ option.label }}
            </label>
          </div>
        </div>
        <div class="px-3 py-3 border-t border-gray-200 dark:border-dark-5 font-medium flex">
          <button v-if="currentSelected.length > 0" type="button" class="btn btn-secondary py-1 px-2"
            @click="onClearSelectClicked">{{ buttonLabelClearSelection }}</button>
          <label class="py-1 px-2 ml-auto">{{ options.length }} of {{ options.length }}</label>
        </div>
      </div>
      <div class="p-5 text-center dropdown-menu__content box dark:bg-dark-1" v-else>
        <label style="text-decoration: underline;">
          No Data
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: 'Show Dropdown',
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      buttonLabelClearSelection: 'Clear Selection',
      currentSelected: [...this.modelValue]
    }
  },
  emits: [
    'selectionchange',
    'update:modelValue'
  ],
  methods: {
    onCheckBoxChanged(event, item) {
      this.$emit('update:modelValue', this.currentSelected)
      this.$emit('selectionchange', {
        source: item,
        sources: this.currentSelected
      })
    },
    onClearSelectClicked() {
      this.currentSelected = []
      this.$emit('update:modelValue', this.currentSelected)
      this.$emit('selectionchange', {
        source: null,
        sources: this.currentSelected
      })
    }
  }
})
</script>
