<template>
  <div class="container" v-if="isVerified">
    <div class="intro-x flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
      <div  class="flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
        <div class="-intro-x lg:mr-20">
          <img class="w-72" :src="imgEmailSuccess" />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium  text-theme-11 ">Successfully</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            ระบบทำการยืนยันการเปลี่ยน Email เรียบร้อยแล้ว
          </div>
          <div class="intro-x text-lg mt-3">
            ระบบจะพาคุณกลับหน้าหลักอัตโนมัติ หากระบบยังไม่ตอบสนองสามารถกดปุ่มด้านล่างเพื่อไปหน้าหลัก
          </div>
          <div class="flex">
            <button @click="onBackToHomeClicked" class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10 mr-5">
              Back to Home
              <span class="ml-2" v-if="timeoutRunner > 0">
                ({{ timeoutRunner }})
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { authService } from '@/services'
import imgEmailSuccess from '@/assets/images/Email-Success512.png'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    message: {
      type: String
    }
  },
  emits: [
    'refresh',
    'error',
    'loaded',
    'already'
  ],
  data: () => ({
    imgEmailSuccess,
    isVerified: false,
    router: null,
    timeout: 3000,
    timeoutRunner: 3
  }),
  methods: {
    onBackToHomeClicked() {
      this.router.push('/')
    },
    async onRefreshClicked() {
      this.$emit('refresh')
      await this.handleLoaded()
    },
    async verify() {
      try {
        const res = await authService.verifyEmail({ refKey: this.modelValue?.key, refCode: this.modelValue?.code })
        if (!this.$helpers.isNull(res?.statusCode)) {
          this.$emit('error', res.message)
        } else {
          if (res.isLinkNotFound) {
            this.$emit('error', res.message || res.isExpiryDate)
          } else if (res.isLinkActive) {
            this.$emit('already', res.message)
          } else {
            this.isVerified = true
            const myInterval = setInterval(() => {
              if (this.timeoutRunner == 0) {
                clearInterval(myInterval)
                this.onBackToHomeClicked()
              }
              this.timeoutRunner = this.timeoutRunner - 1
            }, this.timeout)
          }
        }
      } catch (e) {
        this.$emit('error', e.message)
      }
    },
    async beforeVerify() {
      this.isVerified = false
      this.timeoutRunner = this.timeout / 1000
      await this.delay(2000)
      await this.verify()
    },
    async delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds)
      })
    }
  },
  created() {
    this.router = useRouter()
  },
  async mounted() {
    await this.beforeVerify()
    this.$emit('loaded')
  }
})
</script>
