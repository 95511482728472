import HttpRequest from './HttpRequest'

class LogService extends HttpRequest {
  constructor() {
    super(window.__env.api.url + 'Log')
  }

  // payload.params
  //   {
  //     userId: id //userId
  //   }
  async getLogScholarSlpHistoryListByUserId(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('GetLogScholarSlpHistoryListByUserId?userId=' + payload)
    const { data } = res
    return data
  }
}

export default LogService
