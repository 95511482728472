import HttpRequest from './HttpRequest'

class BankService extends HttpRequest {
  constructor() {
    super(window.__env.api.apiBank + 'bankstatement-service')
  }

  async GetBankStatementConfig() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankstatementconfig')
    const { data } = res
    return data
  }

  async getBankStatementConfigBySecretVault(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`bankstatementconfig/${id}/secretVault`)
    const { data } = res
    return data
  }

  async putSyncBankProvider() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(500)
    const res = await this.put('bankdata/syncbankprovider')
    const { data } = res
    return data
  }

  async UpdateBankStatementConfig(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(1000)
    const res = await this.put(`bankstatementconfig/${id}`, payload)
    const { data } = res
    return data
  }

  async updateSecretVault(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(1000)
    const res = await this.patch(`bankstatementconfig/${id}/secretVault`, payload)
    const { data } = res
    return data
  }

  async activeBankStatementConfig(id, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`bankstatementconfig/${id}/active`, payload)
    const { data } = res
    return data
  }

  async DeleteBankStatementConfig(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.delete(`bankstatementconfig/${id}`)
    const { data } = res
    return data
  }

  async AddBankStatementConfig(payload) {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('bankstatementconfig', payload)
    const { data } = res
    return data
  }

  async GetSecretVault() {
    await this.delay(500)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('secretvault')
    const { data } = res
    return data
  }

  async GetSecretVaultByName(payload) {
    await this.delay(500)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('secretvault/getByName', payload)
    const { data } = res
    return data
  }

  async GetSecretVaultById(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`secretvault/${id}`)
    const { data } = res
    return data
  }

  async GetSecretVaultByPagination(payload) {
    await this.delay(500)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('secretvault/GetByPagination', payload)
    const { data } = res
    return data
  }

  async UpdateSecretVaultActive(id, payload) {
    await this.delay(500)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`secretvault/${id}/Active`, payload)
    const { data } = res
    return data
  }

  async UpdateSecretVault(id, payload) {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.put(`secretvault/${id}/vault`, payload)
    const { data } = res
    return data
  }

  async UpdateSecretVaultProfile(id, payload) {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.put(`secretvault/${id}/profile`, payload)
    const { data } = res
    return data
  }

  async AddSecretVault(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('secretvault', payload)
    const { data } = res
    return data
  }

  async DeleteSecretVault(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.delete(`secretvault/${id}`)
    const { data } = res
    return data
  }

  async GetBankStatementType() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankdata/bankstatementtype')
    const { data } = res
    return data
  }

  async GetWorkingDate() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankdata/workingdate')
    const { data } = res
    return data
  }

  async GetBankAccountService(payload) {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankdata/bankaccountservice', payload)
    const { data } = res
    return data
  }

  async GetBankNameGroup(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`bankname/${id}/group`)
    const { data } = res
    return data
  }

  async GetBankName() {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankname')
    const { data } = res
    return data
  }

  async GetSummary(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankstatement/0/getsummary', payload)
    const { data } = res
    return data
  }

  async GetLastTwoDays(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankstatement/0/getlasttwodays', payload)
    const { data } = res
    return data
  }

  async GetDateStartToDateEnd(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('bankstatement/0/getdatestarttodateend/', payload)
    const { data } = res
    return data
  }

  async UpdateBankName(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.put(`bankname/${payload.id}`, payload)
    const { data } = res
    return data
  }

  async AddBankName(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('bankname', payload)
    const { data } = res
    return data
  }

  async DeleteBankName(id) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.delete(`bankname/${id}`)
    const { data } = res
    return data
  }

  async delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    })
  }
}

export default BankService
