<template>
  <div>
     <div class="grid grid-cols-12 gap-6">
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <button type="button" class="btn btn-primary mt-2 shadow-md preview self-center text-xs" @click="handleForm">
         Add New Bank
        </button>
        <table class="table table-report -mt-2">
        <thead>
          <tr>
            <th class="whitespace-nowrap">Bank Code</th>
            <th class="whitespace-nowrap">Bank Name En</th>
            <th class="whitespace-nowrap">Bank Name Th</th>
            <th class="text-center whitespace-nowrap"></th>
          </tr>
        </thead>
        <tbody v-if="listAccounts.length == 0">
          <tr
            v-for="item in 3"
            :key="item"
            class="intro-x"
          >
            <td>
              <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
              </div>
            </td>
            <td>
              <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
              </div>
            </td>
            <td>
              <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <Skeletor />
              </div>
            </td>
            <td class="w-40">
              <div
                class="flex items-center justify-center text-theme-9"
              >
                <Skeletor />
              </div>
            </td>
          </tr>
        </tbody>
         <tbody v-else>
          <tr
            v-for="item in listAccounts"
            :key="item.id"
            class="intro-x"
          >
            <td>
                {{ item.bankCode }}
            </td>
            <td>
                {{ item.bankNameEn }}
            </td>
            <td>
                {{ item.bankNameTh }}
            </td>
            <td>
                {{ item.swiftcode }}
            </td>
            <td>
              <div class="dropdown ml-auto">
                <a
                  class="dropdown-toggle w-5 h-5 block -mr-2"
                  href="javascript:;"
                  aria-expanded="false"
                >
                  <MoreVerticalIcon
                    class="w-5 h-5 text-gray-600 dark:text-gray-300"
                  />
                </a>
                <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                    <a
                      href="#"
                      @click="editItem(item)"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <div>
                        <EditIcon class="w-4 h-4 mr-2" />
                        Edit
                      </div>
                    </a>
                    <a
                      href="#"
                      @click="deleteBankName(item)"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                    >
                      <TrashIcon class="w-4 h-4 mr-2" />
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- BEGIN: Modal Content -->
  <div id="delete-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-body p-0">
                  <div class="p-5 text-center">
                      <div class="text-white mt-2">
                          There should not be admin Username or Password <br />
                      </div>
                  </div>
                  <div class="px-5 pb-8 text-center">
                      <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">
                          Cancel
                      </button>
                      <button type="button" class="btn btn-primary w-24" @click="confirmNoAdmin">
                          Ok
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- END: Modal Content -->
  <!-- BEGIN: Modal Content -->
  <div id="form-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
          <div class="modal-content">
              <!-- BEGIN: Modal Header -->
              <div class="modal-header">
                  <h2 class="font-medium text-base mr-auto">
                     {{ bankModel.id > 0 ? 'Edit' : 'Add New' }}
                  </h2>
              </div>
              <!-- END: Modal Header -->
              <!-- BEGIN: Modal Body -->
              <div class="modal-body">
                 <div>
                  <label for="modal-form-1" class="form-label"
                    >Bank Code</label
                  >
                  <input
                    id="modal-form-1"
                    v-model="bankModel.bankCode"
                    type="text"
                    class="form-control"
                    placeholder="Code"
                  />
                </div>
              </div>
              <div class="modal-body">
                 <div>
                  <label for="modal-form-1" class="form-label"
                    >Eng Name</label
                  >
                  <input
                    id="modal-form-1"
                    v-model="bankModel.bankNameEn"
                    type="text"
                    class="form-control"
                    placeholder="Eng Name"
                  />
              </div>
              </div>
               <div class="modal-body">
                 <div>
                  <label for="modal-form-1" class="form-label"
                    >Thai Name</label
                  >
                  <input
                    id="modal-form-1"
                    v-model="bankModel.bankNameTh"
                    type="text"
                    class="form-control"
                    placeholder="Thai Name"
                  />
              </div>
              </div>
              <!-- END: Modal Body -->
              <!-- BEGIN: Modal Footer -->
              <div class="modal-footer">
                  <button type="button" class="btn btn-primary w-20 mr-1 text-right" @click="saveBankName">
                      Save
                  </button>
              </div>
              <!-- END: Modal Footer -->
          </div>
      </div>
  </div>
  <!-- END: Modal Content -->
  <div v-if="loading" class="text-center">
       <img class="h-14 mr-auto ml-auto mb-4" src="@/assets/images/Loading.gif" />
  </div>
</div>
</template>
<script>
import moment from 'moment'
import { bankService, cloudService } from '@/services'
import syncImg from '@/assets/images/refresh.png'

export default {
  data() {
    return {
      listAccounts: [],
      state: { invalidToast: false, notifyMessage: '' },
      currentRemoveId: 0,
      syncImg,
      totalScholar: 0,
      lastSync: moment(),
      listScholarHistory: [],
      totalAvgSLP: 0.0,
      showTotalAvgSlp: false,
      currentPage: 1,
      pageSize: 3,
      lastPage: 0,
      loading: false,
      axieModalItem: false,
      currentIndex: 0,
      bankModel: {
        bankCode: '',
        bankNameEn: '',
        bankNameTh: '',
        canAutomate: false,
        id: 0
      }
    }
  },
  methods: {
    handleForm() {
      this.bankModel = {
        bankCode: '',
        bankNameEn: '',
        bankNameTh: '',
        canAutomate: false,
        id: 0
      }
      cash('#form-modal').modal('show')
    },
    resetCloudForm() {
      this.bankModel = {
        bankCode: '',
        bankNameEn: '',
        bankNameTh: '',
        canAutomate: false,
        id: 0
      }
    },
    handleShowCopySuccess() {
      this.showToast('Copied Success!', 'success')
    },
    handleShowCopySuccessV2(text) {
      const shortRonin = `${text.substring(0, 10)}...${text.substring(text.length - 4, text.length)}`
      this.showToast(`Copied address ${shortRonin} Success!`, 'success')
    },
    handleShowErrorSuccess() {
      this.showToast('Something wrong please try again.', 'danger')
    },
    confirmNoAdmin() {
      if (this.currentIndex < this.listAccounts.length) {
        cash('#form-modal').modal('show')
      }
      cash('#delete-modal-preview').modal('hide')
    },
    async addValutToCloud(keyname, keyvalue) {
      const payload = {
        keyname: keyname,
        keyvalue: keyvalue
      }
      await cloudService.SetByKey(payload)
      this.resetCloudForm()
    },
    async GetBankName() {
      const res = await bankService.GetBankName()
      this.listAccounts = res
    },
    async deleteBankName(item) {
      await bankService.DeleteBankName(item.id)
      await this.GetBankName()
    },
    async editItem(item) {
      this.bankModel = item
      cash('#form-modal').modal('show')
    },
    async saveBankName() {
      const iEdit = this.bankModel.id > 0
      if (iEdit === true) {
        await bankService.UpdateBankName(this.bankModel)
      } else {
        await bankService.AddBankName(this.bankModel)
      }
      cash('#form-modal').modal('hide')
      await this.GetBankName()
    }
  },
  async mounted() {
    console.log('Hello')
    await this.GetBankName()
  }
}
</script>
