// Main
export const GET_DARK_MODE = 'GET_DARK_MODE'
export const GET_TOKEN = 'GET_TOKEN'
export const GET_PACKAGE = 'GET_PACKAGE'
export const GET_HAS_PACKAGE = 'GET_HAS_PACKAGE'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PAGE = 'GET_PAGE'
export const GET_IS_SYNC_PROVIDER = 'GET_IS_SYNC_PROVIDER'
export const GET_PROVIDER = 'GET_PROVIDER'
export const GET_IMAGE = 'GET_IMAGE'
export const GET_IS_LOGGED_IN = 'GET_IS_LOGGED_IN'
export const GET_HAS_PROFILE = 'GET_HAS_PROFILE'

// Local
export const GET_REMEMBER_ME = 'GET_REMEMBER_ME'
export const GET_HAS_REMEMBER_ME = 'GET_HAS_REMEMBER_ME'

// Side Menu
export const GET_MENU = 'GET_MENU'
