<template>
  <div class="container" v-if="isVerified">
    <div class="flex flex-col items-center justify-center h-screen text-center intro-x lg:flex-row lg:text-left">
      <div class="flex flex-col items-center justify-center h-screen text-center lg:flex-row lg:text-left">
        <div class="-intro-x lg:mr-20">
          <img class="w-72" :src="imgResetPasswordSuccess" />
        </div>
        <div v-if="!isResetCompleted" class="intro-x">
          <div class="mt-10 text-white lg:mt-0">
            <h2 class="text-2xl font-bold intro-x xl:text-3xl xl:text-left">
              กำหนดรหัสผ่านใหม่
            </h2>
            <div class="mt-3 text-lg intro-x">
              กรุณาระบุรหัสผ่านใหม่ที่ต้องการ
            </div>
          </div>
          <div class="mt-4 dropdown" id="policy-password-dropdown" data-placement="bottom">
            <div class="border-gray-300 input-group login__input focus:intro-x">
              <div id="input-group-password " :class="{ disabled: isResetting }" class="input-group-text ">
                <LockIcon class="block w-3 mx-auto" />
              </div>
              <input :disabled="isResetting" :type="isShowPassword ? 'text' : 'password'" placeholder="รหัสผ่านใหม่"
                v-model="password" class="border-gray-300 form-control dropdown-toggle" aria-label="Password"
                autocomplete="off" @focusout="onNewPasswordFocusOut" @focusin="onNewPasswordFocusIn"
                aria-describedby="input-group-password" />
              <div id="input-group-password " class="cursor-pointer input-group-text" :class="{ disabled: isResetting }"
                @click="isShowPassword = !isShowPassword">
                <EyeOffIcon v-if="isShowPassword" class="block w-3 mx-auto " />
                <EyeIcon v-else class="block w-3 mx-auto " />
              </div>
            </div>
            <div class="dropdown-menu">
              <div v-if="!$helpers.isNull(policyPassword.rule)"
                style="display: flex;align-items: center;place-content: center; ">
                <div class="mt-5 mb-5 container-password">
                  <div>
                    {{ safePasswordMessage }}
                  </div>
                  <div class="mt-2 mb-2 progress-bar_wrap ">
                    <div class="progress-bar_item" :class="updateProgressBar(1)"></div>
                    <div class="progress-bar_item" :class="updateProgressBar(2)"></div>
                    <div class="progress-bar_item" :class="updateProgressBar(3)"></div>
                  </div>
                  <div>
                    รหัสผ่านจะต้องประกอบไปด้วย:
                  </div>
                  <ul class="mt-2 -ml-2 ui-password">
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMinCharacters }"
                      v-if="policyPassword.rule.minLength > 0">
                      ต้องมีความยาวรหัสผ่านอย่างน้อย <strong>{{ policyPassword.rule.minLength }}</strong> ตัวขึ้นไป
                    </li>
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMaxCharacters }"
                      v-if="policyPassword.rule.maxLength > 0">
                      ต้องมีความยาวรหัสผ่านไม่เกิน <strong>{{ policyPassword.rule.maxLength }}</strong> ตัว
                    </li>
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainNumber }"
                      v-if="policyPassword.rule.minNumbers > 0">
                      ต้องมีตัวเลข (0-9) <strong>{{ policyPassword.rule.minNumbers }}</strong> ตัวขึ้นไป
                    </li>
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainUppercase }"
                      v-if="policyPassword.rule.minUpperCase > 0">
                      ต้องมีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) <strong>{{ policyPassword.rule.minUpperCase }}</strong>
                      ตัวขึ้นไป
                    </li>
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainLowercase }"
                      v-if="policyPassword.rule.minLowerCase > 0">
                      ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) <strong>{{ policyPassword.rule.minLowerCase }}</strong>
                      ตัวขึ้นไป
                    </li>
                    <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainSpecialCharacter }"
                      v-if="policyPassword.rule.minIncludeSpecialChar > 0">
                      ต้องมีอักขระพิเศษ (!@#$%^&+=) <strong>{{ policyPassword.rule.minIncludeSpecialChar }}</strong>
                      ตัวขึ้นไป
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <template v-if="v$.password.$error">
            <div v-for="(error, index) in v$.password.$errors" :key="index" class="mt-2 text-theme-6">
              {{ error.$message }}
            </div>
          </template>
          <template v-if="!$helpers.isNull(errorPasswordMessage)">
            <div class="mt-2 text-theme-6">
              {{ errorPasswordMessage }}
            </div>
          </template>
          <div class="mt-4 border-gray-300 input-group login__input focus:intro-x ">
            <div id="input-group-confirm-password " class="input-group-text " :class="{ disabled: isResetting }">
              <LockIcon class="block w-3 mx-auto" />
            </div>
            <input :disabled="isResetting" :type="isShowConfirmPassword ? 'text' : 'password'"
              placeholder="ยืนยันรหัสผ่าน" v-model="confirmPassword" class="border-gray-300 form-control"
              aria-label="password" aria-describedby="input-group-password" />
            <div id="input-group-password " class="cursor-pointer input-group-text" :class="{ disabled: isResetting }"
              @click="isShowConfirmPassword = !isShowConfirmPassword">
              <EyeOffIcon v-if="isShowConfirmPassword" class="block w-3 mx-auto " />
              <EyeIcon v-else class="block w-3 mx-auto " />
            </div>
          </div>
          <template v-if="v$.confirmPassword.$error">
            <div v-for="(error, index) in v$.confirmPassword.$errors" :key="index" class="mt-2 text-theme-6">
              {{ error.$message }}
            </div>
          </template>
          <div class="mt-4 ml-1 border-gray-300 input-group login__input focus:intro-x ">
            รหัสอ้างอิง : <strong class="ml-2"> {{ modelValue?.code }} </strong>
          </div>
          <div class="mt-4 border-gray-300 input-group login__input focus:intro-x ">
            <input :disabled="isResetting" type="text" placeholder="Code Verification"
              class="border-gray-300 form-control " v-model="codeVerification" />
          </div>
          <template v-if="v$.codeVerification.$error">
            <div v-for="(error, index) in v$.codeVerification.$errors" :key="index" class="mt-2 text-theme-6">
              {{ error.$message }}
            </div>
          </template>
          <div v-if="!$helpers.isNull(messageError)" class="mt-6 ml-1 input-group text-theme-6 ">
            <img :src="imgError" class="h-5 mr-2" />
            {{ messageError }}
          </div>
          <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
            <button @click="onResetPasswordClicked" :disabled="isResetting"
              class="w-full px-4 py-3 align-top btn btn-primary xl:w-36 xl:mr-3">
              <div class="flex flex-col items-center justify-end sm:flex-row">
                <div class="text-center ">
                  {{ isResetting ? 'Resetting' : 'Reset Password' }}
                </div>
                <LoadingIcon v-if="isResetting" icon="oval" class="w-5 h-5 ml-3 text-center" />
              </div>
            </button>
            <button type="button" class="w-full px-4 py-3 mt-3 align-top btn btn-outline-secondary xl:w-32 xl:mt-0">
              <div class="flex flex-col items-center justify-end sm:flex-row">
                <div class="text-center">
                  กลับสู่หน้าหลัก
                </div>
              </div>
            </button>
          </div>
        </div>
        <div v-else class="mt-10 text-white lg:mt-0">
          <div class="font-medium intro-x text-8xl text-theme-11 ">Successfully</div>
          <div class="mt-5 text-xl font-medium intro-x lg:text-3xl">
            ระบบได้ทำการเปลี่ยน Reset Password เรียบร้อยแล้ว
          </div>
          <div class="mt-3 text-lg intro-x">
            ระบบจะพาคุณกลับหน้าหลักอัตโนมัติ หากระบบยังไม่ตอบสนองสามารถกดปุ่มด้านล่างเพื่อไปหน้าหลัก
          </div>
          <div class="flex">
            <button @click="onBackToHomeClicked"
              class="px-4 py-3 mt-10 mr-5 text-white border-white intro-x btn dark:border-dark-5 dark:text-gray-300">
              Back to Home
              <span class="ml-2" v-if="timeoutRunner > 0">
                ({{ timeoutRunner }})
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { authService } from '@/services'
import imgResetPasswordSuccess from '@/assets/images/Email-Reset-Password512.png'
import imgError from '@/assets/images/Error32.png'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, helpers } from '@vuelidate/validators'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: [
    'refresh',
    'error',
    'loaded',
    'already'
  ],
  setup: () => ({
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true
    })
  }),
  data: () => ({
    imgError,
    imgResetPasswordSuccess,
    isResetting: false,
    isShowPassword: false,
    isShowConfirmPassword: false,
    isVerified: false,
    isResetCompleted: false,
    router: null,
    messageError: null,
    timeout: 3000,
    timeoutRunner: 3,
    password: '',
    confirmPassword: '',
    codeVerification: '',
    errorPasswordMessage: null,
    safePasswordMessage: null,
    policyPassword: {
      rule: null,
      validations: {
        isContainMinCharacters: false,
        isContainMaxCharacters: false,
        isContainNumber: false,
        isContainUppercase: false,
        isContainLowercase: false,
        isContainSpecialCharacter: false
      }
    }
  }),
  validations() {
    return {
      password: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
      },
      confirmPassword: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
        sameAsPassword: helpers.withMessage('ยืนยันรหัสผ่านไม่ตรงกัน', sameAs(this.password))
      },
      codeVerification: {
        required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
      }
    }
  },
  watch: {
    password(newVal, oldVal) {
      this.validationRulePolicyPassword()
    }
  },
  computed: {
    isValidPolicyPassword() {
      return this.policyPassword.validations.isContainMinCharacters &&
        this.policyPassword.validations.isContainMaxCharacters &&
        this.policyPassword.validations.isContainNumber &&
        this.policyPassword.validations.isContainUppercase &&
        this.policyPassword.validations.isContainLowercase &&
        this.policyPassword.validations.isContainSpecialCharacter
    }
  },
  methods: {
    updateProgressBar(val) {
      const length = this.password?.length ?? 0
      if (length === 0) {
        this.safePasswordMessage = 'ความปลอดภัยรหัสผ่าน'
        return null
      } else if (length > 0 && length <= 4) {
        this.safePasswordMessage = 'รหัสผ่านไม่ปลอดภัย'
        if (val === 1) {
          return 'active bg-red-500'
        }
      } else if (length > 4 && length <= 8) {
        this.safePasswordMessage = 'รหัสผ่านดี'
        if (val === 1 || val === 2) {
          return 'active bg-yellow-500'
        }
      } else {
        this.safePasswordMessage = 'รหัสผ่านดีมาก'
        return 'active bg-green-500'
      }
    },
    validationRulePolicyPassword() {
      this.errorPasswordMessage = null
      if (this.$helpers.isNull(this.password)) {
        this.confirmPassword = this.password
        this.policyPassword.validations.isContainMinCharacters = false
        this.policyPassword.validations.isContainMaxCharacters = false
        this.policyPassword.validations.isContainNumber = false
        this.policyPassword.validations.isContainUppercase = false
        this.policyPassword.validations.isContainLowercase = false
        this.policyPassword.validations.isContainSpecialCharacter = false
        return
      }
      // Check Contain Min Characters
      if (this.policyPassword.rule.minLength > 0) {
        if (this.password.length >= this.policyPassword.rule.minLength) {
          this.policyPassword.validations.isContainMinCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวอย่างน้อย ${this.policyPassword.rule.minLength} ตัวขึ้นไป`
          this.policyPassword.validations.isContainMinCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMinCharacters = true
      }
      // Check Contain Max Characters
      if (this.policyPassword.rule.maxLength > 0) {
        if (this.password.length <= this.policyPassword.rule.maxLength) {
          this.policyPassword.validations.isContainMaxCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวไม่เกิน ${this.policyPassword.rule.maxLength} ตัว`
          this.policyPassword.validations.isContainMaxCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMaxCharacters = true
      }
      // Check Contain Number
      if (this.policyPassword.rule.minNumbers > 0) {
        const findNumberMatch = this.password.match(/\d+/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minNumbers) {
          this.policyPassword.validations.isContainNumber = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวเลข (0-9) ${this.policyPassword.rule.minNumbers} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainNumber = false
        }
      } else {
        this.policyPassword.validations.isContainNumber = true
      }
      // Check Contain Uppercase
      if (this.policyPassword.rule.minUpperCase > 0) {
        const findNumberMatch = this.password.match(/[A-Z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minUpperCase) {
          this.policyPassword.validations.isContainUppercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (A-Z) ${this.policyPassword.rule.minUpperCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainUppercase = false
        }
      } else {
        this.policyPassword.validations.isContainUppercase = true
      }
      // Check Contain Lowercase
      if (this.policyPassword.rule.minLowerCase > 0) {
        const findNumberMatch = this.password.match(/[a-z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minLowerCase) {
          this.policyPassword.validations.isContainLowercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) ${this.policyPassword.rule.minLowerCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainLowercase = false
        }
      } else {
        this.policyPassword.validations.isContainLowercase = true
      }
      // Check Contain Special Character
      if (this.policyPassword.rule.minIncludeSpecialChar > 0) {
        const findSpecialMatch = this.password.match(/[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g)?.join('') ?? ''
        if (findSpecialMatch.length >= this.policyPassword.rule.minIncludeSpecialChar) {
          this.policyPassword.validations.isContainSpecialCharacter = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีอักขระพิเศษ (!@#$%^&+=) ${this.policyPassword.rule.minIncludeSpecialChar} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainSpecialCharacter = false
        }
      } else {
        this.policyPassword.validations.isContainSpecialCharacter = true
      }
    },
    onNewPasswordFocusIn() {
      cash('#policy-password-dropdown').dropdown('show')
    },
    onNewPasswordFocusOut() {
      cash('#policy-password-dropdown').dropdown('hide')
    },
    onBackToHomeClicked() {
      this.router.push('/')
    },
    async onRefreshClicked() {
      this.$emit('refresh')
      await this.handleLoaded()
    },
    async onResetPasswordClicked() {
      this.messageError = null
      this.v$.$touch()
      if (this.v$.$invalid || !this.isValidPolicyPassword) {
        this.$helpers.showToastify('Validate-Failed', 'Please check your data', true)
      } else {
        try {
          this.isResetting = true
          const payload = {
            refKey: this.modelValue?.key,
            refCode: this.modelValue?.code,
            codeVerification: this.codeVerification,
            password: this.password,
            confirmPassword: this.confirmPassword
          }
          const res = await authService.resetPassword(payload)
          if (!this.$helpers.isNull(res?.statusCode)) {
            this.$emit('error', res.message)
          } else {
            if (res.isLinkNotFound) {
              this.$emit('error', res.message)
            } else if (res.isLinkActive || res.isExpiryDate) {
              this.$emit('already', res.message)
            } else {
              if (res.isError) {
                this.messageError = res.message
              } else {
                this.isResetCompleted = true
                const myInterval = setInterval(() => {
                  if (this.timeoutRunner == 0) {
                    clearInterval(myInterval)
                    this.onBackToHomeClicked()
                  }
                  this.timeoutRunner = this.timeoutRunner - 1
                }, this.timeout)
              }
            }
          }
        } catch (error) {
          this.$emit('error', error)
        } finally {
          this.isResetting = false
        }
      }
    },
    async verify() {
      try {
        const res = await authService.verifyResetPassword({ refKey: this.modelValue?.key, refCode: this.modelValue?.code })
        if (!this.$helpers.isNull(res?.statusCode)) {
          this.$emit('error', res.message)
        } else {
          if (res.isLinkNotFound) {
            this.$emit('error', res.message)
          } else if (res.isLinkActive || res.isExpiryDate) {
            this.$emit('already', res.message)
          } else {
            this.policyPassword.rule = await authService.getPolicyPassword()
            this.isVerified = true
          }
        }
      } catch (e) {
        this.$emit('error', e.message)
      }
    },
    async beforeVerify() {
      this.isVerified = false
      this.timeoutRunner = this.timeout / 1000
      await this.delay(2000)
      await this.verify()
    },
    async delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds)
      })
    }
  },
  created() {
    this.router = useRouter()
  },
  async mounted() {
    await this.beforeVerify()
    this.$emit('loaded')
  }
})
</script>
