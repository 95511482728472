<template>
  <div class="modal modal-slide-over" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg" v-if="isShow">
      <div class="modal-content">
        <a data-dismiss="modal" ref="elClose" href="javascript:;" :disabled="isUpdating"
          @click="onCloseClicked">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <!-- BEGIN: Slide Over Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            Edit Credential - {{ type }}
          </h2>
          <div class="sm:flex">
            <div class="mr-1">Status : </div>
            <div v-if="Boolean(refData.isActive) === true" class="flex items-center justify-center text-color-highlight">
              Active
            </div>
            <div v-else-if="Boolean(refData.isActive) === false"
              class="flex items-center justify-center text-color-error">
              Inactive
            </div>
          </div>
        </div>
        <!-- END: Slide Over Header -->
        <!-- BEGIN: Slide Over Body -->
        <div class="modal-body ">
          <div class="-mt-1" v-if="type === typeProfileName">
            <div class="flex text-center">
              <label  >Bank : </label>
              <div class="ml-2" >
                <i :class="refData.iconClass"/>
                <label class="ml-2 ">
                  {{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }}</label>
              </div>
            </div>
            <div class="mt-5">
              <label for="modal-form-1" class="form-label">Credential Name</label>
              <label class="text-theme-6 ml-2">*</label>
              <input :disabled="isUpdating" id="modal-form-1" v-model="newData.name" type="text" class="form-control"
                placeholder="Credential Name" />
              <div v-if="$helpers.isNull(newData.name)" class=" text-theme-6 mt-2">
                This field is required
              </div>
              <div Class="text-theme-6 mt-2" v-if="!$helpers.isNull(errorMessageProfileName)" >
                <span>{{ errorMessageProfileName }}</span>
              </div >
            </div>
            <div class="mt-3">
              <label for="modal-form-1" class="form-label">Description</label>
              <input :disabled="isUpdating" id="modal-form-1" v-model="newData.desc" type="text"
                class="form-control" placeholder="Description" />
            </div>
          </div>
          <div class="-mt-1" v-else>
            <div class="flex text-center">
              <label  >Bank : </label>
              <div class="ml-2" >
                <i :class="refData.iconClass"/>
                <label class="ml-2 ">
                  {{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }}</label>
              </div>
            </div>
            <div class="mt-3">
              <div class=" flex text-center" v-if="refData != null" >
                <div class="text-theme-6" v-if="refData.isExpiryDate" >
                  ** Credential has expired.
                </div>
                <div class="text-yellow-500" v-if="refData.isAboutExpiry" >
                  ** The credential is about to expire.
                </div>
                <div class="text-yellow-500" v-if="!refData.isVault" >
                  ** The credential has crashed. Please update new information.
                </div>
              </div>
            </div>
            <div class="mt-3">
              <label for="modal-form-1" class="form-label">Username</label>
              <label class="text-theme-6 ml-2">*</label>
              <div class="input-group border-gray-300 ">
                <div id="input-group-username" class="input-group-text">
                  <UserIcon class="w-3 block mx-auto" />
                </div>
                <input :disabled="isUpdating"  type="text" v-model.trim="newData.username" placeholder="Username"
                  class=" border-gray-300  form-control " aria-label="Username" aria-describedby="input-group-username" />
              </div>
              <div v-if="$helpers.isNull(newData.username)" class=" text-theme-6 mt-2">
                This field is required
              </div>
            </div>
            <div class="mt-3">
              <div>
                <label for="modal-form-2" class="form-label">Password</label>
                <label class="text-theme-6 ml-2">*</label>
                <div class="input-group  border-gray-300 ">
                  <div id="input-group-password " class="input-group-text ">
                    <LockIcon class="w-3 block mx-auto" />
                  </div>
                  <input :disabled="isUpdating" :type="isShowPassword ? 'text' : 'password'" placeholder="Password"
                    v-model.trim="newData.password" class=" form-control border-gray-300 " aria-label="Password"
                    aria-describedby="input-group-password" />
                  <div id="input-group-password " :aria-disabled="isUpdating" class="input-group-text cursor-pointer"
                    @click="isShowPassword = !isShowPassword">
                    <EyeOffIcon v-if="isShowPassword" class="w-3 block mx-auto " />
                    <EyeIcon v-else class="w-3 block mx-auto " />
                  </div>
                </div>
              </div>
              <div v-if="$helpers.isNull(newData.password)" class=" text-theme-6 mt-2">
                This field is required
              </div>
            </div>
            <div class="mt-3" v-if="isShowCompany()">
              <label for="modal-form-1" class="form-label">Company ID / Client Code</label>
              <label class="text-theme-6 ml-2">*</label>
              <input :disabled="isUpdating" id="modal-form-1" v-model="newData.company" type="text"
                class="form-control border-gray-300 block " placeholder="Company ID / Client Code" />
                <div v-if="$helpers.isNull(newData.company)" class=" text-theme-6 mt-2">
                This field is required
              </div>
            </div>
            <div class="mt-3" >
              <label for="modal-form-1" class="form-label">Password Date Reminder</label>
              <litepie-datepicker :disabled="isUpdating" :formatter="dateFormatter" :auto-apply="false" readonly as-single
                v-model:modelValue="selectDates" />
              <div class="text-theme-6 mt-2" v-if="isExpiryDateFormat">
                Unable to select a date backwards from the current date.
              </div>
            </div>
            <div>
              <div class="alert show mt-7" :class="isDarkMode ? 'alert-outline-warning' : 'alert-warning-soft'"
                role="alert">
                <div class="flex items-center">
                  <AlertCircleIcon class="w-32 mr-2 " style="align-self: start;" />
                   <div class=" inline-block">
                      คุณได้ทำการตรวจสอบข้อมูล Login โดยตรวจสอบจากเว็บธนาคารที่คุณเลือก
                      <span class="p-1  text-theme-27">'{{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }}'</span>
                      กรุณาพิมพ์คำว่า
                      <span class="p-1  text-theme-27">'Confirm'</span>
                      ลงใน ช่องด้านล่าง.
                    </div>
                </div>
                <div class="items-center text-center mt-5">
                  <input type="text" class="form-control w-64 text-center" :disabled="isUpdating"
                    :class="$helpers.isNull(txtConfirm) ? ' border-red-700 dark:border-red-500' : ''"
                    v-model="txtConfirm" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Slide Over Body -->
        <!-- BEGIN: Slide Over Footer -->
        <div class="modal-footer text-right w-full absolute bottom-0">
          <div Class="col-span-12 flex text-left text-theme-6 mb-3" v-if="!$helpers.isNull(errorMessage)" >
            <img :src="imgError" class=" w-6 h-6 mr-2" />
            <span>{{ errorMessage }}</span>
          </div >
          <button :disabled="isUpdating" @click="onCloseClicked" type="button" data-dismiss="modal" class="btn btn-primary w-20 mr-5 text-left">
            <div class="text-center">Cancel</div>
          </button>
          <button :disabled="!isLoaded || isUpdating || isValidateRefData() || isValidateNewData()" type="button" class="btn btn-primary h-10 mr-1 text-right"
            @click="onUpdateClicked">
            <div class="flex flex-col sm:flex-row justify-end items-center">
              <div class="text-center">
                {{ isUpdating ? 'Updating' : 'Update' }}
              </div>
              <LoadingIcon v-if="isUpdating" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
          </button>
        </div>
        <!-- END: Slide Over Footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { bankService } from '@/services'

import { useStore } from '@/store'
import imgExpired from '@/assets/images/Expired64.png'
import imgDeadlineExpired from '@/assets/images/DeadlineExpired64.png'
import imgSecurityWarning from '@/assets/images/SecurityWarning64.png'
import imgError from '@/assets/images/Error32.png'

export default defineComponent({
  emits: ['update'],
  data() {
    return {
      imgError,
      txtConfirm: null,
      imgDeadlineExpired,
      imgExpired,
      imgSecurityWarning,
      type: null,
      refData: null,
      isShow: false,
      isShowPassword: false,
      isUpdating: false,
      errorMessage: null,
      errorMessageProfileName: null,
      isLoaded: false,
      isExpiryDateFormat: false,
      newData: {},
      selectDates: [],
      typeProfileName: 'Profile',
      dateFormatter: {
        date: 'YYYY-MM-DD',
        month: 'MMM'
      },
      confirmName: 'Confirm'
    }
  },
  computed: {
    isDarkMode() {
      const store = useStore()
      return store.getters['main/darkMode']
    }
  },
  watch: {
    selectDates: {
      deep: true,
      handler(val, oldVal) {
        if (val?.length > 0) {
          const currentDateTime = new Date()
          const valDate = new Date(val[0])
          const compareValDate = new Date(valDate.getFullYear(), valDate.getMonth(), valDate.getDate())
          const compareDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate())
          if (compareValDate <= compareDate) {
            this.isExpiryDateFormat = true
          } else {
            this.isExpiryDateFormat = false
          }
        } else {
          this.isExpiryDateFormat = false
        }
      }
    }
  },
  methods: {
    isValidateRefData() {
      if (this.type === this.typeProfileName) {
        return (this.refData.name === this.newData.name) &&
               (this.refData.desc === this.newData.desc)
      } else {
        if (this.isShowCompany()) {
          return (this.refData.company === this.newData.company) &&
                 (this.refData.username === this.newData.username) &&
                 (this.refData.password === this.newData.password)
        } else {
          return (this.refData.username === this.newData.username) &&
                 (this.refData.password === this.newData.password)
        }
      }
    },
    isValidateNewData() {
      if (this.type === this.typeProfileName) {
        return this.$helpers.isNull(this.newData.name)
      } else {
        if (this.isShowCompany()) {
          return this.$helpers.isNull(this.newData.company) ||
                 this.$helpers.isNull(this.newData.username) ||
                 this.$helpers.isNull(this.newData.password) ||
                 this.isExpiryDateFormat
        } else {
          return this.$helpers.isNull(this.newData.username) ||
                 this.$helpers.isNull(this.newData.password) ||
                 this.isExpiryDateFormat
        }
      }
    },
    isShowCompany() {
      if (this.refData?.fieldamout > 2) {
        return true
      } else {
        this.newData.company = null
        return false
      }
    },
    open(data, type) {
      this.clearData()
      this.refData = { ...data }
      this.refData.desc = this.$helpers.isNull(data.desc) ? '' : data.desc
      this.type = type
      if (type === this.typeProfileName) {
        this.newData.name = data.name
        this.newData.desc = this.$helpers.isNull(data.desc) ? '' : data.desc
      } else {
        this.newData.username = data.username
        this.newData.password = null
        this.newData.company = data.companyid
        this.selectDates = [this.$filters.formatDate(data.expiryDate)]
      }
      this.isShow = true
      this.isLoaded = true
    },
    async onUpdateClicked() {
      this.isUpdating = true
      this.errorMessage = null
      try {
        if (this.type === this.typeProfileName) {
          this.errorMessageProfileName = null
          const payload = {
            name: this.newData.name,
            desc: this.newData.desc === '' ? null : this.newData.desc
          }
          const res = await bankService.UpdateSecretVaultProfile(this.refData.id, payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            this.$helpers.showToastify('Successfully', 'Update profile credential.')
            this.$refs.elClose.click()
            this.$emit('update', this)
          } else {
            if (res.statusCode === 400) {
              this.errorMessageProfileName = res.message
            } else {
              this.errorMessage = res.message
            }
            this.isUpdating = false
          }
        } else {
          if (this.txtConfirm !== this.confirmName) {
            this.errorMessage = 'Invalid "Confirm" text. Please enter again correctly.'
            this.isUpdating = false
            return
          }
          let tempExpiryDate = null
          if (this.selectDates.length > 0) {
            if (this.selectDates[0] !== '-') {
              tempExpiryDate = this.$filters.formatDateTimeToServer(this.selectDates[0])
            }
          }
          const payload = {
            username: this.newData.username,
            password: this.newData.password,
            expiryDate: tempExpiryDate,
            company: this.newData.company
          }
          const res = await bankService.UpdateSecretVault(this.refData.id, payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            this.$helpers.showToastify('Successfully', 'Update profile credential.')
            this.$refs.elClose.click()
            this.$emit('update', this)
          } else {
            this.errorMessage = res.message
            this.isUpdating = false
          }
        }
      } catch (e) {
        this.isUpdating = false
        this.errorMessage = e.message
      }
    },
    async onCloseClicked() {
      await this.delay(500)
      this.clearData()
    },
    clearData() {
      this.isShow = false
      this.refData = null
      this.isUpdating = false
      this.isLoaded = false
      this.isExpiryDateFormat = false
      this.errorMessage = null
      this.errorMessageProfileName = null
      this.txtConfirm = null
      this.newData = {}
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
