import HttpRequest from './HttpRequest'

class JobScheduleService extends HttpRequest {
  constructor() {
    super(window.__env.api.apiBank + 'bankstatement-service')
  }

  async GetJobScheduleConfig(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('jobscheduleconfig', payload)
    const { data } = res
    return data
  }

  async GetLoadJobScheduleConfig() {
    await this.delay(1000)
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('jobscheduleconfig/getdata')
    const { data } = res
    return data
  }

  async GetBankAccountNumber(groupBankStatementConfigId) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`jobscheduleconfig/getBankAccountNumber/${groupBankStatementConfigId}`)
    const { data } = res
    return data
  }

  async GetCheckedScheduleTime(groupBankStatementConfigId) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`jobscheduleconfig/getCheckedScheduleTime/${groupBankStatementConfigId}`)
    const { data } = res
    return data
  }

  // async GetCheckedScheduleTimeEdit(id) {
  //   const token = this.getAccessToken()
  //   if (token) {
  //     this.setHeader({
  //       Authorization: `Bearer ${token}`
  //     })
  //   }
  //   const res = await this.get(`jobscheduleconfig/getCheckedScheduleTimeEdit/${id}`)
  //   const { data } = res
  //   return data
  // }

  async CheckJobNameDuplicate(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get(`jobscheduleconfig/checkJobNameDuplicate/${payload.jobName}/${payload.groupBankStatementConfigId}`)
    const { data } = res
    return data
  }

  async UpdateJobScheduleConfig(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.put(`jobscheduleconfig/update/${payload.GroupBankStatementConfigId}`, payload)
    const { data } = res
    return data
  }

  async ChangeWorkingDateDay(bankConfigId) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`jobscheduleconfig/WokingDateDay/${bankConfigId}`)
    const { data } = res
    return data
  }

  async UpdateJobNameConfig(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`jobscheduleconfig/${payload}/jobname`, payload)
    const { data } = res
    return data
  }

  async ActiveJobScheduleConfig(groupBankStatementConfigId, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`jobscheduleconfig/${groupBankStatementConfigId}/active`, payload)
    const { data } = res
    return data
  }

  async InActiveJobScheduleConfig(groupBankStatementConfigId, payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.patch(`jobscheduleconfig/InActive/${groupBankStatementConfigId}`, payload)
    const { data } = res
    return data
  }

  async getJobScheduleConfigByBankId(ids) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    await this.delay(1000)
    const res = await this.get(`jobscheduleconfig/${ids}/bankconfigids`)
    const { data } = res
    return data
  }

  async delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    })
  }
}

export default JobScheduleService
