<!-- eslint-disable no-unused-vars -->
<template>
  <div ref="main">
    <div class="col-span-12 overflow-auto intro-y lg:overflow-visible">
      <div class="flex flex-col mt-3 sm:flex-row">
        <button @click="onAddNewCredentialClicked($event)" class="mt-3 mr-2 shadow-md btn btn-primary">Add
          Credential
        </button>
      </div>
    </div>
    <div class="mt-5 mb-3 border-white intro-y" style="border-top-width: 1px;" />
    <div class="grid grid-cols-12 gap-6 ">
      <!-- BEGIN: Options -->
      <div class="flex flex-wrap items-center col-span-12 mt-2 sm:flex-nowrap">
        <LWDropdownMultiSelect @selectionchange="onFilterSelectionChanged($event)" class="mr-2 intro-y"
          :disabled="isLoading || isSearching" label="Bank" :options="listBankName" v-model="selectedBanks" />
        <LWDropdownMultiSelect @selectionchange="onFilterSelectionChanged($event)" class="mr-2 intro-y"
          :disabled="isLoading || isSearching" label="Status" :options="listStatus" v-model="selectedStatus" />
        <div class="w-full mt-3 intro-y sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative text-gray-700 dark:text-gray-300">
            <input v-model="textSearch" :disabled="isLoading || isSearching" @keyup="onTextSearchKeyUp($event)"
              type="text" class="max-w-xs pr-10 form-control box placeholder-theme-13" placeholder="Search..." />
          </div>
        </div>
        <div class="intro-y">
          <button type="button" class="self-auto ml-2 align-baseline shadow-md btn btn-primary " style="height: 36px;"
            @click="onSearchClicked" :disabled="isSearching || isLoading">
            <LoadingIcon v-if="!isLoading && isSearching" icon="oval" class="block mx-auto mr-3" />
            <SearchIcon v-else class="block mx-auto mr-3" />
            {{ !isLoading && isSearching ? 'Searching' : 'Search' }}
          </button>
        </div>
        <div class="hidden mx-auto text-gray-600 md:block" />
        <div class="ml-2 text-center intro-y">
          <label :class="{disabled : isLoading || isSearching}">Items per page:</label>
          <select class="w-20 mt-3 ml-2 mr-2 form-select box sm:mt-0" :disabled="isLoading || isSearching"
            v-model="selectedPageSize" @change="onPageSizeChanged">
            <option v-for="(item, index) in listPageSize" :value="item" :key="index">{{ item }}</option>
          </select>
        </div>
      </div>
      <!-- END: Options -->
      <a class="hidden" ref="editCredentialsModal" href="#" data-toggle="modal"  data-target="#edit-credentials-modal-slide-over"/>
      <!-- BEGIN: Data -->
      <div v-for="item in dataPagination.items" :key="item.id" class="col-span-12 intro-y md:col-span-3">
        <div class="h-full box "
          :class="!item.isVault || item.isExpiryDate ? 'error' : (item.isAboutExpiry ? 'warning' : '')">
          <div class="flex pt-1 pr-1 -mb-3 " style="justify-content: right;">
            <LoadingIcon v-if="item.isUpdating" icon="oval" class="w-4 h-4 mr-2 text-center" />
            <div class="self-center mr-1 text-xs" :class="item.isActive ? 'text-green-600' : 'text-theme-6'">
              {{ item.isActive ? 'Active' : 'Inactive' }}
            </div>
            <a href="#" v-if="item.isActive" data-toggle="modal" data-target="#active-credentials-modal">
              <input @change="onActiveCredentialChanged(item)" class="form-check-switch small"
                style="vertical-align: top;" v-model="item.isActive" type="checkbox" />
            </a>
            <input v-else @change="onActiveCredentialChanged(item)" class="form-check-switch small"
              style="vertical-align: top;" v-model="item.isActive" type="checkbox" />
          </div>
          <div class="flex flex-col items-center p-5 lg:flex-row">
            <div class="w-auto text-center lg:mr-auto lg:text-left lg:mt-0" style="max-width:60%">
              <div class="font-medium" style="overflow-wrap: break-word;"> {{ item.name }}</div>
              <div class="text-gray-600 text-xs mt-0.5 " style="overflow-wrap: break-word;"
                v-if="item.desc !== null && item.desc !== ''">
                {{ item.desc }}
              </div>
              <div class="text-xs mt-0.5 "
                :class="item.isExpiryDate ? 'text-theme-6' : (item.isAboutExpiry ? 'text-yellow-500' : 'text-gray-600 ')"
                v-if="item.expiryDate !== null && item.expiryDate !== ''">
                {{ item.isExpiryDate ? 'Expired' : 'Expiring' }} : {{ $filters.formatDate(item.expiryDate) }}
              </div>
            </div>
            <div class="flex mt-3 -ml-2 lg:ml-0 lg:justify-end lg:mt-0">
              <Tippy v-if="item.isExpiryDate" style="cursor:auto ;"
                class="flex items-center justify-center w-8 h-8 border rounded-full dark:border-dark-5 zoom-in"
                content="Credential has expired.">
                <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgExpired" />
              </Tippy>
              <Tippy v-if="item.isAboutExpiry" style="cursor:auto;"
                class="flex items-center justify-center w-8 h-8 ml-2 border rounded-full dark:border-dark-5 text-gray-50 zoom-in"
                content="The credential is about to expire.">
                <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgDeadlineExpired" />
              </Tippy>
              <Tippy v-if="!item.isVault" style="cursor:auto;"
                class="flex items-center justify-center w-8 h-8 ml-2 border rounded-full dark:border-dark-5 text-gray-50 zoom-in"
                content="The Secret Vault has crashed. Please update new information.">
                <img style="box-shadow: none;" class="w-12" alt="centered image" :src="imgSecurityWarning" />
              </Tippy>
              <div class="ml-auto dropdown ">
                <Tippy tag="a" href="#" aria-expanded="false"
                  class="flex items-center justify-center w-8 h-8 ml-2 text-gray-500 border rounded-full dropdown-toggle dark:border-dark-5 zoom-in"
                  content="Edit">
                  <EditIcon class="w-5 h-5" />
                </Tippy>
                <div class="w-40 dropdown-menu">
                  <div class="p-2 -mb-3 dropdown-menu__content box dark:bg-dark-1">
                    <a href="#" @click="onEditCredentialClicked(item, 'Profile')" data-toggle="modal"
                      data-target="#edit-credentials-modal-slide-over"
                      class="items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2">
                      <div>
                        <Edit2Icon class="w-4 h-4" />
                        <label class="form-check-label">
                          Profile
                        </label>
                      </div>
                    </a>
                  </div>
                  <div class="p-2 -mb-3 dropdown-menu__content box dark:bg-dark-1">
                    <a href="#"  @click="onEditCredentialClicked(item, 'Secret Vault')" data-toggle="modal"
                      data-target="#edit-credentials-modal-slide-over"
                      class="items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2">
                      <div>
                        <Edit2Icon class="w-4 h-4" />
                        <label class="form-check-label">
                          Secret Vault
                        </label>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <Tippy tag="a" href="#" @click="onDeleteCredentialClicked(item)" data-target="#delete-credentials-modal"
                data-toggle="modal"
                class="flex items-center justify-center w-8 h-8 ml-2 text-gray-500 border rounded-full dark:border-dark-5 zoom-in"
                content="Delete">
                <TrashIcon class="w-5 h-5" />
              </Tippy>
            </div>
          </div>
          <div
            class="flex flex-wrap items-center justify-center border-t border-gray-200 lg:flex-nowrap dark:border-dark-5"
            style="padding: 30px;">
            <div class="w-full mb-4 mr-auto lg:mb-0 ">
              <div class="flex -ml-3" style="position: absolute;bottom: 10px;">
                <div>
                  <i :class="item.iconClass" class="mr-2" style="width:32px;height:32px" />
                </div>
                <div class="mr-auto text-xs text-gray-600"> {{ item.bankGroupShortNameEn }} - {{ item.bankShortName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Data -->
      <!-- BEGIN: Pagination -->
      <Pagination v-if="!isLoading && !isSearching ? dataPagination.items?.length > 0 : false" :take="dataPagination.take"
        :entries="dataPagination.entries" :total="dataPagination.total" :currentPage="dataPagination.currentPage"
        :entriesName="'credentials'" :totalPage="dataPagination.totalPage" @click="onPaginationClicked($event)" />
      <!-- END: Pagination -->
    </div>
    <table v-if="isLoading || isSearching" class="table mt-5 table-report-2 intro-y">
      <tbody>
        <tr v-for="item in 3" :key="item">
          <td class="w-20">
            <Skeletor />
          </td>
          <td class="w-40">
            <Skeletor />
          </td>
          <td class="w-40">
            <div class="font-medium whitespace-nowrap">
              <Skeletor />
            </div>
            <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
              <Skeletor />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="!isLoading || !isSearching ? dataPagination.items?.length < 1 : false"
      class="px-5 pt-5 mt-5 intro-y box">
      <div class="pb-5 -mx-5 border-b border-gray-200 dark:border-dark-5">
        <div style="justify-content: center; display: flex"
          class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
          <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
        </div>
        <div style="justify-content: center; display: flex"
          class="flex-1 px-5 pt-5 mt-6 border-t border-l border-r border-gray-200 lg:mt-0 dark:text-gray-300 dark:border-dark-5 lg:border-t-0 lg:pt-0">
          <div class="mt-5 font-medium text-center lg:text-left"> Credential information is not available.</div>
        </div>
      </div>
    </div>
    <CredentialsDeleteView ref="CredentialsDeleteView" @update="onCredentialsDeleteViewUpdated($event)"
      id="delete-credentials-modal" />
    <CredentialsActiveView ref="CredentialsActiveView" @update="onCredentialsActiveViewUpdated($event)"
      id="active-credentials-modal" />
    <CredentialsEditView ref="CredentialsEditView" @update="onCredentialsEditViewUpdated($event)"
      id="edit-credentials-modal-slide-over" />
  </div>
</template>
<script>

import { bankService } from '@/services'
import imgGreenCircle from '@/assets/images/GreenCircle64.png'
import imgRedCircle from '@/assets/images/RedCircle64.png'
import imgEmptyFolder from '@/assets/images/EmptyFolder64.png'
import imgExpired from '@/assets/images/Expired64.png'
import imgDeadlineExpired from '@/assets/images/DeadlineExpired64.png'
import imgSecurityWarning from '@/assets/images/SecurityWarning64.png'
import CredentialsEditView from '@/views/corgio/credentials-edit/Main.vue'
import CredentialsDeleteView from '@/views/corgio/credentials-delete/Main.vue'
import CredentialsActiveView from '@/views/corgio/credentials-active/Main.vue'
import { useRouter } from 'vue-router'

export default {
  data() {
    return {
      imgGreenCircle,
      imgRedCircle,
      imgDeadlineExpired,
      imgExpired,
      imgSecurityWarning,
      imgEmptyFolder,
      router: null,
      isSearching: false,
      isDeleting: false,
      isLoading: false,
      listBankName: [],
      listStatus: [],
      listPageSize: [],
      textSearch: null,
      selectedPageSize: null,
      selectedBanks: [],
      selectedStatus: [],
      dataPagination: {
        pageSize: 0,
        currentPage: 0,
        total: 0,
        entries: 0,
        items: []
      }
    }
  },
  components: { CredentialsEditView, CredentialsDeleteView, CredentialsActiveView },
  methods: {
    onCredentialsActiveViewUpdated(event) {
      this.dataPagination.items.forEach(_ => {
        if (_.id === event.id) {
          _.isActive = event.isActive
        }
      })
    },
    async onCredentialsDeleteViewUpdated(event) {
      await this.GetSecretVaultByPagination()
    },
    async onCredentialsEditViewUpdated(event) {
      await this.GetSecretVaultByPagination()
    },
    async onPageSizeChanged() {
      await this.GetSecretVaultByPagination()
    },
    async onSearchClicked() {
      await this.GetSecretVaultByPagination()
    },
    async onPaginationClicked(page) {
      await this.GetSecretVaultByPagination(page)
    },
    async onTextSearchKeyUp(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        await this.GetSecretVaultByPagination()
      }
    },
    async onFilterSelectionChanged(event) {
      if (!this.$helpers.isNull(event?.source)) {
        event.source.isLoading = true
        await this.GetSecretVaultByPagination(1, true)
        event.source.isLoading = false
      } else {
        await this.GetSecretVaultByPagination()
      }
    },
    async onActiveCredentialChanged(item) {
      if (item.isActive === true) {
        try {
          item.isUpdating = true
          await bankService.UpdateSecretVaultActive(item.id, { isActive: item.isActive })
          this.$refs.main.click()
          this.$helpers.showToastify('Successfully', 'credential is ' + (item.isActive ? 'activated!' : 'inactivated!'), false)
        } catch (err) {
          item.isActive = !item.isActive
          this.$helpers.showToastify('Failed to ' + (item.isActive ? 'activated!' : 'inactivated!'), err.message, true)
        } finally {
          item.isUpdating = false
        }
      } else {
        item.isActive = !item.isActive
        await this.$refs.CredentialsActiveView.open(item)
      }
    },
    async onDeleteCredentialClicked(item) {
      await this.$refs.CredentialsDeleteView.open(item)
    },
    onEditCredentialClicked(item, val) {
      this.$refs.main.click()
      this.$refs.CredentialsEditView.open(item, val)
    },
    onAddNewCredentialClicked(event) {
      event.preventDefault()
      this.router.push({
        name: 'credentials-create'
      })
    },
    async GetSecretVaultByPagination(page = 1, isBackground = false) {
      if (!isBackground) {
        this.isSearching = true
        this.dataPagination.items = []
      }
      const payload = {
        pageSize: this.selectedPageSize,
        page: page,
        filter: this.textSearch ?? '',
        status: this.selectedStatus,
        banks: this.selectedBanks
      }
      const res = await bankService.GetSecretVaultByPagination(payload)
      if (this.$helpers.isNull(res.statusCode)) {
        this.dataPagination = res
      } else {
        this.$helpers.showToastifyResponseError(res)
      }
      if (!isBackground) {
        this.isSearching = false
      }
    },
    async getBankName() {
      this.listBankName = []
      const res = await bankService.GetBankName()
      if (res !== null && res.length > 0) {
        for (let index = 0; index < res.length; index = index + 1) {
          this.listBankName.push({ label: res[index].bankGroupShortNameEn + ' - ' + res[index].bankShortName, value: res[index].id, iconClass: res[index].iconClass })
        }
      }
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  },
  async created() {
    this.router = useRouter()
    // Assign default value
    this.listPageSize.push(12)
    this.listPageSize.push(24)
    this.listPageSize.push(48)
    this.listPageSize.push(100)
    this.selectedPageSize = this.listPageSize[0]
    this.dataPagination.currentPage = 1

    this.listStatus.push({ label: 'Active', value: true })
    this.listStatus.push({ label: 'Inactive', value: false })
  },
  async mounted() {
    this.isLoading = true
    // Promise Parallel Service
    await Promise.all([this.getBankName(), this.GetSecretVaultByPagination(this.dataPagination.currentPage)])
    this.isLoading = false
    // Check query editVault
    let tempEditVault = this.$route.query['edit-vault']
    if (!this.$helpers.isNull(tempEditVault)) {
      tempEditVault = parseInt(tempEditVault)
      const findItem = this.dataPagination.items.find((_) => _.id == tempEditVault)
      const findRef = this.$refs.editCredentialsModal
      if (findItem) {
        findRef.click()
        this.onEditCredentialClicked(findItem, 'Secret Vault')
      } else {
        const res = await bankService.GetSecretVaultById(tempEditVault)
        if (this.$helpers.isNull(res.statusCode)) {
          findRef.click()
          this.onEditCredentialClicked(res, 'Secret Vault')
        } else {
          this.$helpers.showToastify('Error', 'Invalid query', true)
        }
      }
      // // Clear Query
      // this.router.replace({ query: {} })
    }
  }
}
</script>
