<template>
  <div class="modal" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl" v-if="isShow">
      <div class="modal-content">
        <a data-dismiss="modal"  ref="elClose" href="javascript:;" @click="onDownloadClosed">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">Download File</h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body">
          <div class="grid grid-flow-col auto-cols-max items-center">
            <h2 class="font-medium text-base mr-auto">
              Code :
              {{ refData?.bankCode }}
            </h2>
          </div>
          <div class="mt-5 mb-3 grid grid-flow-col auto-cols-max items-center">
            <h2 class="font-medium text-base mr-auto">
              Bank :
            </h2>
            <i :class="refData?.iconClass" class="mr-2 ml-2" />
            <h2 class="font-medium text-base mr-auto">
              {{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }}
            </h2>
          </div>
          <div class="mt-5 mb-3 grid grid-flow-col auto-cols-max items-center">
            <h2 class="font-medium text-base mr-auto">
              Account Number :
              {{ refData?.bankAccountNo }}
            </h2>
          </div>
          <div class="grid grid-flow-col auto-cols-max items-center">
            <div class="mr-5">
              <h2 class="font-medium text-base mr-auto">Select Date</h2>
            </div>
            <div class="mr-5">
              <litepie-datepicker :disabled="isDownloadLoading" :formatter="dateFormatter" :auto-apply="false" as-single
                v-model="selectDates" />
            </div>
            <div>
              <button :disabled="isDisableSearch || isDownloadLoading" type="button"
                class="btn btn-primary mr-1 shadow-md preview self-center text-xs" @click="onDownloadSearched">
                <img class="w-5 h-5 ml-2 cursor-pointer" style="box-shadow: none" :src="imgSearch" />
                <div class="ml-3 mr-2">Search</div>
              </button>
            </div>
          </div>
          <div class="mt-5" v-if="listDownloadBackdate.length > 0 ||
            listDownloadOther.length > 0 ||
            listDownloadIntraday.length > 0
            ">
            <button type="button" :disabled="isDisableDownloadAll" @click="onDownloadFileAllClick"
              class="btn btn-primary shadow-md preview self-center text-xs">
              Download all check
            </button>
          </div>
          <div v-if="isDownloadContentLoaded">
            <table class="table table-report-2 mt-5" v-if="isDownloadLoading === true">
              <tbody>
                <tr v-for="item in 3" :key="item">
                  <td class="w-20">
                    <Skeletor />
                  </td>
                  <td class="w-40">
                    <Skeletor />
                  </td>
                  <td class="w-40">
                    <div class="font-medium whitespace-nowrap">
                      <Skeletor />
                    </div>
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      <Skeletor />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-report-2 mt-5" v-else-if="isDownloadResult == false">
              <tbody>
                <td style="justify-content: center; display: flex">
                  <div class="grid grid-flow-col auto-cols-max items-center">
                    <img style="box-shadow: none" class="mr-2 w-12" alt="centered image" :src="imgSearchNotFound" />
                    No files found
                  </div>
                </td>
              </tbody>
            </table>
            <div class="mt-5" v-if="listDownloadBackdate.length > 0">
              <div class="intro-y inbox box mt-2">
                <div class="overflow-x-auto sm:overflow-x-visible">
                  <div class="p-2 flex flex-col-reverse sm:flex-row border-b border-gray-200 dark:border-dark-1">
                    <span class="inbox__item--highlight ml-4"> Backdate </span>
                  </div>
                  <div v-for="item in listDownloadBackdate" :key="item.id" style="cursor:auto"
                    class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                    <div class="flex px-5 py-3">
                      <div class="ml-5 w-12 flex-none flex items-center">
                        <input v-model="item.IsCheck" type="checkbox" class="form-check-input border"
                          @change="onDownloadFileItemIsCheckChanged" />
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-96 flex-none flex items-center ">
                        {{ item.name }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.date }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.time }}
                      </div>
                      <div class="inbox__item--time items-center" style="align-self: center;">
                        <img class="cursor-pointer" style="width: 28px;box-shadow: none"
                          @click="onDownloadFileClicked(item)" :src="onDownloadListItemImageLoaded(item)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" v-if="listDownloadIntraday.length > 0">
              <div class="intro-y inbox box mt-2">
                <div class="overflow-x-auto sm:overflow-x-visible">
                  <div class="p-2 flex flex-col-reverse sm:flex-row border-b border-gray-200 dark:border-dark-1">
                    <span class="inbox__item--highlight ml-4"> Intraday </span>
                  </div>
                  <div v-for="item in listDownloadIntraday" :key="item.id" style="cursor:auto"
                    class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                    <div class="flex px-5 py-3">
                      <div class="ml-5 w-12 flex-none flex items-center">
                        <input v-model="item.IsCheck" type="checkbox" class="form-check-input border"
                          @change="onDownloadFileItemIsCheckChanged" />
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-96 flex-none flex items-center ">
                        {{ item.name }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.date }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.time }}
                      </div>
                      <div class="inbox__item--time items-center" style="align-self: center;">
                        <img class="cursor-pointer" style="width: 28px;box-shadow: none"
                          @click="onDownloadFileClicked(item)" :src="onDownloadListItemImageLoaded(item)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" v-if="listDownloadOther.length > 0">
              <div class="intro-y inbox box mt-2">
                <div class="overflow-x-auto sm:overflow-x-visible">
                  <div class="p-2 flex flex-col-reverse sm:flex-row border-b border-gray-200 dark:border-dark-1">
                    <span class="inbox__item--highlight ml-4"> Other </span>
                  </div>
                  <div v-for="item in listDownloadOther" :key="item.id" style="cursor:auto"
                    class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                    <div class="flex px-5 py-3">
                      <div class="ml-5 w-12 flex-none flex items-center">
                        <input v-model="item.IsCheck" type="checkbox" class="form-check-input border"
                          @change="onDownloadFileItemIsCheckChanged" />
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-96 flex-none flex items-center ">
                        {{ item.name }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.date }}
                      </div>
                      <div style="overflow-wrap: anywhere;" class="mr-2 ml-2 w-32 flex-none flex items-center">
                        {{ item.time }}
                      </div>
                      <div class="inbox__item--time items-center" style="align-self: center;">
                        <img class="cursor-pointer" style="width: 28px;box-shadow: none"
                          @click="onDownloadFileClicked(item)" :src="onDownloadListItemImageLoaded(item)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloudService } from '@/services'
import imgDownloadFile from '@/assets/images/DownloadFile32.png'
import imgDownloadPdf from '@/assets/images/DownloadPdf32.png'
import imgDownloadExcel from '@/assets/images/DownloadExcel32.png'
import imgSearch from '@/assets/images/Search32.png'
import imgSearchNotFound from '@/assets/images/SearchNotFound64.png'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgSearch,
      imgSearchNotFound,
      imgDownloadPdf,
      imgDownloadExcel,
      imgDownloadFile,
      listDownloadBackdate: [],
      listDownloadOther: [],
      listDownloadIntraday: [],
      selectDates: [],
      isDisableDownloadAll: false,
      isDownloadContentLoaded: false,
      isDownloadTableAllChecked: false,
      isDownloadTableAllIndeterminateChecked: false,
      isDownloadLoading: false,
      isDownloadResult: false,
      isDisableSearch: true,
      isShow: false,
      refData: null,
      dateFormatter: {
        date: 'YYYY-MM-DD',
        month: 'MMM'
      }
    }
  },
  watch: {
    listDownloadBackdate: {
      deep: true,
      handler(val, oldVal) {
        this.updateIsDisableDownloadAll()
      }
    },
    listDownloadOther: {
      deep: true,
      handler(val, oldVal) {
        this.updateIsDisableDownloadAll()
      }
    },
    listDownloadIntraday: {
      deep: true,
      handler(val, oldVal) {
        this.updateIsDisableDownloadAll()
      }
    },
    selectDates: {
      deep: true,
      handler(val, oldVal) {
        this.isDisableSearch = this.selectDates.length < 1
      }
    },
    isDownloadTableAllChecked(val, oldVal) {
      this.listDownloadOther.forEach((_) => {
        _.IsCheck = val
      })
      this.listDownloadBackdate.forEach((_) => {
        _.IsCheck = val
      })
      this.listDownloadIntraday.forEach((_) => {
        _.IsCheck = val
      })
      this.isDownloadTableAllIndeterminateChecked = false
    }
  },
  methods: {
    async onDownloadSearched() {
      // Set flag
      if (this.isDownloadContentLoaded == false) {
        this.isDownloadContentLoaded = true
      }
      this.isDownloadLoading = true
      this.isDownloadResult = false
      this.listDownloadBackdate = []
      this.listDownloadOther = []
      this.listDownloadIntraday = []
      // Get list download file
      try {
        const payload = {
          path: `${this.refData.bankCode
            }/${this.refData.statementType?.replace(/-/g, '')}`,
          date: this.selectDates[0]
        }
        const res = await cloudService.GetFileList(payload)
        if (this.$helpers.isNull(res?.statusCode)) {
          if (res.length > 0) {
            res.forEach((_) => {
              _.IsCheck = false
            })
          }
          // Set flag
          this.listDownloadBackdate = res.backdate ?? []
          this.listDownloadOther = res.other ?? []
          this.listDownloadIntraday = res.intraday ?? []
        } else {
          this.$helpers.showToastify('Failed find file download!', res.message, true)
        }
      } catch (e) {
        this.$helpers.showToastify('Failed find file download!', e.message, true)
      }
      this.isDownloadResult =
        this.listDownloadBackdate.length > 0 ||
        this.listDownloadOther.length > 0 ||
        this.listDownloadIntraday.length > 0
      this.isDownloadLoading = false
    },
    async onDownloadFileClicked(e) {
      const res = await cloudService.DownloadFile({
        filepath: e.path
      })
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.download = e.basename
      link.click()
    },
    async onDownloadFileAllClick(e) {
      this.listDownloadBackdate.forEach(async (_) => {
        if (_.IsCheck) {
          await this.onDownloadFileClicked(_)
        }
      })
      this.listDownloadOther.forEach(async (_) => {
        if (_.IsCheck) {
          await this.onDownloadFileClicked(_)
        }
      })
      this.listDownloadIntraday.forEach(async (_) => {
        if (_.IsCheck) {
          await this.onDownloadFileClicked(_)
        }
      })
    },
    onDownloadFileItemIsCheckChanged(e) {
      this.updateIsDisableDownloadAll()
    },
    async open(data) {
      this.refData = { ...data }
      this.isShow = true
    },
    async onDownloadClosed() {
      await this.delay(500)
      this.selectDates = []
      this.listDownloadBackdate = []
      this.listDownloadOther = []
      this.listDownloadBackdate = []
      this.isDisableDownloadAll = false
      this.isDownloadLoading = false
      this.isDownloadContentLoaded = false
      this.isDownloadResult = false
      this.isShow = false
      this.refData = null
    },
    onDownloadListItemImageLoaded(e) {
      switch (e?.extension) {
        case '.pdf':
          return imgDownloadPdf
        case '.xlsx':
        case '.xls':
          return imgDownloadExcel
        default:
          return imgDownloadFile
      }
    },
    updateIsDisableDownloadAll() {
      this.isDisableDownloadAll =
        this.listDownloadBackdate.filter((_) => _.IsCheck).length < 1 &&
        this.listDownloadOther.filter((_) => _.IsCheck).length < 1 &&
        this.listDownloadIntraday.filter((_) => _.IsCheck).length < 1
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
