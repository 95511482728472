<template>
  <div id="delete-modal-preview" class="modal" tabindex="-1" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" v-if="isShow">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5">
            <div class=" mt-2 border-b border-gray-200 dark:border-dark-5">
              <div class="mb-2"> Credential Name : {{ refData?.name }} </div>
              <div class="flex mb-2">
                <div class="mr-2"> Bank : </div>
                <i :class="refData?.iconClass" class="mr-2" style="width:16px;height:16px" />
                <label> {{ refData?.bankGroupShortNameEn }} - {{ refData?.bankShortName }} </label>
              </div>
            </div>
            <div class="mt-3">
              หากคุณต้องการลบ Credential นี้จะทำให้ Credential ที่มีการผูกกับ Bank Account นี้หายไป
            </div>
            <div class="box mt-3 max-h-96 p-5 scroll" style="text-align: -webkit-center;">
              <div v-if="!isLoaded" style="width: 32px;height: 32px;">
                <LoadingIcon icon="oval" />
              </div>
              <div v-else-if="listBank.length > 0" class="text-left">
                <div v-for="(item, index) in listBank" :key="index">
                  Code : {{ item.bankCode }}, Account No : {{ item.bankAccountNo }}
                </div>
              </div>
              <div v-else class="text-center">
                No Bank
              </div>
            </div>
            <div class="mt-3">
              และ Credential นี้ ที่มีการผูกกับ Job Schedule นี้จะหายไปด้วย
            </div>
            <div class="box mt-3 max-h-96 p-5 scroll" style="text-align: -webkit-center;">
              <div v-if="!isLoaded" style="width: 32px;height: 32px;">
                <LoadingIcon icon="oval" />
              </div>
              <div v-else-if="listJobSchedules.length > 0" class="text-left">
                <div v-for="(job, i) in listJobSchedules" :key="i">
                  Job Name : {{ job.jobName ? job.jobName.JobName : '' }}
                </div>
              </div>
              <div v-else class="text-center">
                No Job Schedule Name
              </div>
            </div>
            <div class="mt-3 ">
              คุณยืนยันที่จะลบใช่หรือไม่ ?
            </div>
          </div>
          <div style="padding-left:20px;padding-right:20px">
            <div class=" text-center border-t border-gray-200 dark:border-dark-5 " style="padding: 16px;">
              <div class="text-left -ml-4 -mt-2 mb-2 text-red-400" v-if="!$helpers.isNull(errorMessage)">
                <img :src="imgError" class=" w-6 h-6 mr-2" />
                <span>{{ errorMessage }}</span>
              </div>
              <button ref="elClose" type="button" data-dismiss="modal" @click="onCloseClicked"
                class="btn btn-outline-secondary h-10 text-right dark:border-dark-5 dark:text-gray-300 mr-2">
                Cancel
              </button>
              <button type="button" class="btn btn-primary h-10 mr-1 text-right" @click="onDeleteClicked"
                :disabled="!isLoaded || isDeleting">
                <div class="flex flex-col sm:flex-row justify-end items-center">
                  <div class="text-center">
                    {{ isDeleting ? 'Deleting' : 'Delete' }}
                  </div>
                  <LoadingIcon v-if="isDeleting" icon="oval" class="text-center ml-3 w-6 h-6" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { bankService, cloudService, jobScheduleService } from '@/services'
import imgError from '@/assets/images/Error32.png'

export default defineComponent({
  data() {
    return {
      imgError,
      refData: null,
      isShow: false,
      isLoaded: false,
      isDeleting: false,
      errorMessage: null,
      listBank: [],
      listJobSchedules: []
    }
  },
  emits: ['update'],
  methods: {
    async open(data) {
      this.clearData()
      this.isShow = true
      this.refData = { ...data }
      this.listBank = await bankService.getBankStatementConfigBySecretVault(data.id) ?? []
      const bankIds = this.listBank?.map(bank => bank.id)
      this.listJobSchedules = await jobScheduleService.getJobScheduleConfigByBankId(bankIds) ?? []
      this.isLoaded = true
    },
    async onCloseClicked() {
      await this.delay(500)
      this.clearData()
    },
    async onDeleteClicked() {
      this.isDeleting = true
      this.errorMessage = null
      try {
        await bankService.DeleteSecretVault(this.refData.id)
        await cloudService.DeleteByKey(this.refData.uuid)
        this.$helpers.showToastify('Successfully', 'deleted Credential!', false)
        this.$refs.elClose.click()
        this.$emit('update', this)
        await this.getJobScheduleConfig()
      } catch (e) {
        this.errorMessage = e.message
        this.isDeleting = false
      }
    },
    clearData() {
      this.isShow = false
      this.refData = null
      this.errorMessage = null
      this.isDeleting = false
      this.isLoaded = false
      this.newData = {}
    },
    async getJobScheduleConfig() {
      const res = await jobScheduleService.GetLoadJobScheduleConfig()
      console.log('JobScheduleConfig', res)
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
