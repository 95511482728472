<template>
  <details v-for="(item, index) in children" :key="index" :open="isOpen" :class="item?.children?.length > 0 ? 'show-icon' : 'non-icon'"
      class="tree-nav__item  is-expandable">
    <summary class="tree-nav__item-title" >
      <div  class="self-center"  style="overflow-wrap: anywhere; ">
        <input v-if="isCheckbox" :indeterminate="item.isIndeterminate"  v-model="item.checkboxValue"  type="checkbox" class="form-check-input mr-2"
        @click="onCheckboxClicked($event, item)" />
        <slot name="content" :values="item"/>
      </div>

    </summary>
    <Tree-View-Item :ref="item.refKeyItem" :isOpen="isOpen" :children="item?.children ?? []" :isCheckbox="isCheckbox" @child-checkbox-change="onChildCheckBoxChanged($event, item)" >
      <template v-slot:content="{ values }">
        <slot name="content" :values="values"/>
     </template>
    </Tree-View-Item>
  </details>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isCheckbox: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    children: {
      type: Array,
      default: () => []
    }
  },
  emits: ['child-checkbox-change'],
  data() {
    return {
    }
  },
  created() {
    this.children.forEach(_ => {
      if (_.checkboxValue === null || _.checkboxValue === undefined) { _.checkboxValue = false }
      if (_.children === null || _.children === undefined) { _.children = [] }
      _.refKeyItem = this.$helpers.generateUUID()
      _.isIndeterminate = false
    })
  },
  methods: {
    onChildCheckBoxChanged(obj, item) {
      if (obj) {
        const finds = item.children.filter(_ => _.checkboxValue === true)
        item.checkboxValue = false
        item.isIndeterminate = obj.isIndeterminate
        if (finds.length === item.children.length) {
          item.checkboxValue = true
        } else if (finds.length === 0) {
          item.checkboxValue = false
        } else {
          item.isIndeterminate = true
        }
      }
      this.$emit('child-checkbox-change', item)
    },
    updateCheckbox(val) {
      this.children.forEach(_ => {
        _.checkboxValue = val
        _.isIndeterminate = false
        if (_?.obj?.checkboxValue != undefined || _?.obj?.checkboxValue != null) {
          _.obj.checkboxValue = val
        }
        if (_.children.length > 0) {
          this.$refs[_.refKeyItem][0]?.updateCheckbox(val)
        }
      })
    },
    onCheckboxClicked(event, item) {
      item.checkboxValue = event.currentTarget.checked
      item.isIndeterminate = false
      if (item.children.length > 0) {
        this.$refs[item.refKeyItem][0]?.updateCheckbox(event.currentTarget.checked)
      } else {
        if (item?.obj?.checkboxValue != undefined || item?.obj?.checkboxValue != null) {
          item.obj.checkboxValue = event.currentTarget.checked
        }
      }
      this.onChildCheckBoxChanged(null, item)
    },
    updateCheckValueChild(item) {
      this.children.forEach(_ => {
        if (_.obj !== null && _.obj !== undefined) {
          if (_.obj === item) {
            _.checkboxValue = item.checkboxValue
            _.isIndeterminate = false
            if (_.children.length > 0) {
              this.$refs[_.refKeyItem][0]?.updateCheckbox(item.checkboxValue)
            }
            this.onChildCheckBoxChanged(null, item)
          }
        } else {
          if (_.children.length > 0) {
            this.$refs[_.refKeyItem][0]?.updateCheckValueChild(item)
          }
        }
      })
    }
  }
})
</script>
