<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Create Bank Account</h2>
    </div>
    <div class="intro-y box px-5 py-10 mt-5 sm:px-20  ">
      <div class="px-5 sm:px-20 pb-10 border-b border-gray-200 dark:border-dark-5">
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-3 ">
          <div class="intro-y col-span-12 sm:col-span-12">
            <label class="form-label">Type</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="flex items-center">
              <div class="form-check mr-2" v-for="(item, index) in listBankStatementType" :key="index">
                <input :disabled="isDownloadingBankServices || isCreating || !isLoaded"
                  v-model="newData.bankStatementType"  :id="'radio-switch-' + index" class="form-check-input"
                  type="radio" name="listBankAccountType_radio_button" @change="onBankStatementTypeChanged($event)"
                  :value="item.id" />
                <label class="form-check-label" :for="'radio-switch-' + index">{{ item.name }}</label>
              </div>
              <LoadingIcon v-if="!isLoaded" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
            <div class="mt-2 ml-1 text-theme-6" v-if="isLoaded && $helpers.isNull(newData?.bankStatementType)" >This field is required</div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Bank</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="flex items-center">
              <TomSelect v-model="newData.bank" class="w-full" placeholder="Select Bank"
                @change="onSelectBankChanged($event)" :disabled="isDownloadingBankServices || isCreating || !isLoaded">
                <option v-for="option in listBanks" :key="option.id" :value="option.id">
                  {{ `${option.bankGroupShortNameEn} - ${option.bankShortName}|||${option.iconClass}` }}
                </option>
              </TomSelect>
              <LoadingIcon v-if="!isLoaded" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
            <div class="mt-2 ml-1 text-theme-6" v-if="isLoaded && $helpers.isNull(newData?.bank)" >This field is required</div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Service</label>
            <label class="text-theme-6 ml-2">*</label>
            <div v-if="!isLoaded || isDownloadingBankServices || listBankAccountService.length > 0">
              <div class="flex flex-col sm:flex-row ml-1 ">
                <TomSelect v-model="newData.bankService" :disabled="isDownloadingBankServices || isCreating || !isLoaded" class="w-full" placeholder="Select Service">
                <option v-for="option in listBankAccountService" :key="option.id" :value="option.bankServiceTypeId">
                  {{ option.bankServiceTypeName }}
                </option>
                </TomSelect>
                <LoadingIcon v-if="!isLoaded || isDownloadingBankServices" icon="oval" class="ml-2 w-8 h-8" />
              </div>
              <div class="mt-2 ml-1 text-theme-6" v-if="isLoaded && !isDownloadingBankServices && $helpers.isNull(newData?.bankService)" >This field is required</div>
            </div>
            <div v-else class="mt-2 ml-1 mb-3 text-theme-6"> No Service, please select other bank or type. </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Account Number</label>
            <label class="text-theme-6 ml-2">*</label>
            <input :disabled="!isLoaded || isCreating" v-model="newData.bankNo" @input="onInputAccountNumberInput($event)"
                    @keypress="onInputAccountNumberKeyPress($event)" type="text" class="form-control"
                    placeholder="###-#-#####-#" />
            <div v-if="isLoaded && $helpers.isNull(newData.bankNo)" class="text-theme-6 mt-2">
              This field is required
            </div>
            <div v-else-if="isLoaded && isValidateBankNo()" class="text-theme-6 mt-2">
              This field should be at least 10 characters long
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Working Date</label>
            <label class="text-theme-6 ml-2">*</label>
            <div class="flex flex-col sm:flex-row ml-1 ">
              <TomSelect :disabled="!isLoaded || isCreating" v-model="newData.workingDateDay" class="w-full"
              placeholder="Select Service">
              <option v-for="option in listWorkingDate" :key="option.id" :value="option.days">
                {{ option.name }}
              </option>
            </TomSelect>
              <LoadingIcon v-if="!isLoaded" icon="oval" class="ml-2 w-8 h-8" />
            </div>
          <div v-if="isLoaded && $helpers.isNull(newData?.workingDateDay)" class="text-theme-6 mt-2">
            This field is required
          </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label class="form-label">Company</label>
            <input :disabled="!isLoaded || isCreating" v-model.trim="newData.company" type="text" class="form-control"
            placeholder="Company Name" />
            <div v-if="isLoaded && isValidateCompany()" class="text-theme-6 mt-2">
              This field is required
            </div>
          </div>
        </div>
      </div>
      <div >
        <div class="col-span-12 flex text-theme-6 mt-2" v-if="!$helpers.isNull(errorMessage)">
          <img :src="imgError" class=" w-6 h-6 mr-2" />
          <span>{{ errorMessage }}</span>
        </div>
        <div class="col-span-12 flex items-center justify-center sm:justify-end mt-5 ">
          <button :disabled="isCreating || !isLoaded || isValidateData() || isValidateBankNo() || isValidateCompany()" class="btn btn-primary ml-2" style="min-width: 6rem;"  @click="onCreateClicked($event)">
            <div class="flex flex-col sm:flex-row justify-end items-center">
              <div class="text-center">
                {{ isCreating ? "Creating" : "Create" }}
              </div>
              <LoadingIcon v-if="isCreating" icon="oval" class="text-center ml-3 w-6 h-6" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { bankService } from '@/services'
import imgError from '@/assets/images/Error32.png'
import { useRouter } from 'vue-router'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgError,
      router: null,
      isLoaded: false,
      isCreating: false,
      isDownloadingBankServices: false,
      listBanks: [],
      listWorkingDate: [],
      listBankStatementType: [],
      listBankAccountService: [],
      errorMessage: null,
      newData: {
        bankStatementType: null,
        bank: null,
        bankNo: null,
        bankService: null,
        workingDateDay: null,
        company: null
      }
    }
  },
  methods: {
    isValidateCompany() {
      const find = this.listBanks.filter(_ => _.id === Number(this.newData.bank))
      if (find.length > 0 ? find[0].isCompany : false) {
        return this.$helpers.isNull(this.newData.company)
      } else {
        return false
      }
    },
    isValidateBankNo() {
      return this.newData.bankNo?.replace(/-/g, '').length !== 10
    },
    isValidateData() {
      return this.$helpers.isNull(this.newData.bankStatementType) ||
             this.$helpers.isNull(this.newData.bank) ||
             this.$helpers.isNull(this.newData.bankNo) ||
             this.$helpers.isNull(this.newData.bankService)
    },
    onInputAccountNumberInput(e) {
      const temp = e.target.value?.replace(/-/g, '')
      if (temp.length > 9) {
        this.newData.bankNo =
          temp.slice(0, 3) +
          '-' +
          temp.slice(3, 4) +
          '-' +
          temp.slice(4, 9) +
          '-' +
          temp.slice(9, 10)
      } else if (temp.length > 4) {
        this.newData.bankNo =
          temp.slice(0, 3) + '-' + temp.slice(3, 4) + '-' + temp.slice(4, 9)
      } else if (temp?.length > 3) {
        this.newData.bankNo = temp.slice(0, 3) + '-' + temp.slice(3, 4)
      }
    },
    onInputAccountNumberKeyPress(e) {
      if (e.target.value?.replace(/-/g, '').length > 9) {
        e.preventDefault()
      }
      const charCode = e.which ? e.which : e.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault()
      } else {
        return true
      }
    },
    async onCreateClicked(event) {
      this.isCreating = true
      this.errorMessage = null
      try {
        const bankNameItem = this.listBanks.find(
          (x) => x.id === Number(this.newData.bank)
        )
        const payload = {
          id: 0,
          bankCode: this.isEditing ? this.newData.bankCode : '',
          bankRefCode: bankNameItem.bankCode,
          bankId: this.newData.bank,
          bankAccountNo: this.newData.bankNo ? this.newData.bankNo : '',
          company: this.newData.company,
          bankServiceTypeId: this.newData.bankService,
          statementTypeId: this.newData.bankStatementType,
          workingDateDay: this.newData.workingDateDay,
          bankBranchCode: '',
          remark: '',
          isUserEnable: false,
          secretVault: null
        }
        const res = await bankService.AddBankStatementConfig(payload)
        if (this.$helpers.isNull(res?.statusCode)) {
          event.preventDefault()
          this.$helpers.showToastify(
            'Successfully', 'created new bank account.',
            false
          )
          this.router.push({
            name: 'bankmanagement'
          })
        } else {
          this.errorMessage = res.message
        }
      } catch (e) {
        this.errorMessage = e.message
      }
      this.isCreating = false
    },
    async onBankStatementTypeChanged(event) {
      await this.getBankAccountService()
    },
    async onSelectBankChanged(event) {
      await this.getBankAccountService()
    },
    async getAllBank() {
      this.listBanks = await bankService.GetBankName()
      this.newData.bank = this.listBanks[0]?.id
    },
    async getBankStatementType() {
      this.listBankStatementType = await bankService.GetBankStatementType()
      this.newData.bankStatementType = this.listBankStatementType[0]?.id
    },
    async getBankAccountService() {
      this.listBankAccountService = []
      this.newData.bankService = null
      if (this.$helpers.isNull(this.newData.bank) || this.$helpers.isNull(this.newData.bankStatementType)) {
        this.listBankAccountService = []
      } else {
        this.isDownloadingBankServices = true
        const res = await bankService.GetBankAccountService({
          bankid: this.newData.bank,
          statementtypeid: this.newData.bankStatementType
        })
        this.listBankAccountService = res
        if (res.length > 0) {
          this.newData.bankService = res[0]?.bankServiceTypeId
        }
        this.isDownloadingBankServices = false
      }
    },
    async getWorkingDate() {
      this.listWorkingDate = await bankService.GetWorkingDate()
      this.newData.workingDateDay = this.listWorkingDate[0]?.days
    }
  },
  async created() {
    this.router = useRouter()
    await this.getWorkingDate()
  },
  async mounted() {
    await this.getBankStatementType()
    await this.getAllBank()
    await this.getBankAccountService()
    this.isLoaded = true
  }
})
</script>
