import CryptoJS from 'crypto-js'
import { authService } from '@/services'
import * as mutationTypes from './mutation-types'
import * as storageTypes from './storage-types'
import * as getterTypes from './getter-types'
import * as actionTypes from './action-types'

const state = () => {
  return {
    darkMode: true,
    page: null,
    profile: null,
    package: null,
    provider: null,
    token: null,
    image: null
  }
}

const methods = {
  isNull: (val) => {
    return val === null || val === undefined || val === ''
  },
  secretKey: () => 'dJwtkV8j6HZne%g4SrQbIiN2x.UGPTzRKuXMsf5mWov3yCLOqhEAl1cp0aFDY97B',
  decrypt: (val) => {
    if (methods.isNull(val)) {
      return null
    }
    const decrypt = CryptoJS.AES.decrypt(val, methods.secretKey())
    return decrypt.toString(CryptoJS.enc.Utf8)
  },
  encrypt: (val) => {
    if (methods.isNull(val)) {
      return null
    }
    const encrypt = CryptoJS.AES.encrypt(val, methods.secretKey())
    return encrypt.toString()
  }
}

// getters
const getters = {
  [getterTypes.GET_IS_LOGGED_IN]: (state, getters) => !methods.isNull(getters.GET_TOKEN),
  [getterTypes.GET_HAS_PACKAGE]: (state, getters) => !methods.isNull(getters.GET_PACKAGE),
  [getterTypes.GET_HAS_PROFILE]: (state, getters) => !methods.isNull(getters.GET_PROFILE),
  [getterTypes.GET_PAGE]: state => state.page,
  [getterTypes.GET_IMAGE]: state => state.image,
  [getterTypes.GET_IS_SYNC_PROVIDER]: (state, getters) => getters.GET_PROVIDER?.isSync ?? false,
  [getterTypes.GET_PROVIDER]: state => state.provider,
  [getterTypes.GET_PACKAGE]: state => state.package,
  [getterTypes.GET_PROFILE]: state => state.profile,
  [getterTypes.GET_DARK_MODE]: state => state.darkMode,
  [getterTypes.GET_TOKEN]: state => {
    if (methods.isNull(state.token)) {
      state.token = methods.decrypt(localStorage.getItem(storageTypes.TOKEN))
    }
    return state.token
  }
}
// actions
const actions = {
  async [actionTypes.UPDATE_USER_DATA]({ commit, getters }) {
    if (!getters.GET_HAS_PROFILE) {
      const data = await authService.getProfile()
      commit(mutationTypes.SET_PROVIDER, data.userProvider)
      commit(mutationTypes.SET_PROFILE, data.userProfile)
      commit(mutationTypes.SET_IMAGE, data.userImage)
      commit(mutationTypes.SET_PACKAGE, data.userPackage)
    }
  },
  [actionTypes.UPDATE_PACKAGE]({ commit, getters }) {
    authService.getPackage().then((values) => {
      commit(mutationTypes.SET_PACKAGE, values)
    })
  },
  [actionTypes.CLEAR_DATA]({ commit }) {
    commit(mutationTypes.CLEAR_TOKEN)
    // USER DATA
    commit(mutationTypes.CLEAR_PROFILE)
    commit(mutationTypes.CLEAR_IMAGE)
    commit(mutationTypes.CLEAR_PACKAGE)
    commit(mutationTypes.CLEAR_PROVIDER)
    // SET DEFAULT
    commit(mutationTypes.SET_DARK_MODE, true)
  }
}

// mutations
const mutations = {
  [mutationTypes.CLEAR_TOKEN](state) {
    state.token = null
    localStorage.removeItem(storageTypes.TOKEN)
  },
  [mutationTypes.CLEAR_PAGE](state) {
    state.page = null
  },
  [mutationTypes.CLEAR_PROFILE](state) {
    state.profile = null
  },
  [mutationTypes.CLEAR_IMAGE](state) {
    state.image = null
  },
  [mutationTypes.CLEAR_PACKAGE](state) {
    state.package = null
  },
  [mutationTypes.CLEAR_PROVIDER](state) {
    state.provider = null
  },
  [mutationTypes.SET_IMAGE](state, value) {
    state.image = value
  },
  [mutationTypes.SET_PROVIDER](state, value) {
    state.provider = value
  },
  [mutationTypes.SET_PACKAGE](state, value) {
    state.package = value
  },
  [mutationTypes.SET_PROFILE](state, value) {
    state.profile = value
  },
  [mutationTypes.SET_DARK_MODE](state, value) {
    state.darkMode = value
  },
  [mutationTypes.SET_PAGE](state, value) {
    state.page = value
  },
  [mutationTypes.SET_TOKEN](state, token) {
    state.token = token
    localStorage.setItem(storageTypes.TOKEN, methods.encrypt(token))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
