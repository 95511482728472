<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5 intro-y">
      <div class="col-span-12 lg:col-span-12">
        <div class="intro-y box">
          <div class="flex flex-col items-center p-5 border-b border-gray-200 sm:flex-row dark:border-dark-5">
            <h2 class="mr-auto text-base font-medium">คำถามที่พบบ่อย</h2>
          </div>
          <div id="boxed-accordion" class="p-5">
            <div class="preview">
              <div id="faq-accordion-1" class="accordion accordion-boxed">
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-1" class="accordion-header">
                    <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="false"
                    aria-controls="faq-accordion-collapse-1">
                      <div class="flex flex-col sm:flex-row ">
                        <span>1. มีธนาคารอะไรบ้างที่รองรับการทำงานของระบบ StatementPro</span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-1"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-1"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white" >
                      <div class="intro-x">
                        <span> ปัจจุบันธนาคารที่รองรับ ประกอบไปด้วย</span>
                        <div class="mt-5">
                          <div style="border-top-left-radius: 10px;border-top-right-radius: 10px;"
                                class="inline-block text-gray-700 bg-gray-100 border-b border-gray-200  inbox__item sm:block dark:text-gray-500 dark:bg-dark-1 dark:border-dark-1">
                            <div class="flex px-5 py-3 ">
                              <div class="items-center mr-5 text-white w-96 dark:text-white" >
                                ธนาคาร
                              </div>
                              <div class="items-center mr-5 text-white w-96 dark:text-white" >
                                ชื่อเว็บไซต์
                              </div>
                            </div>
                          </div>
                        </div>
                       <div class="overflow-x-auto inbox box sm:overflow-x-visible">
                         <div class="intro-x" v-for="(item, index) in listBank" :key="index">
                           <div class="inline-block text-gray-700 bg-gray-100 border-b border-gray-200 inbox__item--active inbox__item sm:block dark:text-gray-500 dark:bg-dark-1 dark:border-dark-1">
                             <div class="flex px-5 py-3">
                               <div class="items-center mr-5 w-96" >
                                {{ item.bank }}
                               </div>
                               <div class="items-center mr-5 w-96 " >
                                {{ item.site }}
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          2. ลูกค้าสามารถเพิ่มธนาคารที่ไม่ได้อยู่ใน List รายการที่รองรับการทำงานได้หรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1" >
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                          ขณะนี้ยังไม่สามารถเพิ่มธนาคารจากใน List ด้านบนได้ แต่ในอนาคตทาง Lightwork จะมีการพัฒนาในส่วนของธนาคารอื่นๆ ลูกค้าสามารถแจ้งความประสงค์การใช้งานธนาคารอื่นๆ ของลูกค้ากลับมาได้ ผ่านทางหน้าเว็บไซต์ของ Lightwork เพื่อใช้ในการพัฒนาในอนาคต
                    </div>
                  </div>
                </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-3" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-3"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-3">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          3. บัญชีประเภทใดบ้างที่รองรับการทำงานของระบบ StatementPro
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-3"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-3"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                          สามารถทำได้ทั้งบัญชีออมทรัพย์, กระแสรายวัน, ฝากประจำ
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-4" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-4"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-4">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          4. หากต้องการให้ระบบ StatementPro ทำงานจะต้องเตรียมข้อมูลอะไรบ้าง
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-4"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-4"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-y ">
                        <span> ข้อมูลที่จะต้องเตรียม เพื่อเชื่อมข้อมูล Statement ประกอบด้วย </span>
                        <table class="table mt-5">
                          <thead>
                            <tr class="text-white bg-gray-700 dark:bg-dark-1">
                              <th class="border-b-2 border-gray-600 intro-x dark:border-dark-5 whitespace-nowrap">
                                ลำดับ
                              </th>
                              <th class="border-b-2 border-gray-600 intro-x dark:border-dark-5 whitespace-nowrap">
                                ข้อมูลส่วนที่กรอกเข้าระบบ StatementPro
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in listLightworkData" :key="index"
                                class="text-white bg-gray-700 intro-x dark:bg-dark-1">
                              <td class="border-b border-gray-600">{{item.id}}</td>
                              <td class="border-b border-gray-600">{{item.text}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-5" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-5"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-5">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          5. จำเป็นต้องส่งข้อมูล Login (เช่น Username, Password) ให้กับเจ้าหน้าที่หรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-5"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-5"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        ไม่ต้องส่งข้อมูล Login ให้กับเจ้าหน้าที่ ลูกค้าสามารถกรอกข้อมูล Login เข้าสู่ระบบ StatementPro ด้วยตัวเองได้เลย
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-6" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-6"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-6">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          6. ข้อมูล Login ที่นำไปใช้กับระบบ StatementPro สามารถใช้ User เดียวกันกับที่ลูกค้าใช้อยู่ในปัจจุบันได้หรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-6"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-6"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        • ไม่ควรใช้ข้อมูล Login เดียวกันกับที่ลูกค้าใช้งานอยู่ เนื่องจากเว็บไซต์ธนาคารส่วนใหญ่จะมีระบบป้องกันการ Login เข้าใช้งานพร้อมกัน หากเข้าในเวลาเดียวกัน มีความเสี่ยงที่จะทำให้ผู้ที่ใช้งาน Login นั้นๆ ไม่สามารถเข้าสู่ระบบได้ ทำให้เกิดความผิดพลาดในการดึงข้อมูล Statement
                      </div>
                      <div class="mt-3 intro-x">
                        • แนะนำให้สร้าง Username แยกสำหรับระบบ StatementPro โดยให้มีสิทธิ์แค่ดูข้อมูล ได้เท่านั้น (View only) หรือหากไม่สามารถสร้างได้จำเป็นต้องใช้ Username เดียวกันทางลูกค้าจะต้องไม่ทำการ Login ในช่วงเวลาที่ระบบ StatementPro ทำงานอยู่
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-7" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-7"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-7">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          7. ลูกค้าจะทราบได้อย่างไร ว่ารหัสผ่านจะไม่ถูกนำไปใช้หรือเผยแพร่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-7"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-7"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                      การเก็บข้อมูล Login ไม่ว่าจะเป็น Username/Password ระบบ StatementPro มีการเก็บโดย Azure Key Vault ซึ่งเป็นบริการ Cloud โดย Microsoft สำหรับการจัดเก็บและเข้าถึงข้อมูลที่เป็นความลับ มีความปลอดภัยสูงในการเข้าถึงข้อมูล เหมาะกับการเก็บข้อมูล Password ที่ต้องการ Security สูง
                    </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-8" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-8"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-8">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          8. ลูกค้าจะทราบได้อย่างไรว่า การเข้าถึง Bank Account ของระบบ StatementPro จะไม่ถูกนำไปทำธุรกรรมทางการเงินที่ก่อให้เกิดความเสียหาย
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-8"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-8"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                       <div class="intro-x">
                        ลูกค้าสามารถสร้าง User เพื่อนำมาใช้กับระบบ StatementPro โดย Add Permission (สิทธิ์การเข้าถึง) ให้ทำได้เฉพาะ อ่านข้อมูล Statement เท่านั้นได้ ทำให้มั่นใจได้ว่า User นี้ไม่มีสิทธิ์การทำงานอื่นๆ นอกเหนือจากการอ่านข้อมูล ทั้งนี้การตั้งค่าของระบบจะทำแค่เชื่อมข้อมูลส่วน Statement เท่านั้น ไม่มีการตั้งค่าส่วนที่เกี่ยวข้องกับการโอนเงินทั้งเข้าและออกแต่อย่างใด
                       </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-9" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-9"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-9">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          9. ลูกค้าต้องการเพิ่มข้อมูลเลขบัญชีธนาคารเพื่อให้ระบบ StatementPro ทำงานได้อย่างไร
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-9"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-9"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        ในการเพิ่ม Bank Account จะต้องเพิ่มข้อมูลจากเว็บไซต์ของ Peak ก่อนและทำการ Sync เข้าระบบ Lightwork โดยมีวิธีการดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1. Login เพื่อเข้าสู่หน้าเว็บไซต์ของ Peak
                        </span>
                        <div class="thumbnail" style="height:600px" >
                           <img alt="random image"  :src="imgFaq91" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. แถบด้านบนจะพบเมนู ให้เลือก การเงิน > เงินสด/ธนาคาร/e-Wallet > เพิ่มช่องทาง
                        </span>
                        <div class="thumbnail" style="height:600px" >
                           <img alt="random image"  :src="imgFaq92" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          3. จะพบ Popup “เพิ่มช่องทางการเงิน” ให้กรอกข้อมูล ธนาคาร, ประเภทบัญชี, ชื่อบัญชีธนาคาร, เลขบัญชีธนาคาร จากนั้นกดปุ่ม “เพิ่มบัญชีธนาคาร”<br/>หมายเหตุ: กรณีมีข้อมูลช่องทางการเงินอยู่แล้ว ให้ข้ามขั้นตอนนี้ไป
                        </span>
                        <div class="thumbnail" style="height:600px" >
                           <img alt="random image"  :src="imgFaq93" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          4. เมื่อกรอกข้อมูลช่องทางการเงินเรียบร้อยแล้ว จะพบรายการที่กรอกไปส่วน เงินฝากธนาคาร-ออมทรัพย์ XX บัญชี
                        </span>
                        <div class="thumbnail" style="height:800px" >
                           <img alt="random image"  :src="imgFaq94" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          5. กลับเข้าไปที่เว็บไซต์ของระบบ StatementPro จากนั้นไปที่เมนู Bank Management แล้วกดปุ่ม “Sync Bank Accounts” เพื่อเป็นการดึงข้อมูล Bank Account จากหน้าเว็บไซต์ของ Peak ที่กรอกไป
                        </span>
                        <div class="thumbnail" style="height:310px" >
                           <img alt="random image"  :src="imgFaq95" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          6. เมื่อกด Sync Bank Accounts แล้ว จะพบว่ารายการ Bank Account ที่เพิ่มในเว็บไซต์ของ Peak จะปรากฏในตารางด้านล่าง
                        </span>
                        <div class="thumbnail" style="height:450px" >
                           <img alt="random image"  :src="imgFaq96" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          7.ให้ทำการ Set ข้อมูลของแต่ละ Bank Account ก่อน โดยกดที่ icon Edit หลังคำว่า
                        </span>
                        <span class="text-yellow-400">
                          ** Please Update Bank Information
                        </span>
                        <div class="thumbnail" style="height:450px" >
                           <img alt="random image"  :src="imgFaq97" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          8. จะพบหน้า Form ที่แบ่งเป็น 2 ส่วนหลักๆคือ
                        </span>
                      </div>
                      <div class="mt-3 ml-3 intro-x">
                        <span>
                          • ส่วนที่ 1: ข้อมูลที่ Sync มาจากระบบ Peak ที่ลูกค้าได้ทำการกรอกจากหน้า Peak มาแล้ว
                        </span>
                      </div>
                      <div class="mt-3 ml-3 intro-x">
                        <span>
                          • ส่วนที่ 2: ข้อมูลที่ต้อง Set เพิ่มในระบบ StatementPro ประกอบด้วย
                        </span>
                      </div>
                      <div class="mt-3 ml-5 intro-x">
                        <span>
                          i. Bank Site = เว็บไซต์ของธนาคารที่ต้องการให้เข้าไป Download ข้อมูล Statement
                        </span>
                      </div>
                      <div class="mt-3 ml-5 intro-x">
                        <span>
                          ii. Service = Service/เมนูที่ต้องเข้าไป Download ของเว็บไซต์ที่เหลือจาก Bank Site
                        </span>
                      </div>
                      <div class="mt-3 ml-5 intro-x">
                        <span>
                          iii. Working Date = วันที่ต้องการดึงข้อมูล ในที่นี่จะเป็น Backdate (ข้อมูลย้อนหลัง 1 วันเสมอ)
                        </span>
                      </div>
                      <div class="mt-3 ml-5 intro-x">
                        <span>
                          iv. Company Name = CompanyName/Business Unit ถ้ามีและต้องเลือกในเว็บไซต์นั้นๆ หากไม่มีไม่ต้องกรอก
                        </span>
                        <div class="thumbnail" style="height:700px" >
                           <img alt="random image"  :src="imgFaq98" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          9. เมื่อกรอกข้อมูลเรียบร้อยแล้ว ให้กดปุ่ม Update
                        </span>
                        <div class="thumbnail" style="height:1100px" >
                           <img alt="random image"  :src="imgFaq99" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          10. ข้อมูลในแถวนั้นๆ จะถูก Set เรียบร้อยแล้ว
                        </span>
                        <div class="thumbnail" style="height:450px" >
                           <img alt="random image"  :src="imgFaq910" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-10" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-10"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-10">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          10. ลูกค้าสามารถ Set รหัสผ่านเองเมื่อมีการเพิ่มเลขบัญชีมาแล้วได้อย่างไร
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-10"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-10"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                         ลูกค้าสามารถ Set รหัสผ่านใหม่เองได้ โดยดำเนินการเพิ่มดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1. ไปที่เว็บไซต์ของระบบ StatementPro จากนั้นไปที่เมนู Credentials กดปุ่ม Add Credential
                        </span>
                        <div class="thumbnail" style="height:500px">
                           <img alt="random image"  :src="imgFaq101" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. จะพบ Form ให้กรอกข้อมูลการ Login ของแต่ละเว็บไซต์ธนาคาร โดยในแต่ละธนาคารมีข้อมูลที่ให้กรอกแตกต่างกันไป ขึ้นอยู่กับเว็บไซต์ของธนาคารนั้นๆ
                        </span>
                      </div>
                      <div class="mt-3 text-center intro-x">
                        <span>
                          ส่วน Setup Profile
                        </span>
                        <div class="thumbnail" style="height:650px" >
                           <img alt="random image"  :src="imgFaq102" />
                        </div>
                      </div>
                      <div class="mt-3 text-center intro-x">
                        <span>
                          ส่วน Setup Secret Vault
                        </span>
                        <div class="thumbnail" style="height:520px" >
                           <img alt="random image"  :src="imgFaq103" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          3. จากนั้นตรวจสอบข้อมูลอีกครั้ง และพิมพ์ Confirm ในช่องด้านล่าง จากนั้นกดปุ่ม Create ก็จะสร้างข้อมูลการ Login ของธนาคาร เสร็จเรียบร้อย
                        </span>
                        <div class="thumbnail" style="height:670px" >
                           <img alt="random image"  :src="imgFaq104" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          4. เมื่อสร้างข้อมูล Login เรียบร้อยแล้ว จะแสดงที่หน้า Credential
                        </span>
                        <div class="thumbnail" style="height:500px" >
                           <img alt="random image"  :src="imgFaq105" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          5. กลับไปที่เมนู Bank Management จะทำการผูก Credential ที่ได้สร้างไป โดยคลิกที่ Drop Down ด้านหน้าชื่อธนาคาร เพื่อเลือก Credentials Name ที่ได้สร้างไว้ ว่า Bank Account นี้จะต้องใช้ข้อมูล Login ใดจึงจะสามารถเข้าเว็บไซต์ของธนาคารได้ เมื่อเลือกเสร็จแล้ว ถือเป็นอันผูกข้อมูลสำเร็จ
                        </span>
                        <div class="thumbnail" style="height:450px" >
                           <img alt="random image"  :src="imgFaq106" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-11" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-11"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-11">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          11. หากมีการเปลี่ยนแปลงรหัสผ่านจะต้องแจ้งเจ้าหน้าที่หรือสามารถเปลี่ยนแปลงเองได้อย่างไร
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-11"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-11"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        ลูกค้าสามารถเปลี่ยนแปลงรหัสผ่านเองได้เลย โดยไม่จำเป็นต้องแจ้งเจ้าหน้าที่ โดยทำการเปลี่ยนแปลงดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          1. เข้าไปที่หน้าของ Lightwork ไปที่เมนู: Credentials เลือก Credentials ที่ต้องการแก้ไขข้อมูล โดยคลิกที่ icon Edit ใน Credential ที่ต้องการแก้ไข
                        </span>
                        <div class="thumbnail" style="height:500px">
                           <img alt="random image"  :src="imgFaq111" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          2. เลือก Secret Vault สำหรับแก้ไขข้อมูลการ Login ของธนาคารต่างๆ และเมื่อแก้ไขแล้วให้พิมพ์ “Confirm” เพื่อเป็นการยืนยันว่าได้ตรวจสอบความถูกต้องของข้อมูล Login แล้ว
                        </span>
                        <div class="thumbnail" style="height:800px">
                           <img alt="random image"  :src="imgFaq112" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        <span>
                          3. เมื่อแก้ไขเรียบร้อย ให้ทำการกดปุ่ม Update
                        </span>
                        <div class="thumbnail" style="height:1000px">
                           <img alt="random image"  :src="imgFaq113" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-12" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-12"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-12">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          12. หากต้องการแก้ไขชื่อธนาคารหรือเลขบัญชี สามารถแก้ไขผ่านหน้าเว็บไซต์ของ StatementPro ได้เลยหรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-12"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-12"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        ไม่สามารถแก้ไขผ่านหน้าเว็บไซต์ของ StatementPro ได้ จะต้องเข้าไปแก้ไขที่หน้าเว็บไซต์ PEAK แล้วทำการ Sync เข้ามาในเว็บไซต์ของ StatementPro โดยมีวิธีการดังนี้
                      </div>
                      <div class="mt-3 font-bold underline intro-x">
                        ตัวอย่าง ต้องการแก้ไขเลขที่บัญชี ของธนาคารกสิกร
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:450px">
                           <img alt="random image"  :src="imgFaq121" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        1. ไปที่เว็บไซต์ของ PEAK จากนั้นไปที่เมนู การเงิน > เงินสด/ธนาคาร/e-Wallet > ดูภาพรวม
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:650px">
                           <img alt="random image"  :src="imgFaq122" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        2. เลือกธนาคารที่ต้องการแก้ไขข้อมูล
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:490px">
                           <img alt="random image"  :src="imgFaq123" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        3. กดที่ตัวเลือก > แก้ไข
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:570px">
                           <img alt="random image"  :src="imgFaq124" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        4. จากนั้นแก้ไขเลขบัญชีที่ต้องการ แล้วกดปุ่ม “แก้ไขบัญชีธนาคาร”
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:550px">
                           <img alt="random image"  :src="imgFaq125" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        5. กลับไปที่หน้าเว็บไซต์ StatementPro ไปที่เมนู Bank Management แล้วกดปุ่ม Sync Bank Account
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:400px">
                           <img alt="random image"  :src="imgFaq126" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        6. จะพบว่าเลขบัญชีถูกแก้ไขเรียบร้อยแล้ว
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:400px">
                           <img alt="random image"  :src="imgFaq127" />
                        </div>
                      </div>
                      <div class="mt-3 font-bold underline intro-x">
                        ตัวอย่าง ต้องการแก้ไขธนาคารจาก ธนาคารกสิกร ไปเป็น ธนาคารไทยพาณิชย์
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:400px">
                           <img alt="random image"  :src="imgFaq128" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        1. ไปที่เว็บไซต์ของ PEAK จากนั้นไปที่เมนู การเงิน > เงินสด/ธนาคาร/e-Wallet > ดูภาพรวม
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:650px">
                           <img alt="random image"  :src="imgFaq129" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        2. เลือกธนาคารที่ต้องการแก้ไขข้อมูล
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:500px">
                           <img alt="random image"  :src="imgFaq1210" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        3. กดที่ตัวเลือก > แก้ไข
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:590px">
                           <img alt="random image"  :src="imgFaq1211" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        4. จากนั้นแก้ไขธนาคารที่ต้องการ แล้วกดปุ่ม “แก้ไขบัญชีธนาคาร”
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:550px">
                           <img alt="random image"  :src="imgFaq1212" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        5. กลับไปที่หน้าเว็บไซต์ StatementPro ไปที่เมนู Bank Management แล้วกดปุ่ม Sync Bank Account
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:410px">
                           <img alt="random image"  :src="imgFaq1213" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x ">
                        6. จะพบว่าธนาคารถูกแก้ไขเรียบร้อยแล้ว แต่จะพบว่า Account นี้จะต้องทำการ Set ข้อมูล Bank Account ใหม่ เนื่องจากมีการเปลี่ยนแปลงธนาคาร
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:400px">
                           <img alt="random image"  :src="imgFaq1214" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-13" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-13"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-13">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          13. หากถึงรอบการเปลี่ยนแปลงรหัสผ่านของธนาคารจะทราบได้อย่างไร
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-13"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-123"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        • ตามปกติแล้วธนาคารจะมีระยะเวลาที่กำหนดในการให้ลูกค้าเข้ามาเปลี่ยนแปลงรหัสผ่าน เช่น ทุกๆ 90 วันจะต้องมาเปลี่ยนหรือมี Email แจ้งเตือนจากธนาคารไปยังลูกค้า ฉะนั้นทาง Lightwork แนะนำให้เข้ามาเปลี่ยนรหัสผ่านทุก 60 วัน หรือตามที่ธนาคารกำหนด หากลูกค้าทราบว่าถึงเวลาที่จะต้องเปลี่ยนรหัสผ่านแล้ว ให้ดำเนินการเปลี่ยนแปลงก่อน หากไม่เข้ามาเปลี่ยน เมื่อระบบ StatementPro ทำงานแล้วพบว่ารหัสผ่านผิด จะไม่สามารถเข้าถึงหน้าเว็บไซต์ของธนาคารเพื่อดึงข้อมูลออกมาได้
                      </div>
                      <div class="mt-3 intro-x">
                        • ทั้งนี้ในตอนสร้างข้อมูล Login จะมีให้กรอก Password Date Reminder หากลูกค้าทราบว่า Password ของธนาคารนั้นๆ จะหมดอายุภายในวันใด ให้กรอกไว้ ระบบจะทำการแจ้งเตือนผ่านหน้าเว็บไซต์ StatementPro เพื่อให้ทราบว่าต้องทำการเข้ามาเปลี่ยน Password โดยจะแจ้งเตือนก่อนถึงวันที่บันทึกไว้ 7 วัน
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq13" />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-14" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-14"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-14">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          14. กรณีกรอกรหัสผ่านผิด สามารถแก้ไขเองได้อย่างไร
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-14"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-14"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                       <div class="intro-x">
                        <span>ลูกค้าสามารถเปลี่ยนแปลงรหัสผ่านเองได้ ตามหัวข้อที่ 11</span>
                       </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-15" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-15"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-15">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          15. หากต้องการยกเลิกหรือปิด Bank Account ชั่วคราว สามารถทำอย่างไรได้บ้าง
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-15"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-15"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        สามารถดำเนินการตามขั้นตอนดังนี้
                      </div>
                      <div class="mt-3 intro-x">
                        • เข้าไปที่เว็บไซต์ของระบบ StatementPro จากนั้นไปที่เมนู Bank Management
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq151" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        • จะเห็นว่าทุก Bank Account ในหน้านี้ Column: Status เป็น Inactive หมายถึง ยังไม่ให้ระบบ StatementPro ทำงานใน Bank Account นั้นๆ
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq152" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        • เมื่อต้องการตั้งค่าเปิด Bank Account ทำงานให้คลิกที่ปุ่ม จุด 3 จุดด้านหลัง แล้วกดปุ่ม Active
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq153" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        • Bank Account นั้นจะเปลี่ยน Status เป็น Active
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"   :src="imgFaq154" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        • เมื่อต้องการตั้งค่าปิด Bank Account ไม่ให้ทำงาน ให้คลิกที่ปุ่ม จุด 3 จุดด้านหลัง แล้วกดปุ่ม Inactive
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq155" />
                        </div>
                      </div>
                      <div class="mt-3 intro-x">
                        • Bank Account นั้นจะเปลี่ยน Status เป็น Inactive
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:470px">
                           <img alt="random image"  :src="imgFaq156" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-16" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-16"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-16">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          16. หากเว็บไซต์ของธนาคารปิด/ไม่สามารถเข้าได้ชั่วคราว ทำให้ไม่สามารถเชื่อมข้อมูลได้ ระบบ StatementPro จะสามารถดึงข้อมูลย้อนหลังเมื่อเว็บไซต์ของธนาคารกลับมาทำงานได้ตามปกติแล้วหรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-16"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-16"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        • ระบบ StatementPro จะดำเนินการดึงข้อมูล Statement ย้อนหลังกลับมาให้ เฉพาะกรณีที่เว็บของธนาคารปิด/มีการเปลี่ยนแปลง ในรอบถัดไป
                      </div>
                      <div class="mt-3 intro-x">
                        • หากเป็นปัญหาที่เกิดจาก Login ของลูกค้าเอง เช่น Username/Password ผิดจะไม่ทำการ ดึงข้อมูล Statement ย้อนหลังกลับมาให้ และลูกค้าจะต้องเข้ามาแก้ไข Password ให้ถูกต้องเอง เพื่อให้วันถัดไปสามารถกลับมา Log-in เข้าเว็บไซต์เพื่อดึงข้อมูลได้
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-17" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-17"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-17">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          17. ลูกค้าจะสามารถเลือกวันที่ในการ Download ข้อมูลจากเว็บไซต์ของธนาคารได้หรือไม่
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-17"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-17"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                        ไม่สามารถเลือกได้ ปัจจุบันระบบจะได้ข้อมูล Backdate - ดึงข้อมูลย้อนหลัง 1 วันเท่านั้น
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item intro-y">
                  <div id="faq-accordion-content-18" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-18"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-18">
                      <div class="flex flex-col sm:flex-row ">
                        <span>
                          18. ลูกค้าจะทราบได้อย่างไร หากไม่สามารถ Download ข้อมูลในบาง Bank Account ได้
                        </span>
                        <div class="flex w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0 " >
                          <ChevronUpIcon class="chevron-auto" />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-18"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-18"
                    data-bs-parent="#faq-accordion-1">
                    <div class="p-3 leading-relaxed text-white accordion-body dark:text-white">
                      <div class="intro-x">
                         สามารถตรวจสอบได้ที่หน้า Dashboard โดยจะมีสรุปของแต่ละวันว่ามี Account ใดบ้างที่ทำงาน Completed หรือ Account ใดบ้างที่ Error พร้อมทั้ง Error Message ว่าเกิดจากสาเหตุใด
                      </div>
                      <div class="mt-3 intro-x">
                        <div class="thumbnail" style="height:500px">
                           <img alt="random image"  :src="imgFaq18"  />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import imgFaq91 from '@/assets/faq/provider/9-1.png'
import imgFaq92 from '@/assets/faq/provider/9-2.png'
import imgFaq93 from '@/assets/faq/provider/9-3.png'
import imgFaq94 from '@/assets/faq/provider/9-4.png'
import imgFaq95 from '@/assets/faq/provider/9-5.png'
import imgFaq96 from '@/assets/faq/provider/9-6.png'
import imgFaq97 from '@/assets/faq/provider/9-7.png'
import imgFaq98 from '@/assets/faq/provider/9-8.png'
import imgFaq99 from '@/assets/faq/provider/9-9.png'
import imgFaq910 from '@/assets/faq/provider/9-10.png'
import imgFaq101 from '@/assets/faq/provider/10-1.png'
import imgFaq102 from '@/assets/faq/provider/10-2.png'
import imgFaq103 from '@/assets/faq/provider/10-3.png'
import imgFaq104 from '@/assets/faq/provider/10-4.png'
import imgFaq105 from '@/assets/faq/provider/10-5.png'
import imgFaq106 from '@/assets/faq/provider/10-6.png'
import imgFaq111 from '@/assets/faq/provider/11-1.png'
import imgFaq112 from '@/assets/faq/provider/11-2.png'
import imgFaq113 from '@/assets/faq/provider/11-3.png'
import imgFaq121 from '@/assets/faq/provider/12-1.png'
import imgFaq122 from '@/assets/faq/provider/12-2.png'
import imgFaq123 from '@/assets/faq/provider/12-3.png'
import imgFaq124 from '@/assets/faq/provider/12-4.png'
import imgFaq125 from '@/assets/faq/provider/12-5.png'
import imgFaq126 from '@/assets/faq/provider/12-6.png'
import imgFaq127 from '@/assets/faq/provider/12-7.png'
import imgFaq128 from '@/assets/faq/provider/12-8.png'
import imgFaq129 from '@/assets/faq/provider/12-9.png'
import imgFaq1210 from '@/assets/faq/provider/12-10.png'
import imgFaq1211 from '@/assets/faq/provider/12-11.png'
import imgFaq1212 from '@/assets/faq/provider/12-12.png'
import imgFaq1213 from '@/assets/faq/provider/12-13.png'
import imgFaq1214 from '@/assets/faq/provider/12-14.png'
import imgFaq13 from '@/assets/faq/provider/13.png'
import imgFaq151 from '@/assets/faq/provider/15-1.png'
import imgFaq152 from '@/assets/faq/provider/15-2.png'
import imgFaq153 from '@/assets/faq/provider/15-3.png'
import imgFaq154 from '@/assets/faq/provider/15-4.png'
import imgFaq155 from '@/assets/faq/provider/15-5.png'
import imgFaq156 from '@/assets/faq/provider/15-6.png'
import imgFaq18 from '@/assets/faq/provider/18.png'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgFaq91,
      imgFaq92,
      imgFaq93,
      imgFaq94,
      imgFaq95,
      imgFaq96,
      imgFaq97,
      imgFaq98,
      imgFaq99,
      imgFaq910,
      imgFaq101,
      imgFaq102,
      imgFaq103,
      imgFaq104,
      imgFaq105,
      imgFaq106,
      imgFaq111,
      imgFaq112,
      imgFaq113,
      imgFaq121,
      imgFaq122,
      imgFaq123,
      imgFaq124,
      imgFaq125,
      imgFaq126,
      imgFaq127,
      imgFaq128,
      imgFaq129,
      imgFaq1210,
      imgFaq1211,
      imgFaq1212,
      imgFaq1213,
      imgFaq1214,
      imgFaq13,
      imgFaq151,
      imgFaq152,
      imgFaq153,
      imgFaq154,
      imgFaq155,
      imgFaq156,
      imgFaq18,
      listBank: [],
      listLightworkData: []
    }
  },
  methods: {
    initializeBank() {
      this.listBank.push({
        bank: 'ธนาคารกรุงศรีอยุธยา (BAY)',
        site: 'Krungsri BizOnline'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงศรีอยุธยา (BAY)',
        site: 'Krungsri CashLink'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงเทพ (BBL)',
        site: 'Biz iBanking'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงเทพ (BBL)',
        site: 'Corporate iCash'
      })
      this.listBank.push({
        bank: 'ธนาคารกสิกรไทย (KBANK)',
        site: 'K-BIZ'
      })
      this.listBank.push({
        bank: 'ธนาคารกสิกรไทย (KBANK)',
        site: 'K-Cash Connect Plus'
      })
      this.listBank.push({
        bank: 'ธนาคารกรุงไทย (KTB)',
        site: 'Krungthai Corporate Online (เว็บไซต์รูปแบบเดิม)'
      })
      this.listBank.push({
        bank: 'ธนาคารไทยพาณิชย์ (SCB)',
        site: 'Business Net'
      })
      this.listBank.push({
        bank: 'ธนาคารทหารไทยธนชาต (TTB)',
        site: 'Business One'
      })
    },
    initializeLightworkData() {
      this.listLightworkData.push({
        id: 1,
        text: 'ชื่อธนาคาร/ชื่อเว็บไซต์ธนาคาร'
      })
      this.listLightworkData.push({
        id: 2,
        text: 'เลขบัญชีธนาคาร'
      })
      this.listLightworkData.push({
        id: 3,
        text: 'Company Name (ถ้ามีเพื่อใช้เลือกในหน้าเว็บไซต์ธนาคาร)'
      })
      this.listLightworkData.push({
        id: 4,
        text: 'Username (สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
      this.listLightworkData.push({
        id: 5,
        text: 'Password (สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
      this.listLightworkData.push({
        id: 6,
        text: 'Company ID (ถ้ามี สำหรับใช้ Login เข้าเว็บไซต์ของธนาคาร)'
      })
    }
  },
  created() {
    this.initializeBank()
    this.initializeLightworkData()
  }
})
</script>
