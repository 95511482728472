import cash from 'cash-dom'
import TomSelect from 'tom-select'

const setValue = (el, props) => {
  if (props.modelValue) {
    cash(el).val(props.modelValue)
  }
}

const init = (el, emit, computedOptions) => {
  el.TomSelect = new TomSelect(el, {
    ...computedOptions,
    optionClass: 'option',
    itemClass: 'item',
    disabledField: null,
    render: {
      option: function(data, escape) {
        if (data.text.indexOf('|||')) {
          const strSpl = data.text.split('|||')
          return `<div><i class="${escape(strSpl[1])} mr-2"></i>  ${escape(strSpl[0])}</div>`
        } else {
          return '<div>' + escape(data.text) + '</div>'
        }
      },
      item: function(data, escape) {
        if (data.text.indexOf('|||')) {
          const strSpl = data.text.split('|||')
          return `<div><i class="${escape(strSpl[1])} mr-2"></i>  ${escape(strSpl[0])}</div>`
        } else {
          return '<div>' + escape(data.text) + '</div>'
        }
      }
    }
  })
  el.TomSelect.on('change', function(selectedItems) {
    emit('update:modelValue', selectedItems)
  })
}

const reInit = (el, props, emit, computedOptions) => {
  el.TomSelect.destroy()
  cash(el).html(
    cash(el)
      .prev()
      .html()
  )
  setValue(el, props)
  init(el, emit, computedOptions)
}

export { setValue, init, reInit }
