<template>
  <div>
    <details class="tree-nav__item is-expandable" :open="isOpen" :class="source.length > 0 ? 'show-icon' : 'non-icon'">
      <summary class="tree-nav__item-title">
        <div class="flex " style="overflow-wrap: anywhere;">
          <input v-if="isCheckbox"  :indeterminate="isIndeterminate" :checked="checkValue" type="checkbox" ref="root" class="form-check-input"
          @change="onCheckboxChanged($event)" />
          <label class="ml-2">
            {{rootName}}
          </label>
          <label v-if="isShowSelect" class="ml-2">
            {{getSelectNumber}}
          </label>
        </div>
      </summary>
      <Tree-View-Item ref="childComponent" :children="source" :isOpen="isOpen" :isCheckbox="isCheckbox" @child-checkbox-change="onChildCheckBoxChanged($event)" >
       <template v-slot:content="{ values }">
          <slot name="content" :values="values"></slot>
       </template>
      </Tree-View-Item>
    </details>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isCheckbox: {
      type: Boolean,
      default: false
    },
    isIndeterminate: {
      type: Boolean,
      default: false
    },
    rootName: {
      type: String,
      default: 'TreeView'
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    isShowSelect: {
      type: Boolean,
      default: true
    },
    checkValue: {
      type: Boolean,
      default: false
    },
    source: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'update:checkValue',
    'update:isIndeterminate'
  ],
  data() {
    return {
      sourceCount: 0
    }
  },
  computed: {
    getSelectNumber() {
      if (this.source.length > 0) {
        const temp = this.findSelectItem(this.source)
        return '(' + temp.selects + '/' + temp.count + ')'
      } else {
        return '(0)'
      }
    }
  },
  methods: {
    findSelectItem(obj) {
      const result = {
        selects: 0,
        count: 0
      }
      obj.forEach(_ => {
        if (_.children?.length > 0) {
          const temp = this.findSelectItem(_.children)
          result.selects += temp.selects
          result.count += temp.count
        } else {
          if (_.checkboxValue === true) {
            result.selects += 1
          }
          result.count += 1
        }
      })
      return result
    },
    onChildCheckBoxChanged(obj) {
      if (obj) {
        const finds = this.source.filter(_ => _.checkboxValue === true)
        this.$emit('update:checkValue', false)
        this.$emit('update:isIndeterminate', obj.isIndeterminate)
        if (finds.length === this.source.length) {
          this.$emit('update:checkValue', true)
        } else if (finds.length === 0) {
          this.$emit('update:checkValue', false)
        } else {
          this.$emit('update:isIndeterminate', true)
        }
      } else {
        this.$emit('update:isIndeterminate', false)
      }
    },
    onCheckboxChanged(event) {
      this.$emit('update:isIndeterminate', false)
      this.$emit('update:checkValue', event.target.checked)
      this.$refs.childComponent.updateCheckbox(event.currentTarget.checked)
    },
    updateCheckValue(val) {
      this.$emit('update:isIndeterminate', false)
      this.$emit('update:checkValue', val)
      this.$refs.childComponent.updateCheckbox(val)
    },
    updateCheckValueChild(item) {
      this.$refs.childComponent.updateCheckValueChild(item)
    }
  }
})
</script>
