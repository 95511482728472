import moment from 'moment'
// import { createApp } from 'vue'
import Toastify from 'toastify-js'
import { mount } from 'mount-vue-component'

export default app => {
  app.config.globalProperties.$filters = {
    formatDateTime(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss')
      }
      return '-'
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).format('HH:mm:ss')
      }
      return '-'
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
      return '-'
    },
    formatDateRe(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
      return '-'
    },
    formatDateOut(value) {
      if (value) {
        return new Date(value.getFullYear(), value.getMonth(), value.getDate())
      }
      return null
    },
    dateDiff(first, second) {
      if (first && second) {
        const start = moment(first)
        const end = moment(second)
        return end.diff(start, 'days')
      }
      return 0
    },
    getDatesInRange(startDate, endDate) {
      if (startDate === endDate) {
        return [startDate]
      } else {
        let dates = []
        // to avoid modifying the original date
        const theDate = new Date(startDate)
        while (theDate < new Date(endDate)) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, new Date(endDate)]

        return dates.map(_ => this.formatDate(_))
      }
    },
    formatDateTimeToServer(value) {
      if (value) {
        return moment.utc(new Date(value)).format()
      }
      return '-'
    },
    formatDateTimeToClient(value) {
      if (value) {
        return moment(new Date(value)).format()
      }
      return '-'
    },
    formatNonUtcDateTime(value) {
      if (value) {
        const minDate = moment.utc('0001-01-16 00:00:00') // minimum value as per UTC

        if (!moment(value).utc().isAfter(minDate)) {
          return 'TBA'
        }
        return moment(String(value)).format('DD MMM YYYY (HH:mm)')
      }
      return 'TBA'
    },
    displayGender(value) {
      if (value === 0) {
        return 'Female'
      } if (value === 1) {
        return 'Male'
      }
      return 'Not specified'
    },
    emptyDisplayDat(value) {
      if (!value || value === null || value === '') {
        return '-'
      }
      return value
    },
    numberWithComma(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0.00'
    },
    number2DigitWithComma(value) {
      if (value) {
        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0.00'
    },
    numberWithCommaAndWithoutDigit(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0'
    },
    number0DigitWithComma(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0'
    },
    shortNumber2DigitWithComma(value) {
      let data = '0'
      let postfix = ''
      if (!value) {
        return data
      }
      const positiveNumber = value * (value < 0 ? -1 : 1)
      if (positiveNumber >= 1000000) {
        data = (value / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        postfix = 'M'
      } else if (positiveNumber >= 10000) {
        data = (value / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        postfix = 'K'
      } else {
        data = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        postfix = ''
      }
      return `${data}${postfix}`
    },
    showShortRonin(value) {
      if (value) {
        return `${value.substring(0, 5)}...${value.substring(value.length - 3, value.length)}`
      }
      return '-'
    },
    displaySlpPriceByCurrency(value, currency = undefined) {
      if (!currency && localStorage.getItem('currentCurrency')) {
        const current = JSON.parse(localStorage.getItem('currentCurrency'))
        const price = parseFloat(current.slpPrice || 1)
        return `${current.currencySymbol}${this.number2DigitWithComma((value * price))}`
      } else if (currency) {
        const current = JSON.parse(currency)
        const price = parseFloat(current.slpPrice || 1)
        return `${current.currencySymbol}${this.number2DigitWithComma((value * price))}`
      } else {
        return `SLP${this.number2DigitWithComma(value)}`
      }
    },
    diffFromToday(date) {
      if (!date) {
        return 'TBA'
      }

      var minDate = moment.utc('0001-01-16 00:00:00') // minimum value as per UTC
      if (!moment(date).utc().isAfter(minDate)) {
        return 'TBA'
      }

      const mins = moment(date).diff(moment(), 'minutes')
      const duration = moment.duration(mins, 'minutes')
      const days = duration.days()
      const hours = duration.hours()
      if (days < 0) {
        return `${(days * -1)} days ago`
      }
      if (days === 0) {
        return 'today!!'
      } else if (days === 1) {
        return '1 day'
      }
      return `${days} days ${hours} hrs`
    }
  }
  app.config.globalProperties.$helpers = {
    generateUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    isNull(val) {
      return val === null || val === undefined || val === ''
    },
    showToastifyResponseError(res) {
      this.showToastify(`Error ${res.statusCode}`, res.message, true)
    },
    showToastify(title, body, iserror) {
      const { el } = mount(app.component('ToasitfyAlert'), {
        props: {
          title: title,
          body: body,
          iserror: iserror
        }
      })
      Toastify({
        node: el,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
  }
}
