<template>
  <div class="modal" data-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl" v-if="isShow" style="width: 550px;">
      <div class="modal-content">
        <a data-dismiss="modal" class="hidden" ref="elClose" href="javascript:;" :disabled="isUploading" @click="onCloseClicked">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body">
          <div class="grid items-center justify-center grid-flow-col mt-5 auto-cols-max intro-y"
            style="padding-left:20px;padding-right:20px">
            <h2 class="mr-auto text-base font-medium"
              style="font-size: 1.8rem;font-weight: 500;margin-bottom: 0.3125rem;"> Upload Profile Image</h2>
          </div>
          <div class="grid items-center justify-center grid-flow-col mt-5 auto-cols-max intro-y"
            style="padding-left:20px;padding-right:20px">
            <div>
              <div class="flex intro-y">
                <div class="mr-2">
                  <h2 class="mr-auto text-base font-medium drop-zoon__icon"> File should be an image like :</h2>
                </div>
                <div>
                  <h2 class="mr-auto text-base font-medium drop-zoon__icon">{{ imagesTypes.join(', .') }}</h2>
                </div>
              </div>
              <div class="flex intro-y">
                <div class="mr-2">
                  <h2 class="mr-auto text-base font-medium drop-zoon__icon"> File size limit :</h2>
                </div>
                <div>
                  <h2 class="mr-auto text-base font-medium drop-zoon__icon">{{ (fileSizeLimit / 1000 / 1000).toFixed(2) }}
                    MB</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center -mt-3 upload-area intro-y">
            <!-- Drop Zoon -->
            <div ref="dropZoon" class="upload-area__drop-zoon drop-zoon w-96 border-theme-6"
              @dragover="onDropZoonDragOvered($event)" @dragleave="onDropZoonDragLeaved($event)"
              @drop="onDropZoonDropped($event)" @click="onDropZoonClicked($event)">
              <div v-if="$helpers.isNull(fileInfo.image) && !isFileLoading" class="drop-zoon__icon">
                <ImageIcon class="block w-16 h-16 mx-auto" />
              </div>
              <p v-if="$helpers.isNull(fileInfo.image) && !isFileLoading" class="drop-zoon__paragraph">Drop your file
                here or Click to browse</p>
              <div class="flex intro-y " v-if="isFileLoading">
                <LoadingIcon icon="oval" class="w-5 h-5 drop-zoon__icon " />
                <span class="ml-2 drop-zoon__loading-text">Please Wait ...</span>
              </div>
              <div v-if="!$helpers.isNull(fileInfo.image) && !isFileLoading" class="drop-zoon__preview-image intro-y">
                <img :src="fileInfo.image" alt="Preview Image" class="w-40 h-40 rounded-full" draggable="false">
              </div>
              <input type="file" @change="onFileInputChanged($event)" ref="fileInput" class="drop-zoon__file-input"
                accept="image/*">
            </div>
            <!-- End Drop Zoon -->
          </div>
          <div v-if="!$helpers.isNull(this.fileInfo.image) && !isFileLoading" class="mt-5 intro-y"
            :class="{ 'upload-area--open drop-zoon--Uploaded': !$helpers.isNull(this.fileInfo.image) && !isFileLoading }">
            <div class="grid items-center justify-center grid-flow-col intro-x auto-cols-max"
              style="padding-left:20px;padding-right:20px">
              <div style="max-width: 350px;">
                <div class="flex intro-x">
                  <div class="mr-2">
                    <h2 class="mr-auto text-base font-medium">Name :</h2>
                  </div>
                  <div>
                    <h2 class="mr-auto text-base font-medium">{{ fileInfo.name }}</h2>
                  </div>
                </div>
                <div class="flex intro-x">
                  <div class="mr-2">
                    <h2 class="mr-auto text-base font-medium">Extension :</h2>
                  </div>
                  <div>
                    <h2 class="mr-auto text-base font-medium">{{ fileInfo.extension ?? '-' }}</h2>
                  </div>
                </div>
                <div class="flex intro-x">
                  <div class="mr-2">
                    <h2 class="mr-auto text-base font-medium">Size :</h2>
                  </div>
                  <div>
                    <h2 class="mr-auto text-base font-medium">{{ getFileSize }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="justify-end mt-7 intro-y" style="padding-left:20px;padding-right:20px">
            <div class="text-center border-t border-gray-200 dark:border-dark-5" style="padding: 16px;">
              <div class="flex mt-1 mb-4 -ml-4 text-left text-red-400 intro-x" v-if="!$helpers.isNull(errorMessage)">
                <img :src="imgError" class="w-6 h-6 mr-2 " />
                <span>{{ errorMessage }}</span>
              </div>
              <div class="flex justify-center">

                <button :disabled="isUploading" type="button" data-dismiss="modal" @click="onCloseClicked"
                class="h-10 mr-2 text-right btn btn-outline-secondary dark:border-dark-5 dark:text-gray-300">
                Cancel
              </button>
              <button @click="onUploadClicked"
              :disabled="isUploading || isFileLoading || $helpers.isNull(this.fileInfo.data)"
              type="button" class="h-10 mr-1 text-right btn btn-primary">
              <LoadingIcon v-if="isUploading" icon="oval" class="w-6 h-6 mr-2 text-center" />
              {{ isUploading ? 'Uploading' : 'Upload' }}
            </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import imgError from '@/assets/images/Error32.png'
import { cloudService } from '@/services'

export default defineComponent({
  data: () => {
    return ({
      imgError,
      fileSizeLimit: 4194304,
      isShow: false,
      errorMessage: null,
      isUploading: false,
      refData: null,
      imagesTypes: [],
      isFileLoading: false,
      fileInfo: {
        data: null,
        image: null,
        size: null,
        name: null,
        extension: null
      }
    })
  },
  emits: ['update'],
  methods: {
    open(data) {
      this.refData = { ...data }
      this.isShow = true
    },
    async onCloseClicked() {
      await this.delay(500)
      this.isShow = false
      this.isFileLoading = false
      this.isUploading = false
      this.fileInfo = {
        data: null,
        image: null,
        size: null,
        name: null,
        extension: null
      }
      this.refData = null
    },
    async onFileInputChanged(event) {
      this.errorMessage = null
      // Select The Chosen File
      if (event.target.files.length > 0) {
        this.fileInfo.data = event.target.files[0]
        // Call Function InitializeImage(), And Send To Her The Chosen File :)
        await this.InitializeImage()
      }
    },
    onDropZoonDragOvered(event) {
      if (this.isFileLoading || this.isUploading) {
        return
      }
      // Prevent Default Behavior
      event.preventDefault()
      // Add Class (drop-zoon--over) On (drop-zoon)
      this.$refs.dropZoon.classList.add('drop-zoon--over')
    },
    onDropZoonDragLeaved(event) {
      // Remove Class (drop-zoon--over) from (drop-zoon)
      this.$refs.dropZoon.classList.remove('drop-zoon--over')
    },
    async onDropZoonDropped(event) {
      this.errorMessage = null
      if (this.isFileLoading || this.isUploading) {
        return
      }
      // Prevent Default Behavior
      event.preventDefault()
      // Remove Class (drop-zoon--over) from (drop-zoon)
      this.$refs.dropZoon.classList.remove('drop-zoon--over')
      // Select The Dropped File
      this.fileInfo.data = event.dataTransfer.files[0]
      // Call Function InitializeImage(), And Send To Her The Dropped File :)
      await this.InitializeImage()
    },
    onDropZoonClicked(event) {
      if (this.isFileLoading || this.isUploading) {
        return
      }
      // Click The (fileInput)
      this.$refs.fileInput.click()
    },
    isFileValidate(fileType, fileSize) {
      // File Type Validation
      const isImage = this.imagesTypes.filter((type) => fileType.indexOf(`image/${type}`) !== -1)

      // If The Uploaded File Is An Image
      if (isImage.length !== 0) {
        // Check, If File Size Is 4MB or Less
        if (fileSize <= this.fileSizeLimit) { // 4MB :)
          return true
        } else { // Else File Size
          this.errorMessage = 'Please Your File Should be 4 Megabytes or Less'
          return false
        };
      } else { // Else File Type
        this.errorMessage = 'Please make sure to upload An Image File Type'
        return false
      };
    },
    async InitializeImage() {
      this.isFileLoading = true
      await this.delay(2000)
      // FileReader()
      const fileReader = new FileReader()
      // File Type
      const fileType = this.fileInfo.data.type
      // File Size
      const fileSize = this.fileInfo.data.size
      // If File Is Passed from the (File Validation) Function
      if (this.isFileValidate(fileType, fileSize)) {
        // After File Reader Loaded
        fileReader.onload = (e) => {
          // Regular expression for file extension.
          this.fileInfo.extension = this.fileInfo.data.name.split('.').pop()
          this.fileInfo.size = fileSize
          // Add The (fileReader) Result Inside (previewImage) Source
          this.fileInfo.image = fileReader.result
          // Add File Name Inside Uploaded File Name
          this.fileInfo.name = this.fileInfo.data.name.split('.')[0]
          this.isFileLoading = false
        }

        // Read (file) As Data Url
        fileReader.readAsDataURL(this.fileInfo.data)
      } else { // Else
        this.isFileLoading = false
        this.fileInfo = {
          data: null,
          image: null,
          size: null,
          name: null,
          extension: null
        }
      };
    },
    async onUploadClicked() {
      try {
        this.errorMessage = null
        this.isUploading = true
        const payload = new FormData()
        payload.append('file', this.fileInfo.data)
        const res = await cloudService.uploadImageProfile(payload)
        if (this.$helpers.isNull(res.statusCode)) {
          this.$emit('update')
          this.$helpers.showToastify('Profile image updated successfully.')
          this.$refs.elClose.click()
        } else {
          this.errorMessage = res.message
          this.isUploading = false
        }
      } catch (err) {
        this.errorMessage = err.message
        this.isUploading = false
      }
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  },
  created() {
    this.imagesTypes = [
      'jpeg',
      'bmp',
      'png',
      'svg',
      'gif'
    ]
  },
  computed: {
    getFileSize() {
      if (this.fileInfo.size < 1000000) {
        return (this.fileInfo.size / 1000).toFixed(2) + ' KB'
      } else {
        return (this.fileInfo.size / 1000 / 1000).toFixed(2) + ' MB'
      }
    }
  }
})
</script>
