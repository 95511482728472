<template>
  <div >
    <div v-if="type === 1" class="radio" :class="modelValue ? '':'off'" >
      <div class="wrap">
        <input type="radio" id="on" name="radio" @click="onRadioOnClicked($event)">
        <label for="on">{{labelOn}}</label>
        <input type="radio" id="off" name="radio" @click="onRadioOffClicked($event)">
        <label for="off">{{labelOff}}</label>
        <div class="bar"></div>
      </div>
    </div>
    <div v-if="type === 2" ref="checkbox" class="checkbox">
      <input type="checkbox" id="checkbox" checked name="checkbox">
      <label ref="checkboxLabel" for="checkbox" @click="onCheckboxLabelClicked($event)"></label>
      <div ref="checkboxOn" class="on"><span>{{labelOn}}</span></div>
      <div ref="checkboxOff" class="off"><span>{{labelOff}}</span></div>
    </div>
    <div v-if="type === 3" ref="switcher" class="switcher">
      <input type="checkbox" id="switcher" name="switcher">
      <label for="switcher" @click="onSwitcherLabelClicked($event)"></label>
      <span ref="switcherRight" class="left"></span>
      <span ref="switcherLeft" class="right"></span>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue'
import anime from 'animejs'

export default defineComponent({
  props: {
    labelOn: {
      type: String,
      default: 'On'
    },
    labelOff: {
      type: String,
      default: 'Off'
    },
    type: {
      type: Number,
      default: 1
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      zIndex: 2
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  mounted() {
    switch (this.type) {
      case 2:
        anime({
          targets: this.$refs.checkboxOn,
          translateX: '0',
          zIndex: {
            value: [1, 2],
            round: true
          },
          duration: 0
        })
        anime({
          targets: this.$refs.checkboxOff,
          translateX: '-100%',
          zIndex: {
            value: [2, 1],
            round: true
          },
          duration: 0
        })
        break
    }
  },
  methods: {
    onRadioOffClicked(event) {
      this.$emit('update:modelValue', false)
    },
    onRadioOnClicked(event) {
      this.$emit('update:modelValue', true)
    },
    onCheckboxLabelClicked(event) {
      const checkbox = this.$refs.checkbox
      const checkboxOn = this.$refs.checkboxOn
      const checkboxOff = this.$refs.checkboxOff
      if (this.modelValue) {
        checkbox.classList.toggle('active')
        this.$emit('update:modelValue', false)
        if (checkbox.classList.contains('active')) {
          checkbox.setAttribute('checked', false)
          anime({
            targets: checkboxOff,
            zIndex: {
              value: [1, 2],
              round: true
            },
            duration: 0
          })
          anime({
            targets: checkboxOn,
            zIndex: {
              value: [2, 1],
              round: true
            },
            duration: 0
          })
          anime({
            targets: checkboxOff,
            translateX: '0%',
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => {
              anime({
                targets: checkboxOn,
                translateX: '100%',
                duration: 0
              })
              this.$emit('update:modelValue', true)
            }
          })
        } else {
          checkbox.setAttribute('checked', true)
          anime({
            targets: checkboxOn,
            zIndex: {
              value: [1, 2],
              round: true
            },
            duration: 0
          })
          anime({
            targets: checkboxOff,
            zIndex: {
              value: [2, 1],
              round: true
            },
            duration: 0
          })
          anime({
            targets: checkboxOn,
            translateX: '0%',
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => {
              anime({
                targets: checkboxOff,
                translateX: '-100%',
                duration: 0
              })
              this.$emit('update:modelValue', true)
            }
          })
        }
      }
    },
    onSwitcherLabelClicked(event) {
      const switcher = this.$refs.switcher
      const switcherLeft = this.$refs.switcherLeft
      const switcherRight = this.$refs.switcherRight
      if (this.modelValue) {
        this.$emit('update:modelValue', false)
        switcher.classList.toggle('active')
        this.zIndex++
        if (switcher.classList.contains('active')) {
          anime({
            targets: switcherLeft,
            scale: 12,
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => {
              anime({
                targets: switcherRight,
                scale: 0,
                zIndex: {
                  value: [this.zIndex, this.zIndex++],
                  round: true
                },
                duration: 0
              })

              anime({
                targets: switcherRight,
                scale: 1,
                duration: 700,
                complete: () => {
                  this.$emit('update:modelValue', true)
                }
              })
            }
          })
        } else {
          anime({
            targets: switcherRight,
            scale: 12,
            duration: 500,
            easing: 'easeInOutQuad',
            complete: () => {
              anime({
                targets: switcherLeft,
                scale: 0,
                zIndex: {
                  value: [this.zIndex, this.zIndex++],
                  round: true
                },
                duration: 0
              })

              anime({
                targets: switcherLeft,
                scale: 1,
                duration: 700,
                complete: () => {
                  this.$emit('update:modelValue', true)
                }
              })
            }
          })
        }
      }
    }
  }
})
</script>
