<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link :to="{ name: 'dashboard' }" class="flex mr-auto">
        <img alt="lw" class="w-16" :src="imgLogo" />
      </router-link>
      <BarChart2Icon class="w-8 h-8 text-white transform -rotate-90" @click="toggleMobileMenu" />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="py-5 border-t border-theme-29">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li v-if="menu == 'devider'" :key="menu + menuKey" class="my-6 menu__devider"></li>
          <li v-else :key="menuKey">
            <a href="javascript:;" class="menu" :class="{
              'menu--active': menu.active,
              'menu--open': menu.activeDropdown
            }" @click="linkTo(menu, router)">
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div v-if="menu.subMenu" class="menu__sub-icon" :class="{ 'transform rotate-180': menu.activeDropdown }">
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                  <a href="javascript:;" class="menu" :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu, router)">
                    <div class="menu__icon">
                      <ActivityIcon />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div v-if="subMenu.subMenu" class="menu__sub-icon" :class="{
                        'transform rotate-180': subMenu.activeDropdown
                      }">
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <li v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu" :key="lastSubMenuKey">
                        <a href="javascript:;" class="menu" :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)">
                          <div class="menu__icon">
                            <ZapIcon />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import * as getterTypes from '@/store/getter-types'
import { helper as $h } from '@/utils/helper'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from './index'
import { nestedMenu } from '@/layouts/side-menu'

export default defineComponent({
  watch: {
    darkMode(newVal, oldVal) {
      this.changeLogo()
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const imgLogo = ref(null)
    const darkMode = computed(() => store.getters[`main/${getterTypes.GET_DARK_MODE}`])
    const userProvider = computed(() => store.getters[`main/${getterTypes.GET_PROVIDER}`])
    const mobileMenu = computed(() =>
      nestedMenu(store.getters[`sideMenu/${getterTypes.GET_MENU}`], route)
    )
    const getDarkName = () => darkMode.value ? '-dark' : ''
    const changeLogo = () => {
      switch (userProvider?.value?.name) {
        case 'PEAK':
          imgLogo.value = require(`@/assets/images/lw-logo-peak${getDarkName()}.png`)
          break
        default:
          imgLogo.value = require(`@/assets/images/lw-logo-lightwork${getDarkName()}.png`)
          break
      }
    }
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value)
      changeLogo()
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      changeLogo,
      getDarkName,
      darkMode,
      imgLogo,
      enter,
      leave
    }
  }
})
</script>
