<template>
  <div>
    <div class="intro-y flex items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Download Statement</h2>
    </div>
    <div class="intro-y box px-5 py-5 mt-5 ">
      <div class="intro-y ">
        <div class="flex grid-flow-col auto-cols-max">
          <div class="mr-5" style="min-width:100px;">
            <label class="typo__label">Type</label>
          </div>
          <div class="mr-5 w-96">
            <div class="flex flex-col sm:flex-row ml-1 ">
              <div class="form-check mr-3" v-for="(item, index) in listBankStatementType" :key="index">
                <input :disabled="isSearching" v-model="selectBankStatementTypes" :id="'checkbox-switch-' + index"
                  type="checkbox" class="form-check-input" :value="item.id" />
                <label class="form-check-label" :for="'radio-switch-' + index">{{ item.name }}</label>
              </div>
            </div>
            <div class="mt-2 ml-1 text-theme-6" v-if="!validate.isSelectBankStatementTypes">This field is required</div>
          </div>
        </div>
      </div>
      <div class="intro-y mt-5">
        <div class="flex grid-flow-col auto-cols-max">
          <div class="mr-5 mt-2" style="min-width:100px;">
            <label class="typo__label ">Bank</label>
          </div>
          <div class="mr-5 w-96">
            <Multiselect :disabled="isSearching" v-model="selectBanks" mode="multiple" :close-on-select="false"
              :groups="true" placeholder="Select Banks" valueProp="id" trackBy="id" :options="listBank">
              <template v-slot:multiplelabel="{ values }">
                <div class="multiselect-multiple-label">
                  {{ values.length }} banks selected
                </div>
              </template>
              <template v-slot:option="{ option }">
                <div class="flex">
                  <i style="width: 24px;height:24px" class="mr-2" :class="option.iconClass" />
                  <label>{{ option.bankShortName }} </label>
                </div>
              </template>
            </Multiselect>
            <div v-if="!validate.isSelectBanks" class="mt-2 ml-1 text-theme-6">This field is required</div>
          </div>
        </div>
        <div style="margin-left:125px;" class="col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-3"
          v-if="selectBanks?.length > 0">
          <div class="grid grid-cols-12 gap-6 ">
            <div v-for="(item, key) in selectBanks" :key="key" style="padding-right: 40px;"
              :class="isSearching ? 'is-disabled' : ''"
              class="col-span-12 md:col-span-3  mr-2 alert alert-secondary-soft p-3 alert-dismissible show flex items-center"
              role="alert">
              <div class="flex">
                <i style="width: 24px;height:24px" class="mr-2" :class="findBankById(item).iconClass" />
                <label>{{ findBankById(item)?.bankGroupShortNameEn }} : {{ findBankById(item)?.bankShortName }} </label>
              </div>
              <button :disabled="isSearching" type="button"
                style="position: absolute; right: 0px;margin-top: -2px;margin-bottom: auto;margin-right: 0.5rem; border-width: 0px;"
                data-bs-dismiss="alert" aria-label="Close" @click="onBankCloseClicked(item)">
                <XIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y mt-4">
        <div class="flex grid-flow-col auto-cols-max">
          <div class="mr-5 mt-2" style="min-width:100px;">
            <label class="typo__label">Working Date</label>
          </div>
          <div class="mr-5 w-96 ">
            <Multiselect :disabled="isSearching" v-model="selectWorkingDates" :close-on-select="false" mode="multiple"
              valueProp="name" placeholder="Select Working Dates" trackBy="name" label="name" :options="listWorkingDate">
              <template v-slot:multiplelabel="{ values }">
                <div class="multiselect-multiple-label">
                  {{ values?.length }} dates selected
                </div>
              </template>
            </Multiselect>
            <div class="mt-2 ml-1 text-theme-6" v-if="!validate.isSelectWorkingDates">This field is required</div>
          </div>
        </div>
        <div class="flex grid-flow-col auto-cols-max items-center mt-3" v-if="selectWorkingDates?.length > 0">
          <div style="min-width:125px;" />
          <div class="flex flex-col sm:flex-row ">
            <div v-for="(item, key) in selectWorkingDates" :key="key" style="padding-right: 40px;"
              :class="isSearching ? 'is-disabled' : ''"
              class="h-auto mr-2 alert alert-secondary-soft p-3  alert-dismissible show flex items-center" role="alert">
              {{ item }}
              <button :disabled="isSearching" :style="isSearching ? 'no-drop' : ''"
                style="position: absolute; right: 0px;margin-top: -2px;margin-bottom: auto;margin-right: 0.5rem; border-width: 0px;"
                type="button" data-bs-dismiss="alert" aria-label="Close" @click="onWorkingDateCloseClicked(item)">
                <XIcon class="w-4 h-4" :style="isSearching ? 'no-drop' : ''" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y mt-4">
        <div class=" grid-flow-col flex auto-cols-max ">
          <div class="mr-5 " style="min-width:100px;">
            <label class="typo__label">File</label>
          </div>
          <div class="mr-5 ">
            <div class="flex flex-col sm:flex-row ml-1">
              <div class="form-check mr-3" v-for="(item, index) in listFileType" :key="index">
                <input :disabled="isSearching" v-model="selectFileTypes" :id="'checkbox-switch-' + index" type="checkbox"
                  class="form-check-input" :value="item.id" />
                <label class="form-check-label" :for="'radio-switch-' + index">{{ item.name }}</label>
              </div>
            </div>
            <div class="mt-2 ml-1 text-theme-6" v-if="!validate.isSelectFileTypes">This field is required</div>
          </div>
        </div>
      </div>
      <div class="intro-y mt-4">
        <div class="flex grid-flow-col auto-cols-max">
          <div class="mr-5 mt-2" style="min-width:100px;">
            <label>Select Date</label>
          </div>
          <div class="mr-5 ">
            <div class="flex">
              <div class="w-96  mr-5">
                <litepie-datepicker :disabled="isSearching" readonly :formatter="dateFormatter" :auto-apply="false"
                  use-range placeholder="Date: From - To" v-model="selectDates" />
              </div>
              <button @click="onSearchClicked" type="button"
                class="btn btn-primary shadow-md preview  self-auto align-baseline" style="height: 36px;"
                :disabled="isSearching">
                <SearchIcon class="block mx-auto mr-3" />
                Search
              </button>
            </div>
            <div class="mt-2 ml-1 text-theme-6" v-if="!validate.isSelectDates">This field is required</div>
            <div class="mt-2 ml-1 text-theme-6" v-if="!validate.isSelectDatesLimitation">Unable to select more than 60
              days</div>
          </div>
        </div>
      </div>
    </div>
    <table v-if="isSearching" class="table table-report-2 mt-5">
      <tbody>
        <tr v-for="item in 3" :key="item">
          <td class="w-20">
            <Skeletor />
          </td>
          <td class="w-40">
            <Skeletor />
          </td>
          <td class="w-40">
            <div class="font-medium whitespace-nowrap">
              <Skeletor />
            </div>
            <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
              <Skeletor />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="isContentLoaded ? !isSearching : false">
      <div v-if="listSearchTable.length > 0" class="intro-y box px-5 py-5 mt-5 " style="z-index: auto;">
        <div class="intro-x flex flex-col sm:flex-row">
          <Toggle-Switch class="mr-10" v-model="isShowTreeView" labelOn="Tree View" labelOff="List View" />
          <button type="button" class=" mt-1 mb-5 btn btn-primary shadow-md preview "
            :disabled="listSearchTable.filter(_ => _.checkboxValue).length > 0 ? false : true" style="height: 36px;"
            @click="onDownloadClicked">
            Download all check
          </button>
        </div>
        <Tree-View ref="treeview" v-if="listSearchTree.length > 0" v-model:checkValue="checkboxValue"
          :class="isShowTreeView ? '' : 'hidden'" class="intro-x" v-model:isIndeterminate="isIndeterminate"
          :isCheckbox="true" rootName="All" v-model:source="listSearchTree">
          <template v-slot:content="{ values }">
            <i v-if="values?.iconClass !== null && values?.iconClass !== undefined" style="width: 24px;height:24px"
                class="ml-1 mr-2" :class="values?.iconClass" />
              <img v-if="values?.image !== null && values?.image !== undefined"
                style="display:inline;width: 20px;height:20px" class="ml-1 mr-2" :src="values?.image" :alt="values.name">
              <span style="overflow-wrap: anywhere;"> {{ values?.name }} </span>
          </template>
        </Tree-View>
        <div v-if="listSearchTable.length > 0" :class="isShowTreeView ? 'hidden' : ''" class="intro-x inbox box">
          <div style="background: #2d3040;border-radius: 10px 10px 0px 0px;"
            class="p-5 flex flex-col-reverse sm:flex-row text-gray-600 border-b border-gray-200 dark:border-dark-1">
            <div class="w-8 flex-none flex items-center mr-5">
              <input class="form-check-input" type="checkbox" @click="onCheckboxAllClicked($event)"
                v-model="checkboxValue" :indeterminate="isIndeterminate" />
            </div>
            <div class="w-32 ">
              <label class="ml-4">
                Date</label>
            </div>
            <div class="w-32 ">
              Bank Code
            </div>
            <div class="w-32">
              Account No
            </div>
            <div class="w-64 ">
              <label class="ml-5">
                Bank
              </label>
            </div>
            <div class="w-64 ">
              <label class="ml-5">
                Service
              </label>
            </div>
            <div class="w-72">
              <label class="ml-8">
                Name
              </label>
            </div>
          </div>
          <div class="overflow-x-auto sm:overflow-x-visible">
            <div class="intro-y">
              <div v-for="(item, index) in listSearchTable" :key="index"
                class="inbox__item inline-block sm:block text-gray-700 dark:text-gray-500 bg-gray-100 dark:bg-dark-1 border-b border-gray-200 dark:border-dark-1">
                <div class="flex px-5 py-3">
                  <div class="w-8 flex-none flex items-center mr-5">
                    <input class="form-check-input flex-none" type="checkbox" @change="onCheckboxChanged(item)"
                      v-model="item.checkboxValue" />
                  </div>
                  <div class="w-32 self-center" style="overflow-wrap: anywhere;">
                    {{ item.date }}
                  </div>
                  <div class="w-32 self-center" style="overflow-wrap: anywhere;">
                    {{ item.bankCode }}
                  </div>
                  <div class="w-32 self-center" style="overflow-wrap: anywhere;">
                    {{ item.bankAccountNo }}
                  </div>
                  <div class="w-64 self-center flex" style="overflow-wrap: anywhere;">
                    <i style="width: 20px;height:20px" class="mr-2" :class="item.iconClass" />
                    <span> {{ item.bankGroupShortNameEn }} - {{ item.bankShortName }}</span>
                  </div>
                  <div class="w-64 self-center" style="overflow-wrap: anywhere;">
                    {{ item.bankServiceTypeName }}
                  </div>
                  <div class="w-72 self-center flex" style="overflow-wrap: anywhere;">
                    <img style="width: 20px;height:20px" class="mr-2" :src="findImageFileType(item.extension)">
                    <span>{{ item.name }}{{ item.extension }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="intro-y box px-5 pt-5 mt-5 " style="z-index: auto;">
        <div class="border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
          <div style="justify-content: center; display: flex"
            class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
            <img style="box-shadow: none" class="mr-2 w-12" alt="centered image" :src="imgEmptyFolder" />
          </div>
          <div style="justify-content: center; display: flex"
            class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
            <div class=" mt-5 font-medium text-center lg:text-left "> The searched file was not found.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloudService,
  bankService
} from '@/services'
import imgPdf from '@/assets/images/Pdf64.png'
import imgExcel from '@/assets/images/Excel64.png'
import imgCsv from '@/assets/images/Csv64.png'
import imgTxt from '@/assets/images/Txt64.png'
import imgDat from '@/assets/images/Dat64.png'
import imgFile from '@/assets/images/File64.png'
import imgEmptyFolder from '@/assets/images/EmptyFolder64.png'

import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      imgPdf,
      imgExcel,
      imgEmptyFolder,
      isShowTreeView: true,
      checkboxValue: false,
      isSearching: false,
      isContentLoaded: false,
      isIndeterminate: false,
      selectBankStatementTypes: [],
      selectFileTypes: [],
      selectWorkingDates: [],
      selectDates: [],
      selectBanks: [],
      validate: {
        isSelectBankStatementTypes: true,
        isSelectFileTypes: true,
        isSelectWorkingDates: true,
        isSelectDatesLimitation: true,
        isSelectDates: true,
        isSelectBanks: true
      },
      listBankStatementType: [],
      listWorkingDate: [],
      listFileType: [],
      listSearchTree: [],
      listSearchTable: [],
      listBank: [],
      dateFormatter: {
        date: 'YYYY-MM-DD',
        month: 'MMM'
      }
    }
  },
  watch: {
  },
  methods: {
    onBankCloseClicked(item) {
      const index = this.selectBanks.indexOf(item)
      if (index !== -1) {
        this.selectBanks.splice(index, 1)
      }
    },
    onWorkingDateCloseClicked(item) {
      const index = this.selectWorkingDates.indexOf(item)
      if (index !== -1) {
        this.selectWorkingDates.splice(index, 1)
      }
    },
    onCheckboxAllClicked(event) {
      this.listSearchTable.forEach(_ => {
        _.checkboxValue = this.isIndeterminate ? true : event.currentTarget.checked
      })
      this.isIndeterminate = false
      this.$refs.treeview.updateCheckValue(event.currentTarget.checked)
    },
    onCheckboxChanged(item) {
      let count = 0
      this.listSearchTable.forEach(_ => {
        if (_.checkboxValue === item.checkboxValue) {
          count++
        }
      })
      if (this.listSearchTable.length === count) {
        this.checkboxValue = item.checkboxValue
        this.isIndeterminate = false
      } else {
        this.checkboxValue = false
        this.isIndeterminate = true
      }
      this.$refs.treeview.updateCheckValueChild(item)
    },
    async onSearchClicked() {
      if (!this.isValidateRequired()) {
        return
      }
      this.checkboxValue = false
      this.isIndeterminate = false
      this.isSearching = true
      this.listSearchTree = []
      this.listSearchTable = []
      try {
        let tempFileType = []
        this.selectFileTypes.forEach(_ => {
          const find = this.listFileType.find(__ => __.id === _)
          tempFileType = tempFileType.concat(find.extensions)
        })
        const payload = {
          statementtype: this.selectBankStatementTypes,
          banks: this.selectBanks,
          workingdates: this.selectWorkingDates,
          dates: this.$filters.getDatesInRange(this.selectDates[0], this.selectDates[1]),
          filetype: tempFileType
        }
        const res = await cloudService.GetFileListFilter(payload)
        if (!this.$helpers.isNull(res?.statusCode)) {
          this.$helpers.showToastify('Search failed!', res.message, true)
        } else {
          res.sort((a, b) => {
            if (a.date === b.date) {
              return a.bankCode.localeCompare(b.bankCode)
            }
            return new Date(a.date) - new Date(b.date)
          })
          res.forEach(_ => {
            _.checkboxValue = false
            const findDate = this.listSearchTree.filter(item => item.name === _.date)
            const tempBank = _.bankGroupShortNameEn + ' - ' + _.bankShortName + ' : ' + _.bankServiceTypeName
            if (findDate.length > 0) {
              const findBankGroup = findDate[0].children.filter(item => item.name === tempBank)
              if (findBankGroup.length > 0) {
                const findBankStateNo = findBankGroup[0].children.filter(item => item.name === (_.bankCode + ' : ' + _.bankAccountNo))
                if (findBankStateNo.length > 0) {
                  const tempFile = {
                    name: _.name + _.extension,
                    obj: _,
                    image: this.findImageFileType(_.extension)
                  }
                  findBankStateNo[0].children.push(tempFile)
                } else {
                  const tempFile = {
                    name: _.name + _.extension,
                    obj: _,
                    image: this.findImageFileType(_.extension)
                  }
                  const tempBankStateNo = {
                    name: _.bankCode + ' : ' + _.bankAccountNo,
                    children: [tempFile]
                  }
                  findBankGroup[0].children.push(tempBankStateNo)
                }
              } else {
                const tempFile = {
                  name: _.name + _.extension,
                  obj: _,
                  image: this.findImageFileType(_.extension)
                }
                const tempBankStateNo = {
                  name: _.bankCode + ' : ' + _.bankAccountNo,
                  children: [tempFile]
                }
                const tempBankGroup = {
                  iconClass: _.iconClass,
                  name: tempBank,
                  children: [tempBankStateNo]
                }
                findDate[0].children.push(tempBankGroup)
              }
            } else {
              const tempFile = {
                name: _.name + _.extension,
                obj: _,
                image: this.findImageFileType(_.extension)
              }
              const tempBankStateNo = {
                name: _.bankCode + ' : ' + _.bankAccountNo,
                children: [tempFile]
              }
              const tempBankGroup = {
                iconClass: _.iconClass,
                name: tempBank,
                children: [tempBankStateNo]
              }
              const tempDate = {
                name: _.date,
                children: [tempBankGroup]
              }
              this.listSearchTree.push(tempDate)
            }
          })
          this.listSearchTable = res
          this.isContentLoaded = true
        }
      } catch (err) {
        this.$helpers.showToastify('Search failed!', err.message, true)
      }
      this.isSearching = false
    },
    async onDownloadClicked() {
      const payload = {
        fileData: []
      }
      this.listSearchTable.filter(_ => _.checkboxValue).forEach(_ => {
        payload.fileData.push({
          path: _.path,
          bankShortName: _.bankShortName,
          bankGroupShortNameEn: _.bankGroupShortNameEn,
          bankAccountNo: _.bankAccountNo
        })
      })
      const res = await cloudService.DownloadMultiFile(payload)
      console.log(res)
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.download = 'Download_BankStatement.zip'
      link.click()
    },
    findImageFileType(val) {
      switch (val) {
        case '.pdf':
          return imgPdf
        case '.xlsx':
        case '.xls':
          return imgExcel
        case '.txt':
          return imgTxt
        case '.csv':
          return imgCsv
        case '.dat':
          return imgDat
        default:
          return imgFile
      }
    },
    findWorkingDateByDays(val) {
      let find = null
      this.listWorkingDate.forEach(_ => {
        if (_.days === val) {
          find = _
        }
      })
      return find
    },
    findBankById(id) {
      let find = null
      this.listBank.forEach(_ => {
        _.options.forEach(item => {
          if (item.id === id) {
            find = item
          }
        })
      })
      return find
    },
    isValidateRequired() {
      // Default
      this.validate.isSelectDatesLimitation = true
      // Check
      this.validate.isSelectBankStatementTypes = this.selectBankStatementTypes.length > 0
      this.validate.isSelectFileTypes = this.selectFileTypes.length > 0
      this.validate.isSelectWorkingDates = this.selectWorkingDates.length > 0
      this.validate.isSelectDates = this.selectDates.length > 0
      this.validate.isSelectBanks = this.selectBanks.length > 0
      if (this.validate.isSelectBankStatementTypes && this.validate.isSelectFileTypes && this.validate.isSelectWorkingDates &&
        this.validate.isSelectDates && this.validate.isSelectBanks) {
        // Check Dates
        const dateRanges = this.$filters.getDatesInRange(this.selectDates[0], this.selectDates[1])
        this.validate.isSelectDatesLimitation = dateRanges.length <= 60
        return this.validate.isSelectDatesLimitation
      }
      return false
    },
    async getBankStatementType() {
      const res = await bankService.GetBankStatementType()
      this.listBankStatementType = res
      this.listBankStatementType.forEach(_ => {
        this.selectBankStatementTypes.push(_.id)
      })
    },
    async getWorkingDate() {
      const res = await bankService.GetWorkingDate()
      this.listWorkingDate = res
    },
    async getBankName() {
      const res = await bankService.GetBankName()
      this.listBank = []
      res.forEach(_ => {
        const find = this.listBank.filter(item => _.bankCode === item.uid)
        if (find.length > 0) {
          find[0].options.push(_)
        } else {
          const newAdd = {
            uid: _.bankCode,
            label: _.bankGroupShortNameEn,
            options: [_]
          }
          this.listBank.push(newAdd)
        }
      })
    }
  },
  computed: {
  },
  created() {
    this.listFileType = [
      { id: 1, name: 'Excel', extensions: ['.xls', '.xlsx'] },
      { id: 2, name: 'PDF', extensions: ['.pdf'] },
      { id: 3, name: 'CSV/TXT/DAT', extensions: ['.csv', '.txt', '.dat'] }
    ]
    this.listFileType.forEach(_ => {
      this.selectFileTypes.push(_.id)
    })
  },
  async mounted() {
    await this.getBankStatementType()
    await this.getWorkingDate()
    await this.getBankName()
  }
})
</script>
