import CryptoJS from 'crypto-js'
import * as mutationTypes from './mutation-types'
import * as storageTypes from './storage-types'
import * as getterTypes from './getter-types'

const state = () => {
  return {
    rememberMe: null
  }
}

const methods = {
  isNull: (val) => {
    return val === null || val === undefined || val === ''
  },
  secretKey: () => 'UGPTzRKuXMsf5mWov3yCLOqhEAl1cp0aFDY97B.dJwtkV8j6HZne%g4SrQbIiN2x',
  decrypt: (val) => {
    if (methods.isNull(val)) {
      return null
    }
    const decrypt = CryptoJS.AES.decrypt(val, methods.secretKey())
    return decrypt.toString(CryptoJS.enc.Utf8)
  },
  encrypt: (val) => {
    if (methods.isNull(val)) {
      return null
    }
    const encrypt = CryptoJS.AES.encrypt(val, methods.secretKey())
    return encrypt.toString()
  }
}

// getters
const getters = {
  [getterTypes.GET_HAS_REMEMBER_ME]: (state, getters) => !methods.isNull(getters.GET_REMEMBER_ME),
  [getterTypes.GET_REMEMBER_ME]: state => {
    if (methods.isNull(state.rememberMe)) {
      state.rememberMe = JSON.parse(methods.decrypt(localStorage.getItem(storageTypes.REMEMBER_ME)))
    }
    return state.rememberMe
  }
}
// actions
const actions = {
}

// mutations
const mutations = {
  [mutationTypes.SET_REMEMBER_ME](state, rememberMe) {
    state.rememberMe = rememberMe
    localStorage.setItem(storageTypes.REMEMBER_ME, methods.encrypt(JSON.stringify(rememberMe)))
  },
  [mutationTypes.CLEAR_REMEMBER_ME](state) {
    state.rememberMe = null
    localStorage.removeItem(storageTypes.REMEMBER_ME)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
