import Chart from './chart/Main.vue'
import GoogleMapLoader from './google-map-loader/Main.vue'
import Highlight from './highlight/Main.vue'
import Litepicker from './litepicker/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import ToggleSwitch from './toggle-switch/Main.vue'
import TreeView from './tree-view/Main.vue'
import TreeViewItem from './tree-view/item/Main.vue'
import ToasitfyAlert from './toasitfy-alert/Main.vue'
import LWDropdownMultiSelect from './lw-dropdown-multi-select/Main.vue'
import LoadingLightsaber from './loading-lightsaber/Main.vue'
import Pagination from './pagination/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
import CKEditor from './ckeditor/Main.vue'
import Dropzone from './dropzone/Main.vue'
import FullCalendar from './calendar/Main.vue'
import FullCalendarDraggable from './calendar/Draggable.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import { Skeletor } from 'vue-skeletor'
import Multiselect from '@vueform/multiselect'
import LitepieDatepicker from 'litepie-datepicker'

export default app => {
  app.component('Chart', Chart)
  app.component('GoogleMapLoader', GoogleMapLoader)
  app.component('Highlight', Highlight)
  app.component('Litepicker', Litepicker)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  app.component('ToasitfyAlert', ToasitfyAlert)
  app.component('Toggle-Switch', ToggleSwitch)
  app.component('Tree-View', TreeView)
  app.component('Tree-View-Item', TreeViewItem)
  app.component('Dropzone', Dropzone)
  app.component('CKEditor', CKEditor)
  app.component('FullCalendar', FullCalendar)
  app.component('FullCalendarDraggable', FullCalendarDraggable)
  app.component('LWDropdownMultiSelect', LWDropdownMultiSelect)
  app.component(Skeletor.name, Skeletor)
  app.component('Multiselect', Multiselect)
  app.component('LitepieDatepicker', LitepieDatepicker)
  app.component('Pagination', Pagination)
  app.component('Loading-Lightsaber', LoadingLightsaber)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
