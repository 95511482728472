<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 mt-5">
          <div class="flex items-center h-10 intro-y">
            <h2 class="mr-5 text-lg font-medium truncate">รายงานทั่วไป</h2>
            <div class="flex items-center ml-auto ">
              <div>
                <h2 class="text-lg font-medium ">
                  เวลาล่าสุด {{ $filters.formatDateTime(dateNow) }}
                </h2>
              </div>
              <button :disabled="isReloading" class="ml-5 shadow-md btn text-theme-1 dark:text-theme-10"
                @click="onRefreshClicked">
                <RefreshCcwIcon v-if="!isReloading" class="w-4 h-4 mr-3 " />
                <LoadingIcon v-else icon="oval" class="w-5 h-5 mr-3 text-center" />
                {{ isReloading ? 'Reloading Data' : 'Reload Data' }}
              </button>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="p-5 box">
                  <div class="flex">
                    <img class="w-7 h-7" :src="imgSuccess"/>
                  </div>
                  <div class="mt-6 text-3xl font-medium leading-8">{{ reportTodayGeneralResult?.meta?.rawData?.Success }}
                  </div>
                  <div class="mt-1 text-base text-gray-600">รายงานที่สำเร็จ</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="p-5 box">
                  <div class="flex">
                    <img class="w-7 h-7" :src="imgError"/>
                  </div>
                  <div class="mt-6 text-3xl font-medium leading-8">{{ reportTodayGeneralResult?.meta?.rawData?.Error }}
                  </div>
                  <div class="mt-1 text-base text-gray-600">รายงานที่ล้มเหลว</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="p-5 box">
                  <div class="flex">
                    <img class="w-7 h-7" :src="imgWarn"/>
                  </div>
                  <div class="mt-6 text-3xl font-medium leading-8">{{ reportTodayGeneralResult?.meta?.rawData?.Inactive }}
                  </div>
                  <div class="mt-1 text-base text-gray-600">รายงานที่ถูกยกเลิก</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="p-5 box">
                  <div class="flex">
                    <img class="w-7 h-7" :src="imgInfo"/>
                  </div>
                  <div class="mt-6 text-3xl font-medium leading-8">{{ reportTodayGeneralResult?.meta?.rawData?.Total }}
                  </div>
                  <div class="mt-1 text-base text-gray-600">จำนวนรายงานทั้งหมด</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-2 intro-y sm:col-span-6 lg:col-span-6 xl:col-span-6 lg:mt-6 xl:mt-2">
          <div class="flex items-center h-10 intro-y">
            <h2 class="mr-5 text-lg font-medium truncate">สรุปข้อมูล Transaction ที่จะรันวันนี้</h2>
          </div>
          <div class="mt-5 report-box-2 intro-y">
            <div class="p-5 box " v-if="reportTodayTransactionRun.meta.rawData?.length > 0">
              <div class="mt-2 tab-content">
                <apexchart :series="reportTodayTransactionRun.chartData" :options="reportTodayTransactionRun.chartOption"/>
                <div class="col-span-12 mt-5 intro-x md:col-span-3">
                  <div class="flex items-center">
                    <span class="truncate"> ทั้งหมดจำนวน
                      {{ reportTodayTransactionRun.meta.rawData.length }} ธุรกรรม
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5 box " v-else >
              <div class="flex items-center justify-center pb-5 -mx-5 text-center" style="height:345px;">
                <div style="text-align: -webkit-center;">
                  <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
                  <div class="mt-5 font-medium text-center lg:text-left" > ไม่มีบัญชีธนาคารที่จะรันของวันนี้ </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-2 intro-y sm:col-span-6 lg:col-span-6 xl:col-span-6 lg:mt-6 xl:mt-2">
          <div class="flex items-center h-10 intro-y">
            <h2 class="mr-5 text-lg font-medium truncate">สรุปข้อมูล Bank ที่จะรันวันนี้</h2>
          </div>
          <div class="mt-5 report-box-2 intro-y">
            <div class="p-5 box " v-if="reportTodayBankRun.meta.rawData.total > 0">
              <div class="mt-2 tab-content ">
                <Chart type="horizontalBar" :height="200" :options="reportTodayBankRun.chartOption"
                  :data="reportTodayBankRun.chartData" :plugins="reportTodayBankRun.chartPlugin" />
                <div class="flex mt-3" v-if="reportTodayBankRun?.chartData?.datasets?.length > 0">
                  <div class="flex items-center mr-5"
                    v-for="(item, index) in reportTodayBankRun?.chartData?.datasets" :key="index">
                    <div class="w-2 h-2 mr-3 rounded-full" :style="'background:' + item.backgroundColor"></div>
                    <div> {{ item.label }} : {{ item?.data?.reduce((partialSum, a) => partialSum + a, 0)
                    }}
                    </div>
                  </div>
                </div>
                <div class="col-span-12 mt-5 intro-x md:col-span-3">
                  <div class="flex items-center">
                    <span class="truncate"> ทั้งหมดจำนวน
                      {{ reportTodayBankRun.meta.rawData.total }} บัญชี
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-5 box " v-else >
              <div class="flex items-center justify-center pb-5 -mx-5 text-center" style="height:345px;">
                <div style="text-align: -webkit-center;">
                  <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
                  <div class="mt-5 font-medium text-center lg:text-left" > ไม่มีบัญชีธนาคารที่จะรันของวันนี้ </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-2 intro-y ">
          <div class="items-center block h-10 intro-y sm:flex">
            <h2 class="mr-5 text-lg font-medium truncate">
              รายงานการทำงานของวันนี้
            </h2>
          </div>
          <div class="overflow-auto intro-y lg:overflow-visible sm:mt-0">
            <table v-if="reportTodayWorkResult?.meta?.rawData?.length > 0"
              class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">Bank - Service</th>
                  <th class="text-center whitespace-nowrap">Account No</th>
                  <th class="text-center whitespace-nowrap">Working Date</th>
                  <th class="text-center whitespace-nowrap">Remark</th>
                  <th class="text-center whitespace-nowrap">Time</th>
                  <th class="text-center whitespace-nowrap">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in reportTodayWorkResult?.meta?.rawData" :key="index" class="intro-x">
                  <td class="w-auto">
                    <div class="flex">
                      <i class="mr-2" style="width:26px;height:24px" :class="item.iconClass" />
                      <div>
                        <div>
                          {{ item?.shortNameEn }} - {{ item?.bankShortName }}
                        </div>
                        <div class="mt-2 text-xs text-gray-600 ">
                          {{ item?.bankServiceTypeName }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <span class="whitespace-nowrap">
                      {{ item?.bankAccountNo }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="whitespace-nowrap">
                      {{ item?.workingDateName }}
                    </span>
                  </td>
                  <td class="w-72">
                    <span>
                      {{ item?.remark }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="">
                      {{ $filters.formatTime(item?.updatedAt) }}
                    </span>
                  </td>
                  <td class="text-center" :class=" item.isActive ? (item?.isStatus === true ? 'text-theme-9' : 'text-theme-6') : ' text-theme-12'">
                    <span class="">
                      {{ item.isActive ? (item?.isStatus === true ? 'Success' : 'Error') : 'Canceled' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else class="px-5 pt-5 mt-5 intro-y box">
              <div class="pb-5 -mx-5 ">
                <div style="justify-content: center; display: flex"
                  class="flex-1 px-5 pt-5 mt-6 lg:mt-0 lg:pt-0">
                  <img style="box-shadow: none" class="w-12 mr-2" alt="centered image" :src="imgEmptyFolder" />
                </div>
                <div style="justify-content: center; display: flex"
                  class="flex-1 px-5 pt-5 mt-6 lg:mt-0 lg:pt-0">
                  <div class="mt-5 font-medium text-center lg:text-left"> ไม่มีข้อมูลการทำงานของวันนี้ </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 xxl:col-span-3">
      <div class="pb-10 -mb-10 xxl:border-l border-theme-5">
        <div class="grid grid-cols-12 gap-6 xxl:pl-6">
          <div class="col-span-12 md:col-span-6 xl:col-span-4 xxl:col-span-12 xxl:mt-8">
            <div class="flex items-center -mt-2 intro-y">
              <h2 class="mr-5 text-lg font-medium truncate">สถานะของเครดิต</h2>
            </div>
            <div class="mt-5 report-box-2 intro-y">
              <div class="p-5 box">
                <div class=" tab-content">
                  <div class="justify-center w-4/5 mx-auto text-center">
                    <h2 class="text-lg font-medium "> {{ creditData.message }} </h2>
                  </div>
                  <div class="mt-5">
                      <div class="progress-2" :style="hasPackage ? (creditData.remaining <= 15 ? 'border-width:2px;border-color:#CD6155;': '') : ''">
                        <div class="absolute text-center" style="width:-webkit-fill-available;" v-if="hasPackage ? (creditData.remaining <= 35) : false">
                          <span class="text-base" :style="`color:${creditData.fontColor}`"> {{ creditData.remaining }}% </span>
                        </div>
                        <div class="text-center progress-2-bar" :style="`width:${creditData.remaining < 0 ? 0 : creditData.remaining }%;background-color:${creditData.color}`" >
                          <span class="text-base " style="color:black" v-if="hasPackage ? (creditData.remaining > 35) : false"> {{ creditData.remaining }}% </span>
                        </div>
                      </div>
                  </div>
                  <div class="mt-6">
                    <div class="flex items-center">
                      <span class="truncate">เครดิตที่ใช้: {{ userPackage?.usedCredit ?? 0 }}</span>
                      <div class="flex-1 h-px mx-3 border border-r border-gray-300 border-dashed xl:hidden"></div>
                      <span class="font-medium xl:ml-auto" v-if="!userPackage?.isUnlimited === true">{{ creditData.used }}%</span>
                    </div>
                    <div class="flex items-center mt-4" v-if="!userPackage?.isUnlimited === true">
                      <span class="truncate">เครดิตคงเหลือ : {{ uInt(userPackage?.remainingCredit ?? 0) }}</span>
                      <div class="flex-1 h-px mx-3 border border-r border-gray-300 border-dashed xl:hidden"></div>
                      <span class="font-medium xl:ml-auto">{{ (uInt(creditData.remaining)) }}%</span>
                    </div>
                    <div class="flex items-center mt-4 text-theme-6" v-if="!userPackage?.isUnlimited === true ? false : userPackage?.usedCredit < 0">
                      <span class="truncate">เครดิตติดลบ : {{ userPackage?.remainingCredit }}</span>
                      <div class="flex-1 h-px mx-3 border border-r border-gray-300 border-dashed xl:hidden"></div>
                      <span class="font-medium xl:ml-auto">{{ creditData.remaining }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 xxl:col-span-12">
            <div class="flex items-center h-10 intro-y">
              <h2 class="mr-5 text-lg font-medium truncate">สถานะของบัญชีธนาคาร</h2>
            </div>
            <div class="mt-5 report-box-2 intro-y">
              <div class="p-5 box" v-if="reportStatusBank.meta.total > 0">
                <div
                  class="justify-center w-4/5 mx-auto text-gray-600 bg-gray-200 rounded-md boxed-tabs nav nav-tabs dark:bg-dark-1 dark:text-gray-500"
                  role="tablist">
                  <a id="active-users-tab" data-toggle="tab" data-target="#active-users" href="javascript:;"
                    @click="reportStatusBank.meta.selected = true"
                    class="btn flex-1 border-0 shadow-none py-1.5 px-2 active" role="tab" aria-controls="active-users"
                    aria-selected="true">Active</a>
                  <a id="inactive-users-tab" data-toggle="tab" data-target="#inactive-users" href="javascript:;"
                    @click="reportStatusBank.meta.selected = false" class="btn flex-1 border-0 shadow-none py-1.5 px-2"
                    role="tab" aria-selected="false">Inactive</a>
                </div>
                <div class="mt-6 tab-content">
                  <div id="active-users" class="tab-pane active" role="tabpanel" aria-labelledby="active-users-tab">
                    <div class="relative">
                      <Chart class="mt-3" type="doughnut" :height="250" :options="reportStatusBank.chartOption"
                        :data="reportStatusBank.chartData" />
                      <div class="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full">
                        <div class="text-2xl font-medium"> {{ reportStatusBank.meta.selected ?
                          this.reportStatusBank.chartData.datasets[0].data[0] ?? 0 :
                          this.reportStatusBank.chartData.datasets[0].data[1] ?? 0 }}</div>
                        <div class="text-gray-600 dark:text-gray-600 mt-0.5">
                          {{ reportStatusBank.meta.selected ? 'บัญชีที่เปิดใช้งานอยู่' : 'บัญชีที่ปิดใช้งานอยู่' }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-5">
                      <div class="items-center ">
                        <h1 class="font-medium">ทั้งหมดจำนวน {{ reportStatusBank.meta.total }} บัญชี</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 box" v-else>
                <div class="tab-content">
                  <div class="intro-y">
                    <div class="flex items-center px-2 py-2 ml-4 " style="place-content:center">
                      <div class="justify-center text-center">
                        ไม่มีข้อมูล ณ เวลาปัจจุปัน
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 xxl:col-start-auto xxl:row-start-auto">
            <div class="flex items-center h-10 intro-y">
              <h2 class="mr-5 text-lg font-medium truncate">สถานะของข้อมูลรับรอง</h2>
            </div>
            <div class="mt-5 report-box-2 intro-y">
              <div class="p-5 box" v-if="reportStatusCredential?.meta?.rawData?.length > 0">
                <div
                  class="justify-center w-4/5 mx-auto text-gray-600 bg-gray-200 rounded-md boxed-tabs nav nav-tabs dark:bg-dark-1 dark:text-gray-500"
                  role="tablist">
                  <a id="active-users-tab" data-toggle="tab" data-target="#active-users" href="javascript:;"
                    @click="reportStatusCredential.meta.selected = true"
                    class="btn flex-1 border-0 shadow-none py-1.5 px-2 active" role="tab" aria-controls="active-users"
                    aria-selected="true">
                    <span class="truncate ">
                      หมดอายุ
                    </span></a>
                  <a id="inactive-users-tab" data-toggle="tab" data-target="#inactive-users" href="javascript:;"
                    @click="reportStatusCredential.meta.selected = false"
                    class="btn flex-1 border-0 shadow-none py-1.5 px-2" role="tab" aria-selected="false"> <span
                      class="truncate ">
                      กำลังจะหมดอายุ
                    </span></a>
                </div>
                <div class="tab-content">
                  <div id="active-users" class="tab-pane active" role="tabpanel" aria-labelledby="active-users-tab">
                    <div class="mt-3"
                      v-if="reportStatusCredential?.meta?.rawData?.filter((_) => _.isExpired === reportStatusCredential.meta.selected)?.length > 0">
                      <div class="intro-y"
                        v-for="(item, index) in reportStatusCredential?.meta?.rawData?.filter((_) => _.isExpired === reportStatusCredential.meta.selected)"
                        :key="index">
                        <div class="flex items-center px-2 py-2 ">
                          <div class="flex-none overflow-hidden rounded-md ">
                            <i :class="item?.iconClass" style="width: 24px;height: 24px;" />
                          </div>
                          <div class="ml-4">
                            <div class="font-medium sm:w-3/4" style="overflow-wrap: break-word;">
                              {{ item?.name }}
                            </div>
                            <div class="text-xs mt-0.5  truncate " :class="item.isExpired ? 'text-theme-6' : 'text-yellow-500'">
                              Expire : {{ item?.expiryDate }}
                            </div>
                          </div>
                          <div class="px-3 py-1 ml-auto">
                            <button class="shadow-md btn text-theme-1 dark:text-theme-10"
                              @click="onCredentialUpdateClicked($event, item)">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" v-else>
                      <div class="intro-y">
                        <div class="flex items-center px-2 py-2 ml-4 " style="place-content:center">
                          <div class="justify-center text-center ">
                            ไม่มีข้อมูล ณ เวลาปัจจุปัน
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 box" v-else>
                <div class="flex items-center px-2 py-2 ml-4 " style="place-content:center">
                  <div class="justify-center text-center ">
                    ไม่มีข้อมูล ณ เวลาปัจจุปัน
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { dashboardService } from '@/services'
import { mapGetters } from 'vuex'
import { useRouter } from 'vue-router'
import imgError from '@/assets/images/sError64.png'
import imgInfo from '@/assets/images/sInfo64.png'
import imgWarn from '@/assets/images/sWarn64.png'
import imgSuccess from '@/assets/images/sSuccess.png'
import imgEmptyFolder from '@/assets/images/EmptyFolder64.png'
import * as getterTypes from '@/store/getter-types'
import * as actionTypes from '@/store/action-types'
import { useStore } from '@/store'

export default defineComponent({
  computed: {
    ...mapGetters({
      isSyncProvider: `main/${getterTypes.GET_IS_SYNC_PROVIDER}`,
      userProvider: `main/${getterTypes.GET_PROVIDER}`,
      userPackage: `main/${getterTypes.GET_PACKAGE}`,
      hasPackage: `main/${getterTypes.GET_HAS_PACKAGE}`,
      imageProfile: `main/${getterTypes.GET_IMAGE}`,
      userProfile: `main/${getterTypes.GET_PROFILE}`
    }),
    creditData() {
      let used = null
      let remaining = null
      let fontColor = null
      let message = null
      let color = null
      if (this.hasPackage) {
        remaining = this?.userPackage.isUnlimited ? 100 : ((this?.userPackage?.totalCredit - this?.userPackage?.usedCredit) * 100 / this?.userPackage?.totalCredit).toFixed(2)
        used = (this?.userPackage.isUnlimited ? 0 : (100 - remaining)).toFixed(2)
        message = this?.userPackage.isUnlimited ? 'เครดิตไม่จำกัด' : `ทั้งหมดจำนวน ${this.userPackage?.totalCredit} เครดิต`
      } else {
        remaining = 0
        used = 0
        message = 'ไม่มีเครดิต'
      }
      if (remaining <= 15) {
        color = fontColor = '#CD6155'
      } else if (remaining <= 35) {
        color = fontColor = '#FC9064'
      } else if (remaining <= 55) {
        color = '#F9B379'
      } else if (remaining <= 85) {
        color = '#F9E679'
      } else {
        color = '#0DD192'
      }
      return { used, remaining, fontColor, message, color }
    }
  },
  data() {
    return {
      imgSuccess,
      imgError,
      imgInfo,
      imgWarn,
      imgEmptyFolder,
      router: null,
      store: null,
      dateNow: null,
      isReloading: false,
      reportTodayBankRun: {
        meta: {
          rawData: [],
          backdate: 0,
          intraday: 0,
          total: 0
        },
        chartPlugin: [{
          afterDraw: chart => {
            const ctx = chart.chart.ctx
            const yAxis = chart.scales['y-axis-0']
            const isTicksMore = yAxis.ticks.length < 5
            yAxis.ticks.forEach((value, index) => {
              const y = yAxis.getPixelForTick(index)
              let isReduceSize = false
              // Color
              ctx.beginPath()
              ctx.roundRect(yAxis.top + (isTicksMore ? 10 : 22), y - 13, 22, 22, 5)
              switch (value.toLowerCase()) {
                case 'kbank':
                  ctx.fillStyle = '#138f2d'
                  break
                case 'bbl':
                  ctx.fillStyle = '#1e4598'
                  break
                case 'rbs':
                  ctx.fillStyle = '#032952'
                  break
                case 'ktb':
                  ctx.fillStyle = '#1ba5e1'
                  break
                case 'jpm ':
                  ctx.fillStyle = '#321c10'
                  break
                case 'mufg ':
                  ctx.fillStyle = '#d61323'
                  break
                case 'tmb':
                  ctx.fillStyle = '#1279be'
                  break
                case 'scb':
                  ctx.fillStyle = '#4e2e7f'
                  break
                case 'citi':
                  ctx.fillStyle = '#1583c7'
                  break
                case 'sc':
                  ctx.fillStyle = '#a0d235'
                  break
                case 'cimb':
                  ctx.fillStyle = '#7e2f36'
                  break
                case 'uob':
                  ctx.fillStyle = '#0b3979'
                  break
                case 'bay':
                  ctx.fillStyle = '#fec43b'
                  break
                case 'mega':
                  ctx.fillStyle = '#815e3b'
                  break
                case 'boa':
                  ctx.fillStyle = '#e11e3c'
                  break
                case 'cacib':
                  ctx.fillStyle = '#0e765b'
                  break
                case 'gsb':
                  ctx.fillStyle = '#eb198d'
                  break
                case 'baac':
                  ctx.fillStyle = '#4b9b1d'
                  break
                case 'mb':
                  ctx.fillStyle = '#150b78'
                  break
                case 'bnp':
                  ctx.fillStyle = '#14925e'
                  break
                case 'tbank':
                  ctx.fillStyle = '#fc4f1f'
                  break
                case 'ibank':
                  ctx.fillStyle = '#184615'
                  break
                case 'tisco':
                  ctx.fillStyle = '#12549f'
                  break
                case 'kk':
                  ctx.fillStyle = '#199cc5'
                  break
                case 'icbc':
                  ctx.fillStyle = '#c50f1c'
                  break
                case 'tcrb':
                  ctx.fillStyle = '#0a4ab3'
                  break
                case 'lhb':
                  ctx.fillStyle = '#6d6e71'
                  break
                case 'ttb':
                  isReduceSize = true
                  ctx.fillStyle = '#ecf0f1'
                  break
                case 'tmn':
                  ctx.fillStyle = '#ecf0f1'
                  break
                case 'pp':
                  ctx.fillStyle = '#00427a'
                  break
              }
              ctx.fill()
              // Image
              const img = new Image()
              img.src = require(`@/assets/bankimage/${value.toLowerCase()}.svg`)
              ctx.drawImage(img, yAxis.top + (isTicksMore ? 13 : 25), y - (isReduceSize ? 6 : 10), 16, (isReduceSize ? 8 : 16))
            })
          }
        }],
        chartOption: {
          responsive: true,
          legend: {
            display: true,
            position: 'top',
            labels: {
              fontColor: '#e2e9f0'
            }
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: '12',
                  fontColor: '#e2e9f0'
                },
                gridLines: {
                  display: true
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  padding: 30,
                  fontSize: '12',
                  fontColor: '#e2e9f0'
                },
                gridLines: {
                  display: false
                }
              }
            ]
          }
        },
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Backdate',
              barPercentage: 0.9,
              barThickness: 10,
              maxBarThickness: 10,
              minBarLength: 2,
              data: [],
              backgroundColor: '#5e3eff'
            },
            {
              label: 'Intraday',
              barPercentage: 0.5,
              barThickness: 10,
              maxBarThickness: 10,
              minBarLength: 2,
              data: [],
              backgroundColor: '#5e234f'
            }
          ]
        }
      },
      reportStatusBank: {
        meta: {
          rawData: null,
          total: 0,
          selected: true
        },
        chartPlugin: [],
        chartOption: {
          legend: {
            display: false
          },
          cutoutPercentage: 80
        },
        chartData: {
          labels: ['Active', 'Inactive'],
          datasets: [
            {
              data: [],
              backgroundColor: ['#285FD3', '#FF8B26'],
              hoverBackgroundColor: ['#285FD3', '#FF8B26'],
              borderWidth: 5,
              borderColor: '#303953'
            }
          ]
        }
      },
      reportStatusCredential: {
        meta: {
          rawData: null,
          selected: true
        }
      },
      reportTodayWorkResult: {
        meta: {
          rawData: null
        }
      },
      reportTodayGeneralResult: {
        meta: {
          rawData: null
        }
      },
      reportTodayTransactionRun: {
        meta: {
          rawData: []
        },
        chartOption: {
          chart: {
            height: 500,
            type: 'heatmap',
            foreColor: 'white',
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false,
            style: {
              colors: ['#9C27B0'],
              fontSize: '12px'
            }
          },
          xaxis: {
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            tooltip: {
              enabled: false
            }
          },
          yaxis: {
            show: false
          },
          //   ${w.globals.seriesX[seriesIndex][dataPointIndex]}
          // <span> ${series[seriesIndex][dataPointIndex]} </span>
          // <i class="${w.globals.seriesX[seriesIndex][dataPointIndex].iconClass}" style="width: 24px;height: 24px;" />
          tooltip: {
            shared: true,
            theme: 'dark',
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return `<div class="p-3 arrow_box">
                        <div class="border-b border-white " style="border-bottom-width:2px">
                          <div>
                            Time : ${this.$filters.formatTime(w.globals.seriesX[seriesIndex][dataPointIndex].dateTime)}
                          </div>
                          <div class="flex mb-2" >
                            Status :
                            <div class="ml-1" style="color:${series[seriesIndex][dataPointIndex] === 0 ? '#718096' : (series[seriesIndex][dataPointIndex] === 1 ? '#0DD192' : series[seriesIndex][dataPointIndex] === 2 ? '#CD6155' : '#F7dc6f')}">
                             ${series[seriesIndex][dataPointIndex] === 0 ? 'Not Run' : (series[seriesIndex][dataPointIndex] === 1 ? 'Success' : series[seriesIndex][dataPointIndex] === 2 ? 'Error' : 'Cancel')}
                            </div>
                          </div>
                        </div>
                        <div class="flex mt-2">
                          <i style="width:16px;height:16px;" class="mr-2 ${w.globals.seriesX[seriesIndex][dataPointIndex].iconClass}" ></i>
                          ${w.globals.seriesX[seriesIndex][dataPointIndex].shortNameEn} - ${w.globals.seriesX[seriesIndex][dataPointIndex].bankShortName}
                        </div>
                         <div class="text-xs text-gray-600 ">
                          ${w.globals.seriesX[seriesIndex][dataPointIndex].bankServiceTypeName}
                        </div>
                         <div class="text-xs text-gray-600 ">
                          ${w.globals.seriesX[seriesIndex][dataPointIndex].workingDateDay}
                        </div>
                         <div class="text-xs text-gray-600 ">
                          ${w.globals.seriesX[seriesIndex][dataPointIndex].bankAccountNo}
                        </div>
                      </div>`
            }
          },
          stroke: {
            curve: 'smooth',
            width: 0.4,
            colors: ['black']
          },
          markers: {
            size: 0
          },
          grid: {
            borderColor: '#535A6C',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            }
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.9,
              radius: 2,
              enableShades: true,
              distributed: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: '#718096',
                    name: 'Not Run'
                  },
                  {
                    from: 1,
                    to: 1,
                    color: '#0DD192',
                    name: 'Success'
                  },
                  {
                    from: 2,
                    to: 2,
                    color: '#CD6155',
                    name: 'Error'
                  },
                  {
                    from: 3,
                    to: 3,
                    color: '#F7dc6f',
                    name: 'Cancel'
                  }
                ]
              }
            }
          }
        },
        chartData: []
      }
    }
  },
  async created() {
    this.router = useRouter()
    this.store = useStore()
    await this.initializeReportData(false)
  },
  methods: {
    uInt(val) {
      return val < 0 ? 0 : val
    },
    onCredentialUpdateClicked(event, val) {
      event.preventDefault()
      this.router.push({
        name: 'credentials',
        query: {
          'edit-vault': val.id
        }
      })
    },
    async onRefreshClicked() {
      this.isReloading = true
      await this.delay(500)
      await this.initializeReportData()
    },
    updateReportStatusBank(val) {
      this.reportStatusBank.meta.rawData = val
      this.reportStatusBank.meta.total = val.total
      this.reportStatusBank.chartData.labels = val.labels
      this.reportStatusBank.chartData.datasets[0].data = val.data
    },
    updateReportTodayBankRunning(val) {
      this.reportTodayBankRun.meta.rawData = val
      // Clear Name
      this.reportTodayBankRun.chartData.labels = []
      // Clear Backdate
      this.reportTodayBankRun.chartData.datasets[0].data = []
      // Clear Intraday
      this.reportTodayBankRun.chartData.datasets[1].data = []
      val.forEach(item => {
        // Add Name
        this.reportTodayBankRun.chartData.labels.push(item.Name)
        // Add Backdate
        this.reportTodayBankRun.chartData.datasets[0].data.push(item.Backdate)
        // Add Intraday
        this.reportTodayBankRun.chartData.datasets[1].data.push(item.Intraday)
      })

      this.reportTodayBankRun.meta.rawData.backdate = this.reportTodayBankRun?.chartData?.datasets[0]?.data?.reduce((partialSum, a) => partialSum + a, 0)
      this.reportTodayBankRun.meta.rawData.intraday = this.reportTodayBankRun?.chartData?.datasets[1]?.data?.reduce((partialSum, a) => partialSum + a, 0)
      this.reportTodayBankRun.meta.rawData.total = (this.reportTodayBankRun.meta.rawData.backdate + this.reportTodayBankRun.meta.rawData.intraday)
    },
    updateReportStatusCredential(val) {
      this.reportStatusCredential.meta.rawData = val
    },
    updateReportTodayWorkResult(val) {
      this.reportTodayWorkResult.meta.rawData = val
    },
    updateTodayGeneralResult(val) {
      this.reportTodayGeneralResult.meta.rawData = val
    },
    updateReportTodayTransactionRun(val) {
      const row = 4
      const column = 10
      this.reportTodayTransactionRun.meta.rawData = val
      this.reportTodayTransactionRun.chartData = new Array(row).fill(0).map(() => { return { data: [] } })
      if (val?.length > 0) {
        let tempRow = 0
        val.forEach((item, index) => {
          const indexAdd = index + 1
          if (this.reportTodayTransactionRun.chartData[tempRow] === null || this.reportTodayTransactionRun.chartData[tempRow] === undefined) {
            this.reportTodayTransactionRun.chartData.push({ data: [] })
          }
          this.reportTodayTransactionRun.chartData[tempRow].data.push({ x: item, y: item.status })
          if (indexAdd % column === 0) {
            tempRow++
          }
        })
        this.reportTodayTransactionRun.chartData.reverse()
      }
    },
    async initializeReportData(isReload = true) {
      this.dateNow = new Date()
      const listService = [
        {
          promise: dashboardService.GetTodayBankRunning(),
          func: this.updateReportTodayBankRunning
        },
        {
          promise: dashboardService.GetTodayWorkResult(),
          func: this.updateReportTodayWorkResult
        },
        {
          promise: dashboardService.GetTodayGeneralResult(),
          func: this.updateTodayGeneralResult
        },
        {
          promise: dashboardService.GetBankStatus(),
          func: this.updateReportStatusBank
        },
        {
          promise: dashboardService.GetCredentialStatus(),
          func: this.updateReportStatusCredential
        },
        {
          promise: dashboardService.GetTodayTransactionRun(),
          func: this.updateReportTodayTransactionRun
        }
      ]
      const that = this
      let count = 0
      // Fetch Data With Async
      listService.forEach((_) => {
        try {
          _.promise
            .then(val => {
              _.func(val)
              count += 1
              if (count === listService.length) {
                that.isReloading = false
              }
            })
            .catch(error => {
              that.$helpers.showToastify('Error', error?.message, true)
              count += 1
              if (count === listService.length) {
                that.isReloading = false
              }
            })
        } catch (error) {
          that.$helpers.showToastify('Error', error?.message, true)
          count += 1
          if (count === listService.length) {
            that.isReloading = false
          }
        }
      })
      if (isReload) {
        await this.store.dispatch(`main/${actionTypes.UPDATE_PACKAGE}`)
      }
    },
    async delay(milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }
  }
})
</script>
