<template>
  <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
    <div style="margin: auto; width: -webkit-fill-available;">
      <label>
        Showing {{ take }} to {{ entries }} of {{ total }} {{$helpers.isNull(entriesName) ? defaultEntriesName : entriesName}}
      </label>
    </div>
    <ul class="pagination" style="margin: inherit;">
      <li :class="currentPage === 1 ? 'disabled' : ''">
        <a class="pagination__link" href="#"  @click="onFirstClicked">
          <ChevronsLeftIcon class="w-4 h-4" />
        </a>
      </li>
      <li :class="currentPage === 1 ? 'disabled' : ''">
        <a class="pagination__link" href="#" @click="onPreviousClicked">
          <ChevronLeftIcon class="w-4 h-4" />
        </a>
      </li>
      <li v-if="isDotPreviousPagination">
        <label class="pagination__link" style="cursor:auto">...</label>
      </li>
      <li v-for="(item, index) in getPaginationItem" :key="index" :class="item === currentPage ? 'disabled' : ''">
        <a class="pagination__link" :class="item === currentPage ? ' pagination__link--active' : ''"
           href="#" @click="onClicked(item)">{{ item }}</a>
      </li>
      <li v-if="isDotNextPagination">
        <label class="pagination__link" style="cursor:auto">...</label>
      </li>
      <li :class="currentPage === totalPage ? 'disabled' : ''">
        <a class="pagination__link" href="#" @click="onNextClicked">
          <ChevronRightIcon class="w-4 h-4" />
        </a>
      </li>
      <li :class="currentPage === totalPage ? 'disabled' : ''">
        <a class="pagination__link" href="#"  @click="onLastClicked">
          <ChevronsRightIcon class="w-4 h-4" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    entries: {
      type: Number,
      required: true
    },
    take: {
      type: Number,
      required: false
    },
    total: {
      type: Number,
      required: true
    },
    totalPage: {
      type: Number,
      required: true
    },
    entriesName: {
      type: String,
      default: 'entries'
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  emits: ['click'],
  data() {
    return {
      defaultEntriesName: 'entries',
      gapDisplayPage: 2
    }
  },
  computed: {
    isDotPreviousPagination() {
      return this.getPaginationItem[0] > 1
    },
    isDotNextPagination() {
      const lastIndex = this.getPaginationItem.length - 1
      return this.getPaginationItem[lastIndex] < this.totalPage
    },
    getPaginationItem() {
      const tmpPage = []
      for (let item = 1; item <= this.totalPage; item++) {
        if (this.currentPage === item) {
          tmpPage.push(item)
        }
        for (let itemIn = 1; itemIn <= this.gapDisplayPage; itemIn++) {
          if (this.currentPage - itemIn === item) {
            tmpPage.push(item)
          } else if (this.currentPage + itemIn === item) {
            tmpPage.push(item)
          }
        }
      }
      return tmpPage
    }
  },
  methods: {
    onFirstClicked() {
      this.$emit('click', 1)
    },
    onLastClicked() {
      this.$emit('click', this.totalPage)
    },
    onPreviousClicked() {
      this.$emit('click', this.currentPage - 1)
    },
    onNextClicked() {
      this.$emit('click', this.currentPage + 1)
    },
    onClicked(val) {
      this.$emit('click', val)
    }
  },
  watch: {
  }
})
</script>
