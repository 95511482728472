<template>
  <div class="mt-5 intro-y box">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="mr-auto text-base font-medium">Personal Information</h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 mb-4 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <label class="form-label">First Name</label>
            <div class="form-control disabled">
              {{ profile?.firstName }}
            </div>
          </div>
          <div class="mt-3 ">
            <label class="form-label">Email</label>
            <div class="form-control disabled">
              {{ profile?.email }}
            </div>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-6">
          <div class="mt-3 xl:mt-0">
            <label class="form-label">Last Name</label>
            <div class="form-control disabled">
              {{ profile?.lastName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="flex mr-auto text-base font-medium">
        Package Information
      </h2>
    </div>
    <div class="p-5">
      <div v-if="hasPackage ? packageDataUpdate.isExpired : false" class="flex mb-3">
        <img :src="imgNoPackage" class="w-5 h-5 mr-2" />
        <h2 class="text-base font-medium " style="color:#CD6155">
          Package ของคุณได้หมดอายุแล้ว กรุณาติดต่อผู้ดูระบบเพื่อทำการต่อ Package ให้กับบัญชีนี้
        </h2>
      </div>
      <div v-if="hasPackage" class="grid grid-cols-12 mb-4 gap-x-5">
        <div class="col-span-12 xl:col-span-6">
          <div>
            <span>
              Name : {{ packageData?.name }}
            </span>
          </div>
          <div class="flex mt-5" v-if="!$helpers.isNull(packageData?.description)">
            <div class="mr-2"> Description : </div>
            <div> {{ packageData?.description }}</div>
          </div>
          <div class="flex mt-5 ">
            <div class="mr-2"> Credit Amount : </div>
            <img class="w-5 h-5 mr-2" v-if="packageData?.isUnlimited" :src="imgUnlimited" />
            <div> {{ packageData?.isUnlimited ? 'Unlimited' : packageData?.creditAmount ?? '-' }}</div>
          </div>
          <div class="flex mt-5 " v-if="packageData?.isUnlimited === false">
            <div class="mr-2"> Original Credit : </div>
            <div> {{ packageData?.isUnlimited ? '-' : packageData?.originalCredit ?? '-' }}</div>
          </div>
          <div class="flex mt-5 " v-if="packageData?.isUnlimited === false">
            <div class="mr-2"> Total Credit : </div>
            <img class="w-5 h-5 mr-2" v-if="packageData?.isUnlimited" :src="imgUnlimited" />
            <div> {{ packageData?.isUnlimited ? 'Unlimited' : packageData?.totalCredit ?? '-' }}</div>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-6">
          <div class="flex xl:mt-0">
            <div class="mr-2"> Activation Date : </div>
            <div> {{ packageDataUpdate?.expiryDate }}</div>
          </div>
          <div class="flex mt-5">
            <div class="mr-2"> Expiry Date : </div>
            <div :class="{ 'text-theme-6': packageDataUpdate.isExpired }"> {{ packageDataUpdate?.activeDate }}</div>
          </div>
          <div class="flex mt-5" v-if="packageData?.isFreeTrial === true">
            <div>
              Type :
            </div>
            <img class="w-5 h-5 ml-2 mr-2" :src="imgFreeTrial" />
            <div>
              Free Trial
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex">
        <img :src="imgNoPackage" class="w-5 h-5 mr-2" />
        <h2 class="text-base font-medium " style="color:#F9E679">
          บัญชีของคุณไม่ได้มีการผูก Package เอาไว้ กรุณาติดต่อผู้ดูระบบเพื่อทำการผูก Package ให้กับบัญชีนี้
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import imgFreeTrial from '@/assets/images/FreeTrial64.png'
import imgUnlimited from '@/assets/images/Unlimited64.png'
import imgNoPackage from '@/assets/images/NoPackage64.png'

export default defineComponent({
  props: {
    profile: {
      type: Object,
      required: true
    },
    packageData: {
      type: Object,
      required: true
    },
    hasPackage: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    packageDataUpdate() {
      const activeDate = new Date(this.packageData?.activeDate)
      const expiryDate = new Date(this.packageData?.expiryDate)
      const nowDate = this.$filters.formatDateOut(new Date())
      return {
        isExpired: nowDate > expiryDate,
        expiryDate: this.$filters.formatDateRe(activeDate),
        activeDate: this.$filters.formatDateRe(expiryDate)
      }
    }
  },
  data() {
    return {
      imgNoPackage,
      imgUnlimited,
      imgFreeTrial
    }
  }
})
</script>
