import HttpRequest from './HttpRequest'

class ScholarService extends HttpRequest {
  constructor() {
    super(window.__env.api.url + 'Scholar')
  }

  // payload.params
  //   {
  //     userId: 'int',
  //     userName: 'string',
  //     email: 'string',
  //     firstName: 'string',
  //     lastName: 'string',
  //     isActive: 'bool',
  //     orderBy: 'string',
  //     pageNo: 'int',
  //     pageSize: 'int',
  //     isPagination: 'bool',
  //   }
  async getScholars(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.get('GetScholars', payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //    dailyScholars: [
  //     {
  //       "scholarId": int,
  //       "roninAddress": roninaddress
  //     }
  //    ],
  //   }
  async getDailyScholars(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('GetDailyScholars', payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     id: 'int', //optional if 0 or null = create > 0  != null = update
  //     email: 'string',
  //     firstName: 'string',
  //     lastName: 'string',
  //     isActive: 'bool',
  //     roninAddress: 'string',
  //     idCard: 'string',
  //     discordId: 'string',
  //     discordGuidId: 'string',
  //     walletAddress: 'string',
  //     sharePercent: 'int',
  //   }
  async addOrUpdateScholar(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('AddOrUpdateScholar', payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     id: 'int',
  //     isActive: 'bool',
  //   }
  async updateStatus(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('UpdateStatus', payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //     id: 'int',
  //   }
  async deleteScholar(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.delete('DeleteScholar', payload)
    const { data } = res
    return data
  }

  // payload.params
  //   {
  //    dailyScholars: [
  //     {
  //       "scholarId": int,
  //       "roninAddress": roninaddress
  //     }
  //    ],
  //   }
  async getDisplayNameScholars(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('GetDisplayNameScholars', payload)
    const { data } = res
    return data
  }

  async addScholarList(payload) {
    const token = this.getAccessToken()
    if (token) {
      this.setHeader({
        Authorization: `Bearer ${token}`
      })
    }
    const res = await this.post('AddScholarList', payload)
    const { data } = res
    return data
  }

  async getPriceList() {
    const res = await this.get('GetPriceList')
    const { data } = res
    return data
  }
}

export default ScholarService
