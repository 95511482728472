<template>
  <div class="container">
    <div class="intro-x flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
      <div class="flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
        <div class="-intro-x lg:mr-20">
          <img class="lg:h-auto" :src="imgLinkEmail" />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            {{message}}
          </div>
          <div class="flex">
            <button @click="onBackToHomeClicked" class="intro-x btn py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10 mr-5">
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import imgLinkEmail from '@/assets/images/Link-Email256.png'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    message: {
      type: String
    }
  },
  emits: [
    'loaded'
  ],
  data: () => ({
    imgLinkEmail
  }),
  methods: {
    onBackToHomeClicked() {
      this.router.push('/')
    }
  },
  created() {
    this.router = useRouter()
  },
  mounted() {
    this.$emit('loaded')
  }
})
</script>
