import https from 'https'
import axios from 'axios'
import { useStore } from '@/store'
import * as getterTypes from '@/store/getter-types'
import * as actionTypes from '@/store/action-types'

const mainStore = {
  // eslint-disable-next-line new-cap
  getStore: () => new useStore(),
  getToken: () => mainStore.getStore().getters[`main/${getterTypes.GET_TOKEN}`],
  clearData: () => mainStore.getStore().dispatch[`main/${actionTypes.CLEAR_DATA}`]
}

class HttpRequest {
  constructor(url = process.env.VUE_APP_API_ENDPOINT) {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 120000,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    })

    this.axiosInstance.defaults.headers.common['Cache-Control'] = 'no-cache'
    this.axiosInstance.defaults.headers.common['Content-Type'] =
      'application/json'
    this.axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] =
      '*'
    this.axiosInstance.defaults.headers.get.Accept = 'application/json'
    this.axiosInstance.defaults.headers.post.Accept = 'application/json'

    this.axiosInstance.interceptors.request.use(
      function(config) {
        // Do something before request is sent
        return config
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      function(response) {
        // Do something with response data
        return response
      },
      function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 401) {
            if (
              error.response.data != undefined &&
            error.response.data.msg == 'Wrong username/password'
            ) {
              return error
            } else {
              mainStore.clearData()
              // if (error.response.config.url !== 'login') {
              //   window.location = `${window.__env.api.frontEndPreUrl}login`
              // }
              return error.response
            }
          } else if (
            error.response.status === 403 ||
          error.response.status === 405
          ) {
            return error.response
          }
          // if (error.response.status === 500) {
          if (
            error.response.data !== undefined &&
          error.response.data.code !== undefined &&
          error.response.data.code == 200
          ) {
            return error.response.data
          }
          return error.response
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        console.log(error.config)
        throw error
      }
    )
  }

  getAccessToken() {
    return mainStore.getToken() || false
  }

  setHeader(header) {
    // this.axiosInstance.defaults.headers.common[header.key] = header.value
    this.axiosInstance.defaults.headers.common = header
    // this.axiosInstance.defaults.headers.post["Content-Type"] =
    //   "application/x-www-form-urlencoded";
  }

  setHeaderBearerAuth(token) {
    this.setHeader({ Authorization: `Bearer ${token}` })
  }

  get(methodName, data) {
    return this.axiosInstance.get(methodName, {
      params: data
    })
  }

  getBlob(methodName, data) {
    return this.axiosInstance.get(methodName, {
      params: data,
      responseType: 'blob'
    })
  }

  post(methodName, data) {
    return this.axiosInstance.post(methodName, data)
  }

  put(methodName, data) {
    return this.axiosInstance.put(methodName, data)
  }

  patch(methodName, data) {
    return this.axiosInstance.patch(methodName, data)
  }

  delete(methodName, param, data) {
    return this.axiosInstance.delete(methodName, {
      params: param,
      data: data
    })
  }

  request(type, url, data) {
    let promise = null
    switch (type) {
      case 'POST':
        promise = axios.post(url, data)
        break
      case 'PUT':
        promise = axios.put(url, data)
        break
      case 'DELETE':
        promise = axios.delete(url, data)
        break
      case 'GET':
      default:
        promise = axios.get(url, { params: data })
        break
    }
    return promise
  }
}

export default HttpRequest
