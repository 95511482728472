<template>
  <div>
    <DarkModeSwitcher isHide="true" />
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <div class="flex-col hidden min-h-screen xl:flex">
          <div class="my-auto">
            <div class="flex flex-row items-center">
              <img alt="lw" class="-intro-x" style="margin-top: -50; margin-left: -50px"
                :src="isDarkMode ? imgLogo : imgLogoDark" />
            </div>
          </div>
        </div>
        <div v-if="!isLoaded"
          class="flex flex-col items-center justify-center h-screen text-center lg:flex-row lg:text-left">
          <div class="mt-10 text-white lg:mt-0" style="text-align: -webkit-center;">
            <div class="w-10 h-10 -intro-x">
              <LoadingIcon icon="circles" />
            </div>
            <div class="mt-5 text-center intro-x">
              <span style="font-size: 16px;">
                Preparing information...
              </span>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col items-center justify-center h-screen text-center lg:flex-row lg:text-left">
          <div class="mt-10 text-white lg:mt-0" v-if="isRegisterCompleted">
            <div class="mt-5 text-xl font-medium intro-x lg:text-3xl">
              สมัครสมาชิกเรียบร้อยแล้ว
            </div>
            <div class="mt-3 text-lg intro-x">
              กรุณายืนยันการสมัครสมาชิก ผ่านทาง Email: {{ $helpers.isNull(newData.email) ? '-' : newData.email }}
            </div>
            <router-link to="/login" class="px-4 py-3 text-white border-white mt-7 intro-x btn dark:border-dark-5 dark:text-gray-300">
              กลับสู่หน้าหลัก
            </router-link>
          </div>
          <div class="flex h-screen py-5 my-10 xl:h-auto xl:py-0 xl:my-0" v-else>
            <div
              class="w-full px-5 py-8 mx-auto my-auto bg-white rounded-md shadow-md xl:ml-32 dark:bg-dark-1 xl:bg-transparent sm:px-8 xl:p-0 xl:shadow-none sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 class="text-2xl font-bold text-center intro-y xl:text-3xl xl:text-left">
                Sign Up For StatementPro
              </h2>
              <div
                class="items-center mt-4 text-xs text-center text-gray-700 intro-y dark:text-gray-600 sm:text-sm xl:text-left">
                คุณกำลังลงทะเบียนกับ Lightwork
              </div>
              <div class="mt-2 text-center text-gray-500 intro-x dark:text-gray-500 xl:hidden">
                A few more clicks to sign in to your account. Manage all your
                e-commerce accounts in one place
              </div>
              <div class="mt-6">
                <div class="border-gray-300 input-group login__input intro-x focus:intro-x">
                  <input :disabled="isRegistering" type="text" v-model="newData.firstName"
                    class="border-gray-300 form-control" placeholder="First Name" />
                </div>
                <template v-if="v$.newData.firstName.$error">
                  <div v-for="(error, index) in v$.newData.firstName.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <div class="mt-4 border-gray-300 input-group login__input intro-x focus:intro-x">
                  <input :disabled="isRegistering" type="text" v-model="newData.lastName"
                    class="border-gray-300 b form-control" placeholder="Last Name" />
                </div>
                <template v-if="v$.newData.lastName.$error">
                  <div v-for="(error, index) in v$.newData.lastName.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <div class="mt-4 border-gray-300 input-group login__input intro-x focus:intro-x">
                  <div id="input-group-email" class="input-group-text" :class="{ disabled: isRegistering }">
                    @
                  </div>
                  <input :disabled="isRegistering" type="email" placeholder="Email" v-model="newData.email"
                    class="border-gray-300 form-control" aria-label="Email" aria-describedby="input-group-email" />
                </div>
                <template v-if="v$.newData.email.$error">
                  <div v-for="(error, index) in v$.newData.email.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <div class="mt-4 border-gray-300 input-group login__input intro-x focus:intro-x">
                  <div id="input-group-username" class="input-group-text" :class="{ disabled: isRegistering }">
                    <UserIcon class="block w-3 mx-auto" />
                  </div>
                  <input :disabled="isRegistering" type="text" v-model="newData.username" placeholder="Username"
                    class="border-gray-300 form-control" aria-label="Username" aria-describedby="input-group-username" />
                </div>
                <template v-if="v$.newData.username.$error">
                  <div v-for="(error, index) in v$.newData.username.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <div class="mt-4 dropdown intro-x" id="policy-password-dropdown" data-placement="bottom">
                  <div class="border-gray-300 input-group login__input focus:intro-x ">
                    <div id="input-group-password " class="input-group-text " :class="{ disabled: isRegistering }">
                      <LockIcon class="block w-3 mx-auto" />
                    </div>
                    <input :disabled="isRegistering" :type="isShowPassword ? 'text' : 'password'" placeholder="Password"
                      class="border-gray-300 form-control dropdown-toggle" aria-label="Password" autocomplete="off"
                      @focusin="onPasswordFocusIn" @focusout="onPasswordFocusOut" aria-describedby="input-group-password"
                      v-model="newData.password" />
                    <div id="input-group-password " :aria-disabled="isRegistering" class="cursor-pointer input-group-text"
                      :class="{ disabled: isRegistering }" @click="isShowPassword = !isShowPassword">
                      <EyeOffIcon v-if="isShowPassword" class="block w-3 mx-auto " />
                      <EyeIcon v-else class="block w-3 mx-auto " />
                    </div>
                  </div>
                  <div class="dropdown-menu">
                    <div v-if="!$helpers.isNull(policyPassword.rule)"
                      style="display: flex;align-items: center;place-content: center; ">
                      <div class="mt-5 mb-5 container-password">
                        <div>
                          {{ safePasswordMessage }}
                        </div>
                        <div class="mt-2 mb-2 progress-bar_wrap ">
                          <div class="progress-bar_item" :class="updateProgressBar(1)"></div>
                          <div class="progress-bar_item" :class="updateProgressBar(2)"></div>
                          <div class="progress-bar_item" :class="updateProgressBar(3)"></div>
                        </div>
                        <div>
                          รหัสผ่านจะต้องประกอบไปด้วย:
                        </div>
                        <ul class="mt-2 -ml-2 ui-password">
                          <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMinCharacters }"
                            v-if="policyPassword.rule.minLength > 0">
                            ต้องมีความยาวรหัสผ่านอย่างน้อย <strong>{{ policyPassword.rule.minLength }}</strong> ตัวขึ้นไป
                          </li>
                          <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainMaxCharacters }"
                            v-if="policyPassword.rule.maxLength > 0">
                            ต้องมีความยาวรหัสผ่านไม่เกิน <strong>{{ policyPassword.rule.maxLength }}</strong> ตัว
                          </li>
                          <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainNumber }"
                            v-if="policyPassword.rule.minNumbers > 0">
                            ต้องมีตัวเลข (0-9) <strong>{{ policyPassword.rule.minNumbers }}</strong> ตัวขึ้นไป
                          </li>
                          <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainUppercase }"
                            v-if="policyPassword.rule.minUpperCase > 0">
                            ต้องมีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ (A-Z) <strong>{{ policyPassword.rule.minUpperCase
                            }}</strong>
                            ตัวขึ้นไป
                          </li>
                          <li class="li-password" :class="{ is_valid: policyPassword.validations.isContainLowercase }"
                            v-if="policyPassword.rule.minLowerCase > 0">
                            ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) <strong>{{ policyPassword.rule.minLowerCase
                            }}</strong>
                            ตัวขึ้นไป
                          </li>
                          <li class="li-password"
                            :class="{ is_valid: policyPassword.validations.isContainSpecialCharacter }"
                            v-if="policyPassword.rule.minIncludeSpecialChar > 0">
                            ต้องมีอักขระพิเศษ (!@#$%^&+=) <strong>{{ policyPassword.rule.minIncludeSpecialChar }}</strong>
                            ตัวขึ้นไป
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="v$.newData.password.$error">
                  <div v-for="(error, index) in v$.newData.password.$errors" :key="index" class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
                <template v-if="!$helpers.isNull(errorPasswordMessage)">
                  <div class="mt-2 text-theme-6">
                    {{ errorPasswordMessage }}
                  </div>
                </template>
                <div class="mt-4 border-gray-300 input-group login__input focus:intro-x intro-x">
                  <div id="input-group-confirm-password " class="input-group-text " :class="{ disabled: isRegistering }">
                    <LockIcon class="block w-3 mx-auto" />
                  </div>
                  <input :disabled="isRegistering" :type="isShowConfirmPassword ? 'text' : 'password'"
                    placeholder="Confirm password" v-model="newData.confirmPassword" class="border-gray-300 form-control "
                    aria-label="password" autocomplete="off" aria-describedby="input-group-password" />
                  <div id="input-group-password " :aria-disabled="isRegistering" class="cursor-pointer input-group-text"
                    :class="{ disabled: isRegistering }" @click="isShowConfirmPassword = !isShowConfirmPassword">
                    <EyeOffIcon v-if="isShowConfirmPassword" class="block w-3 mx-auto " />
                    <EyeIcon v-else class="block w-3 mx-auto " />
                  </div>
                </div>
                <template v-if="v$.newData.confirmPassword.$error">
                  <div v-for="(error, index) in v$.newData.confirmPassword.$errors" :key="index"
                    class="mt-2 text-theme-6">
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="flex items-center mt-6 text-xs text-gray-700 intro-y dark:text-gray-600 sm:text-sm">
                <input :disabled="isRegistering" id="remember-me" type="checkbox" class="mr-2 border form-check-input "
                  v-model="isAgree" />
                <label :disabled="isRegistering" class="cursor-pointer select-none" for="remember-me">I agree to the
                  StatementPro</label>
                <a :disabled="isRegistering" class="ml-1 text-theme-1 dark:text-theme-10"
                  href="https://www.lightworkai.com/privacypolicy">Privacy
                  Policy</a>.
              </div>
              <div class="mt-2 text-center intro-x xl:mt-8 xl:text-left" v-if="!$helpers.isNull(errorMessage)">
                <div class="flex text-theme-6">
                  <img :src="imgError" class="w-6 h-6 mr-2 " />
                  {{ errorMessage }}
                </div>
              </div>
              <div class="mt-5 text-center intro-y xl:mt-8 xl:text-left">
                <button class="w-full px-4 py-3 align-top btn btn-primary xl:w-32 xl:mr-3" @click="onRegisterClicked"
                  :disabled="!isAgree || isRegistering">
                  <div class="flex flex-col items-center justify-end sm:flex-row">
                    <div class="text-center">
                      {{ isRegistering ? 'Registering' : 'Register' }}
                    </div>
                    <LoadingIcon v-if="isRegistering" icon="oval" class="w-5 h-5 ml-3 text-center" />
                  </div>
                </button>
                <router-link to="/login" :disabled="isRegistering" :class="{ disabled : isRegistering}" class="w-full px-4 py-3 mt-3 align-top btn btn-outline-secondary xl:w-32 xl:mt-0">
                  Sign up
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import imgError from '@/assets/images/Error32.png'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import imgLogo from '@/assets/images/lw-logo-name.png'
import imgLogoDark from '@/assets/images/lw-logo-name-dark.png'
import { mapGetters } from 'vuex'
import { authService } from '@/services'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, sameAs, email } from '@vuelidate/validators'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  computed: {
    ...mapGetters({
      isDarkMode: `main/${getterTypes.GET_DARK_MODE}`
    }),
    isValidPolicyPassword() {
      return this.policyPassword.validations.isContainMinCharacters &&
        this.policyPassword.validations.isContainMaxCharacters &&
        this.policyPassword.validations.isContainNumber &&
        this.policyPassword.validations.isContainUppercase &&
        this.policyPassword.validations.isContainLowercase &&
        this.policyPassword.validations.isContainSpecialCharacter
    }
  },
  data: () => ({
    imgError,
    imgLogo,
    imgLogoDark,
    isShowPassword: false,
    isLoaded: false,
    isShowConfirmPassword: false,
    isRegistering: false,
    isRegisterCompleted: false,
    isAgree: false,
    errorMessage: null,
    errorPasswordMessage: null,
    safePasswordMessage: null,
    newData: {
      firstName: null,
      lastName: null,
      email: null,
      username: null,
      password: null,
      confirmPassword: null
    },
    policyPassword: {
      rule: null,
      validations: {
        isContainMinCharacters: false,
        isContainMaxCharacters: false,
        isContainNumber: false,
        isContainUppercase: false,
        isContainLowercase: false,
        isContainSpecialCharacter: false
      }
    }
  }),
  setup: () => ({
    route: useRoute(),
    v$: useVuelidate({
      $lazy: true,
      $autoDirty: true
    })
  }),
  validations() {
    return {
      newData: {
        firstName: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        },
        lastName: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        },
        email: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
          email: helpers.withMessage('รูปแบบของที่อยู่ของอีเมลที่ไม่ถูกต้อง', email)
        },
        username: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        },
        password: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required)
        },
        confirmPassword: {
          required: helpers.withMessage('จำเป็นต้องกรอกข้อมูล', required),
          sameAsPassword: helpers.withMessage('ยืนยันรหัสผ่านไม่ตรงกัน', sameAs(this.newData.password))
        }
      }
    }
  },
  watch: {
    'newData.password'(newVal, oldVal) {
      this.validationRulePolicyPassword()
    }
  },
  methods: {
    async onRegisterClicked() {
      this.errorMessage = null
      this.v$.$touch()
      if (this.v$.$invalid || !this.isValidPolicyPassword) {
        this.$helpers.showToastify('Validate-Failed', 'Please check your input', true)
      } else {
        try {
          this.isRegistering = true
          const refKey = this.route.query.refKey ?? null
          const providerUserId = this.route.query.id ?? null
          const payload = {
            firstName: this.newData.firstName,
            lastName: this.newData.lastName,
            userName: this.newData.username,
            email: this.newData.email,
            password: this.newData.password,
            confirmPassword: this.newData.confirmPassword,
            provider: {
              isSync: !this.$helpers.isNull(refKey),
              userId: providerUserId,
              token: refKey
            }
          }
          const res = await authService.register(payload)
          if (this.$helpers.isNull(res?.statusCode)) {
            this.isRegisterCompleted = true
          } else {
            this.errorMessage = res.message
          }
        } catch (error) {
          this.errorMessage = error.message
        } finally {
          this.isRegistering = false
        }
      }
    },
    onPasswordFocusIn() {
      cash('#policy-password-dropdown').dropdown('show')
    },
    onPasswordFocusOut() {
      cash('#policy-password-dropdown').dropdown('hide')
    },
    updateProgressBar(val) {
      const length = this.newData.password?.length ?? 0
      if (length === 0) {
        this.safePasswordMessage = 'ความปลอดภัยรหัสผ่าน'
        return null
      } else if (length > 0 && length <= 4) {
        this.safePasswordMessage = 'รหัสผ่านไม่ปลอดภัย'
        if (val === 1) {
          return 'active bg-red-500'
        }
      } else if (length > 4 && length <= 8) {
        this.safePasswordMessage = 'รหัสผ่านดี'
        if (val === 1 || val === 2) {
          return 'active bg-yellow-500'
        }
      } else {
        this.safePasswordMessage = 'รหัสผ่านดีมาก'
        return 'active bg-green-500'
      }
    },
    validationRulePolicyPassword() {
      this.errorPasswordMessage = null
      if (this.$helpers.isNull(this.newData.password)) {
        this.confirmPassword = this.newData.password
        this.policyPassword.validations.isContainMinCharacters = false
        this.policyPassword.validations.isContainMaxCharacters = false
        this.policyPassword.validations.isContainNumber = false
        this.policyPassword.validations.isContainUppercase = false
        this.policyPassword.validations.isContainLowercase = false
        this.policyPassword.validations.isContainSpecialCharacter = false
        return
      }
      // Check Contain Min Characters
      if (this.policyPassword.rule.minLength > 0) {
        if (this.newData.password.length >= this.policyPassword.rule.minLength) {
          this.policyPassword.validations.isContainMinCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวอย่างน้อย ${this.policyPassword.rule.minLength} ตัวขึ้นไป`
          this.policyPassword.validations.isContainMinCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMinCharacters = true
      }
      // Check Contain Max Characters
      if (this.policyPassword.rule.maxLength > 0) {
        if (this.newData.password.length <= this.policyPassword.rule.maxLength) {
          this.policyPassword.validations.isContainMaxCharacters = true
        } else {
          this.errorPasswordMessage = `ต้องมีความยาวไม่เกิน ${this.policyPassword.rule.maxLength} ตัว`
          this.policyPassword.validations.isContainMaxCharacters = false
        }
      } else {
        this.policyPassword.validations.isContainMaxCharacters = true
      }
      // Check Contain Number
      if (this.policyPassword.rule.minNumbers > 0) {
        const findNumberMatch = this.newData.password.match(/\d+/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minNumbers) {
          this.policyPassword.validations.isContainNumber = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวเลข (0-9) ${this.policyPassword.rule.minNumbers} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainNumber = false
        }
      } else {
        this.policyPassword.validations.isContainNumber = true
      }
      // Check Contain Uppercase
      if (this.policyPassword.rule.minUpperCase > 0) {
        const findNumberMatch = this.newData.password.match(/[A-Z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minUpperCase) {
          this.policyPassword.validations.isContainUppercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (A-Z) ${this.policyPassword.rule.minUpperCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainUppercase = false
        }
      } else {
        this.policyPassword.validations.isContainUppercase = true
      }
      // Check Contain Lowercase
      if (this.policyPassword.rule.minLowerCase > 0) {
        const findNumberMatch = this.newData.password.match(/[a-z]/g)?.join('') ?? ''
        if (findNumberMatch.length >= this.policyPassword.rule.minLowerCase) {
          this.policyPassword.validations.isContainLowercase = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก (a-z) ${this.policyPassword.rule.minLowerCase} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainLowercase = false
        }
      } else {
        this.policyPassword.validations.isContainLowercase = true
      }
      // Check Contain Special Character
      if (this.policyPassword.rule.minIncludeSpecialChar > 0) {
        const findSpecialMatch = this.newData.password.match(/[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g)?.join('') ?? ''
        if (findSpecialMatch.length >= this.policyPassword.rule.minIncludeSpecialChar) {
          this.policyPassword.validations.isContainSpecialCharacter = true
        } else {
          if (this.$helpers.isNull(this.errorPasswordMessage)) {
            this.errorPasswordMessage = `ต้องมีอักขระพิเศษ (!@#$%^&+=) ${this.policyPassword.rule.minIncludeSpecialChar} ตัวขึ้นไป`
          }
          this.policyPassword.validations.isContainSpecialCharacter = false
        }
      } else {
        this.policyPassword.validations.isContainSpecialCharacter = true
      }
    },
    async delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds)
      })
    }
  },
  async mounted() {
    cash('body')
      .removeClass('main')
      .removeClass('error-page')
      .addClass('login')
    await this.delay(1000)
    this.policyPassword.rule = await authService.getPolicyPassword()
    this.isLoaded = true
  }
})
</script>
