<template>
  <Chart
    type="pie"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const options = computed(() => {
      return {
        legend: {
          display: false
        }
      }
    })

    return {
      data: {
        labels: ['Used Credit', 'Remaining Credit'],
        datasets: [
          {
            data: [15, 10],
            backgroundColor: ['#5e3eff', '#3ff5cd'],
            hoverBackgroundColor: ['#5e3eff', '#3ff5cd'],
            borderWidth: 5,
            borderColor: '#303953'
          }
        ]
      },
      options
    }
  }
})
</script>
