<template>
  <div v-if="!$helpers.isNull(provider)">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: FAQ Menu -->
      <div class="col-span-12 py-10 intro-y lg:col-span-4 box">
        <img style="box-shadow:none;width:50%" class="block mx-auto mb-7" :src="imgLogo" />
        <div class="mt-3 text-base font-medium text-center">
          {{ name }}
        </div>
        <div class="w-3/4 mx-auto mt-2 text-center text-gray-600">
          {{ description }}
        </div>
        <div class="flex items-center justify-center mt-5">
          <button class="learn-more button-s1" @click="onGoToWebsiteClicked">
            <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
            </span>
            <span class="button-text">Go to Website</span>
          </button>
       </div>
      </div>
      <!-- END: FAQ Menu -->
    </div>
  </div>
</template>
<style>

</style>
<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import * as getterTypes from '@/store/getter-types'

export default defineComponent({
  data() {
    return {
      imgLogo: null,
      name: '',
      description: '',
      linkWebSite: ''
    }
  },
  computed: {
    ...mapGetters({
      provider: `main/${getterTypes.GET_PROVIDER}`
    })
  },
  methods: {
    onGoToWebsiteClicked() {
      window.open(this.linkWebSite)
    },
    setupProvider() {
      if (!this.$helpers.isNull(this.provider)) {
        switch (this.provider.name.toUpperCase()) {
          case 'PEAK' :
            this.imgLogo = require('@/assets/images/peak-logo-new-design.png')
            this.name = this.provider.name
            this.description = 'The most comprehensive accounting program in Thailand.'
            this.linkWebSite = 'https://peakaccount.com/'
            break
          case 'LIGHTWORK' :
            this.imgLogo = require('@/assets/images/lw-logo-lightwork-dark.png')
            this.name = this.provider.name
            this.description = 'The assistant automatically helps to play immediately.'
            this.linkWebSite = 'https://lightworkai.com/'
            break
          case 'SIAMWARE' :
            this.imgLogo = require('@/assets/images/cotto-sanitary-wares.png')
            this.name = this.provider.name
            this.description = 'It is a reliable provider in dish serving and solutions for sanitary ware and rooms. - Blow some work to provide services and solve problems in the shoulder system and kitchen. Export.'
            this.linkWebSite = 'https://www.cotto.com/'
            break
          case 'CENTRAL' :
            this.imgLogo = require('@/assets/images/central.png')
            this.name = this.provider.name
            this.description = 'It is a reliable provider in dish serving and solutions for sanitary ware and rooms. - Blow some work to provide services and solve problems in the shoulder system and kitchen. Export.'
            this.linkWebSite = 'https://www.central.co.th/'
            break
          default :
            this.imgLogo = null
            this.name = null
            this.description = null
            this.linkWebSite = null
            break
        }
      }
    }
  },
  mounted() {
    this.setupProvider()
  }
})
</script>
